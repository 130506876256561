import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DefiningPage.css';
import Footer from '../Footer/Footer';
import DefiningSlider from './DefiningSlider';
import FullWidthSlider from './FullWidthSlider'
import styled from 'styled-components';
function DefiningPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);

  const services = [
    {
      title: 'Explore Sell Skill Worlds',
      image: 'DefiningPageImages/worlds.jpeg',
      description: 'From our widley different worlds that coming from different publishers with different rates, prices, world content and advisor salary. you can choose the best one that fit with your goals.',
      alt: 'explore sell skill worlds',
    },
    {
      title: 'You got a person that his mission is your success',
      image: 'DefiningPageImages/advisor.jpeg',
      description: "You don't know what you don't know, for that reason on sell skill we provide you with the ability to choose a highly rated advisor that will tell you everything you need to know about that certain world and that certain field generally, you can always chat with him call him ask him and even rate him.",
      alt: 'your advisor is ready',
    },
    {
      title: 'Earn while you learn',
      image: 'DefiningPageImages/earnlearn.jpeg',
      description: 'You can reverse the engine and be as an advior, most of publishers on sell skill are allowing the advisor system, they put a certain level, whenever you passed that level, then you can be an advisor',
      alt: 'Earn while you learn',
    },


  ];

  return (
    <PageContainer>
      {/* SEO Enhancements */}
      <Helmet>
        <title>Sell-Skill: YOUR E-LEARNING PLANET</title>
        <meta name="description" content="Sell-Skill offers a platform to buy and sell digital learning resources, access expert legal and medical consultations, and explore digital marketing solutions globally and in the USA." />
        <meta name="keywords" content="digital learning, legal consulting, medical consultation, digital marketing, online courses, programming courses, remote work, USA digital services, professional consulting" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Sell-Skill: Your Go-To Platform for Digital Learning and Professional Consulting" />
        <meta property="og:description" content="Join Sell-Skill to access top digital learning courses, legal and medical consultations, and expert marketing solutions, targeting global users and USA professionals." />
        <meta property="og:image" content="images/hero-image.jpg" /> {/* Replace with an actual hero image URL */}
        <meta property="og:url" content="https://www.sell-skill.com" /> {/* Replace with your actual site URL */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sell-Skill: Digital Learning, Legal Consulting, Medical Advice & More" />
        <meta name="twitter:description" content="Access digital learning resources, legal and medical consultations, and digital marketing solutions with Sell-Skill." />
        <meta name="twitter:image" content="images/hero-image.jpg" />
        <link rel="canonical" href="https://www.sell-skill.com" />
      </Helmet>

      {/* Pop-up Modal */}
      <Modal show={showModal} onHide={handleClose}>
  <Modal.Body className="modal-content text-center">
    <h3 className="modal-title">Join Sell Skill Today</h3>
    <p>Sign up now and take the first step towards achieving your professional goals.</p>
    <Button
      variant="primary"
      className="cta-button"
      onClick={() => {
        handleClose();
        navigate('/skiller-signUp');
      }}
    >
      Sign Up Now
    </Button>
  </Modal.Body>
</Modal>


      {/* Main Content */}
      <Container fluid style={{ padding: 0 }}>
      <div className="fullwidth-slider-container">
          <div className="fullwidth-slider">
            <FullWidthSlider />
          </div>
          {/* Sign Up/In Button */}
          <Button
            className="signup-button"
            onClick={() => navigate('/skiller-signUp')}
          >
            Sign Up/In
          </Button>
        </div>

        {/* Brand Logos Section */}
        <div className="brand-logos">
  <Container>
    <Row className="text-center">
      <Col><div className="brand-box">GET KNOWLEDGE</div></Col>
      <Col><div className="brand-box">GET MATES</div></Col>
      <Col><div className="brand-box">SHARE KNOWLEDGE</div></Col>
      <Col><div className="brand-box">GET PAID</div></Col>

    </Row>
  </Container>
</div>

        {/* Services Section */}
        <section className="services-section">
          <h2 className="text-center mb-4" style={{ color: '#002f6c' }}>Explore Our Top Services</h2>
          {services.map((service, index) => (
            <Row key={index} className="align-items-center mb-4 service-row">
              {index % 2 === 0 ? (
                <>
                  <Col xs={12} md={6}>
                    <Card.Img variant="top" src={service.image} className="img-fluid" alt={service.alt} style={{ borderRadius: '10px' }} />
                  </Col>
                  <Col xs={12} md={6}>
                    <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                      <Card.Body className="text-center">
                        <Card.Title style={{ color: '#002f6c', fontSize: '24px' }}>{service.title}</Card.Title>
                        <Card.Text style={{ color: '#333', fontSize: '16px' }}>{service.description}</Card.Text>
                        <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                          Join Now
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
                
              ) : (
                <>
                  <Col xs={12} md={6} className="order-md-2">
                    <Card.Img variant="top" src={service.image} className="img-fluid" alt={service.alt} style={{ borderRadius: '10px' }} />
                  </Col>
                  <Col xs={12} md={6} className="order-md-1">
                    <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                      <Card.Body className="text-center">
                        <Card.Title style={{ color: '#002f6c', fontSize: '24px' }}>{service.title}</Card.Title>
                        <Card.Text style={{ color: '#333', fontSize: '16px' }}>{service.description}</Card.Text>
                        <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                          Join Now
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
          ))}
        </section>

        {/* Features Section */}
        <div className="features-section">
          <Container>
            <h2 className="section-title text-center">As a learner you will</h2>
            <Row>
              <Col md={4}>
                <div className="feature-card">
                  <img src="DefiningPageImages/completeLevel.jpeg" alt="Industry Experts" className="img-fluid" />
                  <h3 className="feature-title">Complete the level</h3>
                  <p className="feature-description">
                    inside that certain levels there are lessons you will go through them with your advisor and mates, after completion you can sit for an exam for that level 
                    </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="feature-card">
                  <img src="DefiningPageImages/takeExam.jpeg" alt="Flexible Learning" className="img-fluid" />
                  <h3 className="feature-title">Sit for an exam</h3>
                  <p className="feature-description">
                    you will sit for a live exam, with the ability to repeat it
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="feature-card">
                  <img src="DefiningPageImages/certificate.jpeg" alt="Career Growth" className="img-fluid" />
                  <h3 className="feature-title">Get a certification</h3>
                  <p className="feature-description">
                  Get a certification of completion after passing that certain level exam
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Additional Services Section */}
        <section className="additional-services text-center mt-5" style={{ padding: '40px 0', backgroundColor: '#F5F5F5' }}>
          <h2 style={{ color: '#002f6c' }}>It can't be better</h2>
          <p className="mb-4" style={{ color: '#333', fontSize: '16px', maxWidth: '800px', margin: '0 auto' }}>
           With sell skill mind blowing features it is easy to acheive your dreams in no time
          </p>
          <Row>
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="DefiningPageImages/advisorSystem.jpeg" className="img-fluid" alt="Professional Marketing Services" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Advisor system</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                   With the advisor system you can acheive that certain level on that certain world and start work as an advisor on that world
                  </Card.Text>
                  <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="DefiningPageImages/mates.jpeg" className="img-fluid" alt="Business Strategy Consulting" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Find live time mates</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                   No one can deniy the role of the people surronding you, got those like minded people on sell skill and start fighting together as a team
                  </Card.Text>
                  <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            
            <Col xs={12} md={4}>
              <Card className="h-100 border-0" style={{ backgroundColor: '#FFFFFF' }}>
                <Card.Img variant="top" src="DefiningPageImages/uploadWorld.jpeg" className="img-fluid" alt="Financial Planning Services" style={{ borderRadius: '10px' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#002f6c', fontSize: '20px' }}>Upload your own world</Card.Title>
                  <Card.Text style={{ color: '#333', fontSize: '16px' }}>
                   Got a skill and knowledge, sell that on sell skill
                  </Card.Text>
                  <Button onClick={() => navigate('/skiller-signUp')} className="mt-2" style={{ backgroundColor: '#32CD32', borderColor: '#32CD32' }}>
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>


         <div className="featured-professionals-section">
          <Container>
            <h2 className="section-title text-center">Your mission</h2>
            <Row>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="DefiningPageImages/publisherMission.jpeg" />
                  <Card.Body>
                    <Card.Title>Your mission as a publisher</Card.Title>
                    <Card.Text>
                      As a publisher you need take care of your world, update its content, add more and give it a real valuble information. and be carefull your students will rate your world and leave comments
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="DefiningPageImages/advisorMission.jpeg" />
                  <Card.Body>
                    <Card.Title>Your mission as an advisor</Card.Title>
                    <Card.Text>
                    As an advisor your mission is to answer all your student questions and give him the most valuable information that will help him over his all journey. you will also be rated so do your best.
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="professional-card">
                  <Card.Img variant="top" src="DefiningPageImages/studentMission.jpeg" />
                  <Card.Body>
                    <Card.Title>Your mission as a student</Card.Title>
                    <Card.Text>
                      As student your mission is to work harder than it was ever been, find mates and improve your self, as we give you the best enviroment of learning and people who are on your field and better than you, so after that it is just depends on you, in addition to you can become an advisor and earn while you learn. you will be rated as a mate and also as an advisor, so take carefull
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div> 

        {/* Popular Courses Section */}
        {/* <div className="courses-section">
          <Container>
            <h2 className="section-title text-center">Our Popular Courses</h2>
            <Row>
              <Col md={3}>
                <div className="course-card">
                  <img src="CoursesThumbnails/FacebookAdsThumbnail.jpg" alt="Course 1" className="img-fluid" />
                  <h4 className="course-title">Facebook ADS Marketing</h4>
                </div>
              </Col>
              <Col md={3}>
                <div className="course-card">
                  <img src="CoursesThumbnails/youtubeThumbnail.jpg" alt="Course 2" className="img-fluid" />
                  <h4 className="course-title">Youtube ADS Marketing</h4>
                </div>
              </Col>
              <Col md={3}>
                <div className="course-card">
                  <img src="CoursesThumbnails/cryptoThumbnail.jpg" alt="Course 3" className="img-fluid" />
                  <h4 className="course-title">Cryptocurrency trading and investment</h4>
                </div>
              </Col>
              <Col md={3}>
                <div className="course-card">
                  <img src="CoursesThumbnails/tradingThumbnail.jpg" alt="Course 3" className="img-fluid" />
                  <h4 className="course-title">Japanese Candle Sticks trading</h4>
                </div>
              </Col>
            </Row>
            <div className="text-center mt-4">
              <Button variant="primary" className="cta-button" onClick={() => navigate('/auth')}>Explore All Courses</Button>
            </div>
          </Container>
        </div> */}

        {/* Success Stories Section */}
         <div className="success-stories-section">
          <Container>
            <h2 className="section-title text-center">Everyting is rated so you can choose in a piece of mind</h2>
            <Row>
              <Col md={6}>
                <img src="DefiningPageImages/worldRated.jpeg" alt="Success Story 1" className="img-fluid" />
                <h4 className="success-title">Worlds are rated</h4>
                <p className="success-description">
                 You can choose the right world that fits with your needs
                </p>
              </Col>
              <Col md={6}>
                <img src="DefiningPageImages/advisorRated.jpeg" alt="Success Story 2" className="img-fluid" />
                <h4 className="success-title">Advisors are rated</h4>
                <p className="success-description">
                 You can choose the highest rate between advisors
                </p>
              </Col>
            </Row>
          </Container>
        </div> 

        {/* Call to Action Section */}
        <div className="call-to-action-section">
        <Container className="call-to-action-section">
  <h2 className="cta-title">It is cool, right?</h2>
  <Button
    variant="light"
    className="cta-button"
    onClick={() => navigate('/auth')}
  >
    Start Now
  </Button>
</Container>

</div>

        {/* Footer Section */}
        {Footer && <Footer />}
      </Container>
    </PageContainer>
  );
}
   
export default DefiningPage;




const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;
