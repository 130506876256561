import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FaBars, FaBell, FaComments } from 'react-icons/fa';
import axios from 'axios';

const WUploadPost = () => {
  const [title, setTitle] = useState('');
  const [skillerId, setSkillerId] = useState('')
  const worldId = useSelector((state) => state.allow.worldId);
                const [profilePictureSrc, setProfilePictureSrc] = useState('');
                const [showDropdown, setShowDropdown] = useState(false)
  const [thumbnail, setThumbnail] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [attachments, setAttachments] = useState({
    photos: [],
    videos: [],
    pdfs: [],
    texts: [],
    quizs: [],
  });
  const navigate = useNavigate();

  useEffect(() => {

    const getSkillerId = async() => {
      const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
      setSkillerId(response.data)
      console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
     }
     getSkillerId();
    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { responseType: 'blob' }
            );
    
            console.log('Fetched blob:', response.data);
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    
 
    fetchProfilePicture();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
  console.log("Before clearing localStorage:", localStorage);
  localStorage.clear();
  console.log("After clearing localStorage:", localStorage);

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

  console.log("Cookies after clearing:", document.cookie);

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};

  const handleFileUpload = async (file, type) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `uploads/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      return url;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  const addAttachment = async (type, fileOrContent, additionalData = {}) => {
    let url = fileOrContent;
        
    if (type !== 'texts' && type !== 'quizs') {
      url = await handleFileUpload(fileOrContent, type);
      if (!url) return;
    }

    setAttachments((prev) => ({
      ...prev,
      [type]: [
        ...prev[type],
        {
          ...additionalData,
          ...(type === 'texts' || type === 'quizs'
            ? { content: fileOrContent }
            : { contentUrl: url }),
          order: prev[type].length + 1,
        },
      ],
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://sell-skill.com/api/endpoints/WaddPost', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          _id: uuidv4(),
          worldId,
          title,
          thumbnail,
          attachments,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Post uploaded successfully:', result);
        navigate('/levels');
      } else {
        console.error('Error uploading post:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting post:', error);
    }
  };

  return (
    <PageContainer>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
      <ContentContainer>
        <FormContainer>
          <Input
            type="text"
            placeholder="Enter post title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Input
            type="file"
            accept="image/*"
            onChange={async (e) => {
              const url = await handleFileUpload(e.target.files[0], 'thumbnail');
              setThumbnail(url);
            }}
          />
          <AttachmentSection>
            <h3>Add Attachments</h3>
            <Input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => {
                Array.from(e.target.files).forEach((file) => addAttachment('photos', file));
              }}
            />
            <Input
              type="file"
              accept="video/*"
              multiple
              onChange={(e) => {
                Array.from(e.target.files).forEach((file) => addAttachment('videos', file));
              }}
            />
            <Input
              type="file"
              accept="application/pdf"
              multiple
              onChange={(e) => {
                Array.from(e.target.files).forEach((file) => addAttachment('pdfs', file));
              }}
            />
            <Textarea
              placeholder="Add text content"
              onBlur={(e) => addAttachment('texts', e.target.value)}
            />
            <QuizContainer>
              <input
                type="text"
                placeholder="Quiz question"
                onBlur={(e) => addAttachment('quizs', e.target.value, { question: e.target.value })}
              />
            </QuizContainer>
          </AttachmentSection>
          <Button onClick={handleSubmit}>Submit Post</Button>
        </FormContainer>
      </ContentContainer>
    </PageContainer>
  );
};

export default WUploadPost;

// Styled Components
const PageContainer = styled.div`
  padding: 20px;
  background: #f8f9fa;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.h1`
  color: #2c3e50;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 20px auto;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
`;

const AttachmentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;



















const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;


















 






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;






const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;
 

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;
