import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TP.css'; // External CSS for enhanced styling

const TP = () => {
  const navigate = useNavigate();


  useEffect(() => {
    const pixelIds = ['1669785423607093', '1981912632321591', '2437238109784633', '1037844264771881'];
  
    if (window.fbq) {
      // Initialize all Pixel IDs
      pixelIds.forEach((pixelId) => {
        window.fbq('init', pixelId);
      });
  
      // Track the 'PageView' event once
      window.fbq('track', 'PageView', {
        content_name: 'Landing Page view',
        value: 0,
        currency: 'USD',
      });
    }
  }, []);
  
  return (
    <div className="tp-container">
      {/* Background animation */}
      <div className="tp-background"></div>

      {/* Congratulations message */}
      <div className="tp-message-container">
        <h1 className="tp-congrats-title">🎉 Congratulations! 🎉</h1>
        <p className="tp-congrats-text">
          Your journey to success starts now! Click below to collect your ebook.
        </p>

        {/* Collect Ebook Button */}
        <button
  onClick={() => {
    window.location.href =
      'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/NewMoneyEbooks%2FHow-to-use-Facebook-ads.pdf?alt=media&token=760b7f60-54bf-4e4f-8547-1b638a4f552f';
  }}
  className="tp-collect-btn"
>
  COLLECT YOUR EBOOK
</button>
      </div>
    </div>
  );
};

export default TP;
