import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Alert, Card, Form, Container, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeAuth, setProviderOrClientId } from "../reducers/reducers";
import styled from "styled-components";

function SignIn() {
  const dispatch = useDispatch();
  const [signInData, setSignInData] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [notAllowed, setNotAllowed] = useState(false);
  const [clientResult, setClientResult] = useState(true);
  const navigate = useNavigate();




  console.log('Cookies after clearing:', document.cookie);
  console.log('LocalStorage after clearing:', localStorage);
  console.log('SessionStorage after clearing:', sessionStorage);

  
  const googleSuccess = async (response) => {
    const { credential } = response;
    const payload = JSON.parse(atob(credential.split(".")[1]));
    const { email } = payload;

    setIsLoading(true);
    try {
      const responseSkiller = await axios.post("https://sell-skill.com/api/endpoints/skillerSignIn", {
        email,
        password: "",
      });
      const skillerToken = responseSkiller.data;
      localStorage.setItem("skillerToken", JSON.stringify(skillerToken));
      navigate("/worlds");
    } catch (error) {
      console.error("Google Sign-In failed:", error);
      setClientResult(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = async () => {         
    setIsLoading(true);
    try {
      const responseSkiller = await axios.post(
        "https://sell-skill.com/api/endpoints/skillerSignIn",
        signInData
      );
      const skillerToken = responseSkiller.data;
      localStorage.setItem("skillerToken", JSON.stringify(skillerToken));

      const responseProviderOrClientId = await axios.get(
        "https://sell-skill.com/api/endpoints/providerOrClientId"
      );
      dispatch(setProviderOrClientId(responseProviderOrClientId.data));

      localStorage.setItem("authenticated", "true");
      dispatch(changeAuth(true));
      navigate("/worlds");
    } catch (error) {
      console.error("Sign-In failed:", error);
      setNotAllowed(true);
      setClientResult(false);
      localStorage.setItem("authenticated", "false");
      dispatch(changeAuth(false));
    } finally {

      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sell-Skill | Sign In</title>
        <meta
          name="description"
          content="Sign in to Sell-Skill to connect with clients or providers, share knowledge, or access services."
        />
        <meta name="keywords" content="Sell-Skill, login, knowledge sharing, client provider platform" />
      </Helmet>
      <PageContainer>
        <StyledContainer>
          <StyledCard>
            <Card.Body>
              <CardTitle>
                <b>Login to Your Account</b>
              </CardTitle>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <StyledInput
                    type="email"
                    placeholder="Email or username"
                    onChange={(e) => setSignInData({ ...signInData, email: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <StyledInput
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setSignInData({ ...signInData, password: e.target.value })}
                  />
                </Form.Group>
                <StyledButton
                  type="button"
                  onClick={handleSignIn}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    "Log in"
                  )}
                </StyledButton>
              </Form>
              <FooterText>
                <a href="/skiller-signUp">Don't have an account? Sign up here</a>
              </FooterText>
              <GoogleLoginWrapper>
                <GoogleLogin onSuccess={googleSuccess} />
              </GoogleLoginWrapper>
              {notAllowed && (
                <StyledAlert variant="danger">
                  Not allowed. Please ensure you have an account.
                </StyledAlert>
              )}
              {!clientResult && (
                <StyledAlert variant="danger">
                  Email or password is incorrect.
                </StyledAlert>
              )}
            </Card.Body>
          </StyledCard>
        </StyledContainer>
      </PageContainer>
    </>
  );
}

export default SignIn;
const PageContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */
  background-color: #f8f9fa; /* Optional: Add a subtle background color */
`;


const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add vertical centering for inner content */
  width: 100%; /* Ensure it spans the container */
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const CardTitle = styled(Card.Title)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50;
`;

const StyledInput = styled(Form.Control)`
  border: 2px solid #27ae60;
  border-radius: 5px;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  background-color: #27ae60;
  border: none;
  padding: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #1e8449;
  }

  &:disabled {
    background-color: #bdc3c7;
  }
`;

const FooterText = styled.div`
  text-align: center;
  margin-top: 15px;

  a {
    color: #2980b9;
    text-decoration: underline;

    &:hover {
      color: #2c3e50;
    }
  }
`;

const GoogleLoginWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 20px;
  text-align: center;
`;
























// import React, { useState } from 'react';
// import { Helmet } from 'react-helmet';
// import { Button, Alert, Card, Form, Container, Spinner } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from 'axios';
// import { GoogleLogin } from '@react-oauth/google';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { changeAuth, setProviderId, setProviderOrClientId } from '../reducers/reducers';

// function SignIn() {
//   const dispatch = useDispatch();
//   const [notAllowed, setNotAllowed] = useState(false);
//   const [signInData, setSignInData] = useState({ email: '', password: '' });
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();
//   let responseProvider;
//   let responseSkiller;
//   let responseProviderId;
//   let skillerToken;
//   let providerToken;
//   const [clientResult, setClientResult] = useState(true);
//   const [providerResult, setProviderResult] = useState(true);

//   const googleSuccess = async (response) => {
//     const { credential } = response;
//     const payload = JSON.parse(atob(credential.split('.')[1]));
//     const { email } = payload;

//     setIsLoading(true);
//     try {
//       responseSkiller = await axios.post('https://sell-skill.com/api/endpoints/skillerSignIn', { email, password: '' });
//       skillerToken = responseSkiller.data;
//       localStorage.setItem('skillerToken', JSON.stringify(skillerToken));
//       responseSkiller && navigate('/dashboard');
//     } catch (error) {
//       setClientResult(false);
//     }
//     setIsLoading(false);
//   };

//   const handleClick = async () => {
//     setIsLoading(true);
//     try {
//       try {
//         responseSkiller = await axios.post('https://sell-skill.com/api/endpoints/skillerSignIn', signInData);
//         skillerToken = responseSkiller.data;
//         localStorage.setItem('skillerToken', JSON.stringify(skillerToken));
//       } catch (error) { 
//         setClientResult(false);
//       }

//       localStorage.setItem('authenticated', 'true');
//       dispatch(changeAuth(true));

//       responseSkiller && navigate('/worlds');
//       responseProvider && navigate('/worlds');
//       if (responseSkiller || responseProvider) {
//         const responseProviderOrClientId = await axios.get('https://sell-skill.com/api/endpoints/providerOrClientId');
//         dispatch(setProviderOrClientId(responseProviderOrClientId.data));
//       }
//     } catch (error) {
//       console.error('Sign-in failed:', error);
//       setNotAllowed(true);
//       localStorage.setItem('authenticated', 'false');
//       dispatch(changeAuth(false));
//     }
//     setIsLoading(false);
//   };

//   return (
//     <>
//       <Helmet>
//         <title>Sell-Skill | Sign Up or Login</title>
//         <meta name="description" content="Sell-Skill is a platform that connects clients with providers. Sign up to get help or share your knowledge." />
//         <meta name="keywords" content="Sell-Skill, client-provider platform, knowledge sharing, login, sign-up" />
//         <meta name="robots" content="index, follow" />
//       </Helmet>
//       <div style={{ minHeight: '100vh', backgroundColor: '#002f6c', padding: '2rem' }}>
//         <Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100%' }}>
//           <Card style={{ width: '100%', maxWidth: '500px', backgroundColor: '#fff', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
//             <Card.Body>
//               <Card.Title className="text-center mb-4" style={{ fontSize: '24px', color: '#002f6c' }}>
//                 <b>Login to Your Account</b>
//               </Card.Title>
//               <Form>
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                   <Form.Control 
//                     type="email" 
//                     placeholder="Email or username" 
//                     onChange={(e) => setSignInData({ ...signInData, email: e.target.value })} 
//                     style={{ borderColor: '#008080' }} 
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="formBasicPassword">
//                   <Form.Control 
//                     type="password" 
//                     placeholder="Password" 
//                     onChange={(e) => setSignInData({ ...signInData, password: e.target.value })} 
//                     style={{ borderColor: '#008080' }} 
//                   />
//                 </Form.Group>
//                 <Button 
//                   variant="primary" 
//                   type="button" 
//                   onClick={handleClick} 
//                   className="w-100" 
//                   disabled={isLoading}
//                   style={{ backgroundColor: '#008080', borderColor: '#008080' }}
//                 >
//                   {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Log in'}
//                 </Button>
//               </Form>
//               <div className="text-center mt-3">
//                 <a href="/client-sign-up" style={{ color: '#002f6c', textDecoration: 'underline' }}>
//                   Don't have an account? Sign up here
//                 </a>
//               </div>
//               <div className="text-center mt-3">
//                 <GoogleLogin onSuccess={googleSuccess} />
//               </div>
//               {notAllowed && (
//                 <Alert variant="danger" className="mt-3">
//                   Not allowed, please make sure you have an account
//                 </Alert>
//               )}
//               {!clientResult && !providerResult && (
//                 <Alert variant="danger" className="mt-3">
//                   Email or password might be incorrect
//                 </Alert>
//               )}
//             </Card.Body>
//           </Card>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default SignIn;
