import './App.css';
import { Routes, Route } from 'react-router-dom';


import NotFoundPage from './NotFoundPage/NotFoundPage.js';
import AddLesson from './UploadCourse/MyAddEditLessons/AddLesson.js';
import SignIn from './SignUpIn&ProfileEdit/SignIn.js';
import AddLevel from './UploadCourse/MyAddEditLevels/AddLevel.js';

import InsideLesson from './UploadCourse/MyAddEditLessons/InsideLesson.js';
import Worlds from './ExploreWorlds/WorldProcess/Worlds/WorldsMainHome/Worlds.js';
import SkillerSignUp from './SignUpIn&ProfileEdit/SignUp/SignUp.js';
import Categories from './SignUpIn&ProfileEdit/SignUp/Categories.js';
import Certifications from './SignUpIn&ProfileEdit/SignUp/Certifications.js';
import PreviousExperience from './SignUpIn&ProfileEdit/SignUp/PreviousExperience.js';
import Picture from './SignUpIn&ProfileEdit/SignUp/Picture.js';
import Letter from './SignUpIn&ProfileEdit/SignUp/Letter.js';

import SetSubscribedInButton from './ExploreWorlds/SubscribedIn/SetSubscribedInButton.js';

import Chats1 from './Chats/Chats1.js';
import Chats2 from './Chats/Chats2.js';
import Notifications1 from './Notifications/Notifications1.js';
import Notifications2 from './Notifications/Notifications2.js';
import Exam1 from './ExternalExamNB/Exam1.js';
import Exam2 from './ExternalExamNB/Exam2.js';
import Certifications1 from './ExternalCertificationsNB/Certifications1.js';
import Certifications2 from './ExternalCertificationsNB/Certifications2.js';
import SendNotification from './Notifications/SendNotification.js';
import StartExam from './ExternalExamNB/StartExam.js';
import Levels from './ExploreWorlds/WorldProcess/Levels/Levels.js';
import Lessons from './ExploreWorlds/WorldProcess/Lessons/Lessons.js';
import Resources from './ExploreWorlds/WorldProcess/Resources/Resources.js';
import ChooseURAdvisor from './ExploreWorlds/WorldProcess/ChooseAdvisor/ChooseURAdvisor.js';
import SetWithId from './Chats/SetWithId.js';
import MyStudentsChats1 from './ExploreWorlds/WorldProcess/WorldChat/MyStudentsChats1.js';
import MyAdvisorChat from './ExploreWorlds/WorldProcess/WorldChat/MyAdvisorChat.js';

import SetWithIdForStudentsChat from './ExploreWorlds/WorldProcess/WorldChat/SetWithIdForStudentsChat.js';
import SetWithIdForWorldChat from './ExploreWorlds/WorldProcess/WorldChat/SetWithIdForWorldChat.js';
import SetAdvisorManually from './ExploreWorlds/WorldProcess/ChooseAdvisor/SetAdvisorManually.js';
import SetWorldId from './ExploreWorlds/WorldProcess/SetAndNavigate/SetWorldId.js';
import GExams from './ExternalExamNB/GExams.js';
import GExams4Students from './ExternalExamNB/GExams4Students.js';
import GExams4Advisor from './ExternalExamNB/GExams4Adv.js';
import GProfile from './Profile/GProfile/GProfile.js';


import MProfile from './Profile/MProfile/MProfile.js';
import AllUploads from './ManageUploads/AllUploads.js';
import ManageFinancial from './ManageFinancial/ManageFinancial.js';

import DefiningPage from './DefiningFolder/DefinigPage.js';
import DefiningVideo from './SignUpIn&ProfileEdit/SignUp/DefiningVideo.js';
import ProtectedRoute from './ProtectedRoute.js';
import WUploadPost from './ExploreWorlds/WorldProcess/Levels/UploadPost/WUploadPost.js';
import CoverPhoto from './SignUpIn&ProfileEdit/SignUp/CoverPhoto.js';
import RealWorldResults from './SignUpIn&ProfileEdit/SignUp/RealWorldResults.js';

import AddWorld from './UploadCourse/MyAddEditWorlds/AddWorld.js';
import WorldsDisplay from './ExploreWorlds/WorldProcess/Worlds/WorldsMainHome/TsWorlds.js';

import UploadPostToAllLevels from './ExploreWorlds/WorldProcess/UploadPost/UploadPostToAllLevels.js';
import EditPost from './ExploreWorlds/WorldProcess/UploadPost/EditPost.js';

import GetWorldTrailer from './UploadCourse/MyAddEditWorlds/Trailer/GetWorldTrailer.js';
import EditWorld from './UploadCourse/MyAddEditWorlds/EditWorld.js';

import MyCertifications from './ExternalCertificationsNB/MyCertifications.js';
import MyExams from './UploadCourse/Exam/MyExams.js';
import Achievement from './Profile/Acheivement/Acheivement.js';
import MyStudentsAndMyAdvisors from './MyStudents&MyAdvisors/MyStudents&MyAdvisors.js';
import LandingPage1 from './LandingPages/LandingPage1.js';
import FacebookAdsLandingPage from './LandingPages/FacebookAdsLandingPage.js';
import TradeGptLandingPage from './LandingPages/TradeGptLandingPage.js';
import TP from './SKFA General Management/MMO/DM/FA/TP.js';
import LP from './SKFA General Management/MMO/DM/FA/LP.js';
import LPTSWEBDEV from './SKFA General Management/TS/web dev/LP.js';
import TPTSWEBDEV from './SKFA General Management/TS/web dev/TP.js';
import LinkMedia from './SignUpIn&ProfileEdit/SignUp/LinkMedia.js';

// import AddWorld from './UploadCourse/MyAddEditWorlds/AddWorld.js';


function App() {
  
    return (
    
     <Routes>

      
      {
       <Route path="*" Component={NotFoundPage} /> 
      }

    <Route path="/" element={<DefiningPage/>}/>




      <Route path="/acheivement" element={<Achievement/>}/>


      <Route path="/skiller-signUp" element={<SkillerSignUp/>}/>



      <Route path = "/categories" element={<Categories/>}/>




      <Route path="/certifications" element={<Certifications/>}/>







      <Route path="/experience" element={<PreviousExperience/>}/>

























      <Route path="/picture" element={<Picture/>}/>
























      <Route path="/cover-picture" element={<CoverPhoto/>}/>



























      <Route path="/real-world-results" element={<RealWorldResults/>}/>

      <Route path="/letter" element={<Letter/>}/>
      
      <Route path="/skiller-signIn" element={<SignIn/>}/>
      {/*GET*/}

      
      
      {/*upload */}
       <Route path="/add-world" element={          <ProtectedRoute >
   <AddWorld/>
   </ProtectedRoute>}/> 

   
   <Route path="/edit-world" element={          <ProtectedRoute >
   <EditWorld/>


   </ProtectedRoute>}/> 

      <Route path="/add-level" element={          <ProtectedRoute >
   <AddLevel/>
   </ProtectedRoute>}/>

      <Route path="/add-lesson" element={          <ProtectedRoute >
   <AddLesson/>
   </ProtectedRoute>}/>


      <Route path="/lesson-resources" element={          <ProtectedRoute >
   <InsideLesson/>
   </ProtectedRoute>}/>
   
      <Route path = "/worlds"         element={
          <ProtectedRoute >
            <Worlds />
          </ProtectedRoute>
        }/>

<Route path="/profile/:id" element={          
   <GProfile/>
   }/>

      <Route path="/my-profile/:id" element={          <ProtectedRoute >
   <MProfile/>
   </ProtectedRoute>}/>



              <Route path="/levels/:worldId"  element={
          <ProtectedRoute >
            <Levels />
          </ProtectedRoute>
        }/>
      <Route path="/levels"  element={
          <ProtectedRoute >
            <Levels />
          </ProtectedRoute>
        }/>




      <Route path = "/lessons" element={
          <ProtectedRoute >
            <Lessons />
          </ProtectedRoute>
        }/>


      <Route path="/resources"  element={
          <ProtectedRoute >
            <Resources />
          </ProtectedRoute>
        }/>

        
      <Route path="/my-chats" element={          <ProtectedRoute >
<Chats1/>
</ProtectedRoute>}/>   


      <Route path="/chat" element={          <ProtectedRoute >
<Chats2/>
</ProtectedRoute>}/>


      <Route path="/set-with-id" element={          <ProtectedRoute >
   <SetWithId/>
   </ProtectedRoute>}/>


      <Route path="/my-advisor-chat" element={          <ProtectedRoute >
   <MyAdvisorChat/>
   </ProtectedRoute>}/>


      <Route path="/my-students-chats" element={          <ProtectedRoute >
   <MyStudentsChats1/>
   </ProtectedRoute>}/>




      <Route path="/set-with-id-for-student-chat" element={          <ProtectedRoute >
   <SetWithIdForStudentsChat/>
   </ProtectedRoute>}/>





      <Route path="/set-with-id-for-world-chat" element={          <ProtectedRoute >
   <SetWithIdForWorldChat/>
   </ProtectedRoute>}/>



      <Route path="/send-notification" element={          <ProtectedRoute >
   <SendNotification/>
   </ProtectedRoute>}/>




      <Route path="exam" element={          <ProtectedRoute >
   <Exam2/>
   </ProtectedRoute>}/>
      <Route path="/start-exam" element={          <ProtectedRoute >
   <StartExam/>
   </ProtectedRoute>}/>
      <Route path="/Gexams" element={          <ProtectedRoute >
   <GExams/>
   </ProtectedRoute>}/>

      <Route path="/g-exam-4-advisor" element={          <ProtectedRoute >
   <GExams4Advisor/>
   </ProtectedRoute>}/>
      <Route path = "/g-exam-4-student" element={          <ProtectedRoute >
   <GExams4Students/>
   </ProtectedRoute>}/>



      
      <Route path = "/set-subscirbed-in-to-true" element={          <ProtectedRoute >
   <SetSubscribedInButton/>
   </ProtectedRoute>}/>


      <Route path = "/insert-advisor-manually-dispatch-worldId" element={          <ProtectedRoute >
   <SetAdvisorManually/>
   </ProtectedRoute>}/>


      <Route path="/worldId-DNT" element={          <ProtectedRoute >
   <SetWorldId/>
   </ProtectedRoute>}/>





      <Route path="/manage-uploads" element={          <ProtectedRoute >
   <AllUploads/>
   </ProtectedRoute>}/>

      <Route path="/definig-video" element={          <ProtectedRoute >
   <DefiningVideo/>
   </ProtectedRoute>}/>


   <Route path="/lesson-resources" element={          <ProtectedRoute >
   <InsideLesson/>
   </ProtectedRoute>}/>

   <Route path="/ts-worlds" element={          <ProtectedRoute >
   <WorldsDisplay/>
   </ProtectedRoute>}/>

   <Route path="/upload-post-for-world" element={          <ProtectedRoute >
   <WUploadPost/>
   </ProtectedRoute>}/>


   <Route path="/world-trailer/:worldId" element={          <ProtectedRoute >
   <GetWorldTrailer/>
   </ProtectedRoute>}/>


   <Route path="/edit-post-to-world" element={<EditPost/>}/>



   <Route path="/choose-advisor" element={          <ProtectedRoute >
   <ChooseURAdvisor/>
   </ProtectedRoute>}/>
   <Route path="/upload-post-to-world" element={<UploadPostToAllLevels/>}/>























{/* dont forget to add the header*/ }
   <Route path="/my-notifications" element={          <ProtectedRoute >
   <Notifications1/>
   </ProtectedRoute>}/>
      <Route path="/notification" element={          <ProtectedRoute >
   <Notifications2/>
   </ProtectedRoute>}/>
      <Route path="/certification" element={          <ProtectedRoute >
   <Certifications2/>
   </ProtectedRoute>}/>
   <Route path="/my-certifications" element={          <ProtectedRoute >
   <Certifications1/>
   </ProtectedRoute>}/>










   <Route path="/manage-financial" element={          <ProtectedRoute >
   <ManageFinancial/>
   </ProtectedRoute>}/>

   
   <Route path="/my-exams" element={          <ProtectedRoute >
   <MyExams/>
   </ProtectedRoute>}/>
   <Route path="/advisors-&-students" element={          <ProtectedRoute >
   <MyStudentsAndMyAdvisors/>
   </ProtectedRoute>}/>


   <Route path="/fal1" element={<LandingPage1/>} />
   <Route path="/fal2" element={<FacebookAdsLandingPage/>} />
   <Route path="/advertise-like-a-rockstar" element={<LP/>} />
   <Route path ="/download-advertise-like-a-rockstar" element={<TP/>}/>
   <Route path = "/react-guidance" element= {<LPTSWEBDEV/>} />
   <Route path = "/download-react-guidance" element= {<TPTSWEBDEV/>} />
   <Route path = "/media-links" element={<LinkMedia/>} />
    </Routes> 

  );
}

export default App;























































































































// import './App.css';
// import { Routes, Route, Navigate } from 'react-router-dom';

// import SignUpLogin from './auth/SignUpLogin';
// import SignUp from './auth/SignUp';
// import DetermineSignUp from './auth/DetermineSignUp';
// import TeacherSignUp from './Provider/ProviderSignUp/TeacherSignUp.js';
// import Inviting from './Client/ClientHome/Inviting.js';
// import Term from './Client/postingProcess/Term.js';
// import Title from './Client/postingProcess/Title.js';
// import Skills from './Client/postingProcess/Skills.js';
// import Scope from './Client/postingProcess/Scope.js';
// import Experience from './Client/postingProcess/Experience.js';
// import Pudget from './Client/postingProcess/Pudget.js';
// import Description from './Client/postingProcess/Description.js';
// import Overview from './Client/postingProcess/Overview.js';
// import TypeOfWork from './Provider/ProviderSignUp/TypeOfWork.js';

// import RatePerHour from './Provider/ProviderSignUp/RatePerHour.js';


// import PMainHome from './Provider/ProviderHome/PMainHome.js';
// import ClientNotifications from './Client/ClientNotifications/ClientNotifications.js';
// import ProposalAtClient from './Client/ProposalAtClient/ProposalAtClient.js';
// import ProviderProposal from './auth/ProviderProposal/ProviderProposal.js';
// import Accept from './Client/Accept/Accept.js';
// import ClientMessages from './Client/ClientMessages/ClientMessages.js';
// import Paypal from './Payment/Paypal/Paypal.js';
// import Payment from './Payment/Payment.js'
// import ChooseMethod from './Payment/ChooseMethod.js';
// import Cancel from './auth/Cancel.js';
// import ProviderMessages from './Provider/ProviderMessages.js/ProviderMessages.js';
// import ProviderToClientMessage from './Provider/ProviderMessages.js/ProviderToClientMessage.js';
// import ReceivedMessages1Client from './Client/ReceivedMessagesClient/ReceivedMessages1Client.js';
// import ReceivedMessages2Client from './Client/ReceivedMessagesClient/ReceivedMessages2Client.js';
// import PaymentRegister from './Provider/ProviderSignUp/PaymentRegister.js';
// import PaymentSuccess from './Payment/Paypal/PaymentSuccess.js';
// import PaymentCancel from './Payment/Paypal/PaymentCancel.js';
// import React, { useContext, useEffect, useState } from 'react';
// import ProviderProfile4Provider from './Provider/ProviderProfile4Provider/ProviderProfile4Provider.js';
// import ProviderProfile from './Client/providerProfile/ProviderProfile.js';
// import ClientPosts from './Client/ClientPosts/ClientPosts.js';
// import ProviderInvitaions from './Provider/ProviderInvitaions/ProviderInvitaions.js';
// import ProviderInvitation2 from './Provider/ProviderInvitaions/ProviderInvitation2.js';
// import MakeReport from './Client/MakeReport/MakeReport.js';
// import Blog from './Provider/ProviderSignUp/Blog.js';
// import ClientSignUp from './Client/ClientSignUp/ClientSignUp.js';
// import ClientProfilePicture from './Client/ClientSignUp/ClientProfilePicture.js';
// import ClientCategory from './Client/ClientSignUp/ClientCategory.js';
// import ProviderReports from './Provider/ProviderReports/ProviderReports.js';
// import CertainProvider from './Client/CertainProvider/CertainProvider.js';
// import InvitationAcceptance from './Client/InvitationAcceptance/InvitationAcceptance.js';
// import DefinigPage from './DefiningFolder/DefinigPage.js';

// import LandingPage from './DefiningFolder/LandingPage.js';
// import LearnMorePage from './LearnMore/LearnMorePage.js';
// import TestimonialsPage from './Testmonials/TestimonialsPage.js';
// import AboutUsPage from './AboutUs/AboutUsPage.js';
// import BlogsPage from './Blogs/BlogsPage.js';
// import BlogPost from './Blogs/BlogPost.js';
// import ContactUs from './contactUs/ContactUs.js';
// import ServiceConnect from './Blogs/ServiceConnect.js';
// import AffiliateMarketingPost from './Blogs/AffiliateMarketingPost.js';
// import NotFoundPage from './NotFoundPage/NotFoundPage.js';
// import BeautyFashionBlog from './Blogs/BeautyFashionBlog.js';
// import BlogPost5 from './Blogs/BlogPost5.js';
// import FindBestCodingTutor from './Blogs/FindBestCodingTutor.js';
// import BenefitsOfOnlineLegalConsultations from './Blogs/BenefitsOfOnlineLegalConsultations.js';
// import TipsForLearningGraphicDesign from './Blogs/TipsForLearningGraphicDesign.js';
// import FindExpertPost from './Blogs1/FindExpertPost.js';
// import HireGraphicDesignerPost from './Blogs1/HireGraphicDesignerPost.js';
// import SeoOptimizationPost from './Blogs1/SeoOptimizationPost.js';
// import VirtualAssistantPost from './Blogs1/VirtualAssistantPost.js';
// import PerfectJobPost from './Blogs2/PerfectJobPost.js';
// import HiringConsultant from './Blogs2/HiringConsultant.js';
// import HighDemandSkills2024 from './Blogs2/HighDemandSkills2024.js';
// import FutureOfRemoteWork from './Blogs2/FutureOfRemoteWork.js';
// import FreelanceCareerTips from './Blogs2/FreelanceCareerTips.js';
// import SEOTrainingGuide from './Blogs3/SEOTrainingGuide.js';
// import SeoAuditArticle from './Blogs3/SeoAuditArticle.js';
// import SEOArticle from './Blogs3/SEOArticle.js';
// import LocalSEOGuide from './Blogs3/LocalSEOGuide.js';
// import FreelanceConsultingArticle from './Blogs3/FreelanceConsultingArticle.js';
// import ProductivityArticle from './blogs4/ProductivityArticle.js';
// import FallFashionTrends from './blogs4/FallFashionTrends.js';
// import SellGiftCardsBlogPost from './blogs4/SellGiftCardsBlogPost.js'
// import YouTubeAutomationArticle from './blogs4/YouTubeAutomationArticle.js';
// import PersonalWebsiteGuide from './blogs4/PersonalWebsiteGuide.js'
// import FirebaseFastAPIArticle from './blogs5/FirebaseFastAPIArticle.js'

// import CryptoCourse from './courses/cryptoCourse/CryptoCourse.js';
// import TradingCourse from './courses/tradingCourse/TradingCourse.js';


// import RelatedTeachers from './courses/tradingCourse/TradingCourse.js';
// import CryptoDetails from './courses/cryptoCourse/CryptoTrailer.js';
// import TradingDetails from './courses/tradingCourse/TradingTrailer.js';

// import PayPalButton from './Payment/Paypal/Paypal.js';
// import CryptoCoursePayment from './courses/cryptoCourse/CryptoCoursePayment.js';
// import NewMoneyLandingPage from './courses/NewMoneyLandingPage.js';
// import FacebookAdsPayment from './courses/FacebookAdsCourse/FacebookAdsPayment.js';
// import FaceBookAdsCourse from './courses/FacebookAdsCourse/FaceBookAdsCourse.js';
// import FaWeek1 from './courses/FacebookAdsCourse/Week1/FaWeek1.js';
// import FaW1V1 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V1.js'
// import FaW1V2 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V2.js'
// import FaW1V3 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V3.js'
// import FaW1V4 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V4.js'
// import FaW1V5 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V5.js'
// import FaW1V6 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V6.js'
// import FaW1V7 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V7.js'
// import FaW1V8 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V8.js'
// import FaW1V9 from './courses/FacebookAdsCourse/Week1/Videos/FaW1V9.js'
// import LandingPage1 from './LandingPages/LandingPage1.js';

// import FaWeek2 from './courses/FacebookAdsCourse/Week2/FaWeek2.js';
// import FaW2V1 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V1.js';
// import FaW2V2 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V2.js';
// import FaW2V3 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V3.js';
// import FaW2V4 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V4.js';
// import FaW2V5 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V5.js';
// import FaW2V6 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V6.js';
// import FaW2V7 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V7.js';
// import FaW2V8 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V8.js';
// import FaW2V9 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V9.js';
// import FaW2V10 from './courses/FacebookAdsCourse/Week2/Videos/FaW2V10.js';
// import FaWeek3 from './courses/FacebookAdsCourse/Week3/FaWeek3.js';
// import FaW3V1 from './courses/FacebookAdsCourse/Week3/Videos/FaW3V1.js';
// import FaW3V2 from './courses/FacebookAdsCourse/Week3/Videos/FaW3V2.js';
// import FaW3V3 from './courses/FacebookAdsCourse/Week3/Videos/FaW3V3.js';
// import FaW3V4 from './courses/FacebookAdsCourse/Week3/Videos/FaW3V4.js';
// import FaW3V5 from './courses/FacebookAdsCourse/Week3/Videos/FaW3V5.js';
// import FaW3V6 from './courses/FacebookAdsCourse/Week3/Videos/FaW3V6.js';
// import FaW3V7 from './courses/FacebookAdsCourse/Week3/Videos/FaW3V7.js';
// import FaW3V8 from './courses/FacebookAdsCourse/Week3/Videos/FaW3V8.js';
// import ThankYouPage1 from './ThankYouPages/ThankYouPage1.js';
// import FaWeek4 from './courses/FacebookAdsCourse/Week4/FaWeek4.js';
// import FaWeek5 from './courses/FacebookAdsCourse/Week5/FaWeek5.js';
// import FaW4V1 from './courses/FacebookAdsCourse/Week4/Videos/FaW4V1.js';
// import FaW4V2 from './courses/FacebookAdsCourse/Week4/Videos/FaW4V2.js';
// import FaW4V3 from './courses/FacebookAdsCourse/Week4/Videos/FaW4V3.js';
// import FaW4V4 from './courses/FacebookAdsCourse/Week4/Videos/FaW4V4.js';
// import FaW5V1 from './courses/FacebookAdsCourse/Week5/Videos/FaW5V1.js';
// import FaW5V2 from './courses/FacebookAdsCourse/Week5/Videos/FaW5V2.js';
// import FaW5V3 from './courses/FacebookAdsCourse/Week5/Videos/FaW5V3.js';
// import FaW5V4 from './courses/FacebookAdsCourse/Week5/Videos/FaW5V4.js';
// import KillerFacebookAds from './courses/Ebooks/KillerFacebookAds.js';
// import KillerFacebookAdsTrailer from './courses/Ebooks/KillerFacebookAdsTrailer.js'
// import FacebookAdsTrailer from './courses/FacebookAdsCourse/FacebookAdsTrailer.js';
// import PSTrailer from './courses/PS/PSTrailer.js';
// import PSCourse from './courses/PS/PSCourse.js'
// import PSWeek1 from './courses/PS/Week1/PSWeek1.js';
// import PSWeek2 from './courses/PS/Week2/PSWeek2.js';
// import PSWeek3 from './courses/PS/Week3/PSWeek3.js';
// import PSW1V1 from './courses/PS/Week1/Videos/PSW1V1.js';
// import PSW1V2 from './courses/PS/Week1/Videos/PSW1V2.js';
// import PSW1V3 from './courses/PS/Week1/Videos/PSW1V3.js'
// import PSW1V4 from './courses/PS/Week1/Videos/PSW1V4.js'
// import PSW1V5 from './courses/PS/Week1/Videos/PSW1V5.js'
// import PSW1V6 from './courses/PS/Week1/Videos/PSW1V6.js'

// import PSW2V1 from './courses/PS/Week2/Videos/PSW2V1.js'
// import PSW2V2 from './courses/PS/Week2/Videos/PSW2V2.js'
// import PSW2V3 from './courses/PS/Week2/Videos/PSW2V3.js'
// import PSW2V4 from './courses/PS/Week2/Videos/PSW2V4.js'
// import PSW2V5 from './courses/PS/Week2/Videos/PSW2V5.js'
// import PSW2V6 from './courses/PS/Week2/Videos/PSW2V6.js'

// import PSW3V1 from './courses/PS/Week3/Videos/PSW3V1.js'
// import PSW3V2 from './courses/PS/Week3/Videos/PSW3V2.js'
// import PSW3V3 from './courses/PS/Week3/Videos/PSW3V3.js'
// import PSW3V4 from './courses/PS/Week3/Videos/PSW3V4.js'
// import PSW3V5 from './courses/PS/Week3/Videos/PSW3V5.js'
// import PSW3V6 from './courses/PS/Week3/Videos/PSW3V6.js'

// import YTTrailer from './courses/YoutubeAds/YTTrailer.js';
// import YTCourse from './courses/YoutubeAds/YTCourse.js'
// import YTWeek1 from './courses/YoutubeAds/Week1/YTWeek1.js';
// import YTWeek2 from './courses/YoutubeAds/Week2/YTWeek2.js';
// import YTW1V1 from './courses/YoutubeAds/Week1/Videos/YTW1V1.js';
// import YTW1V2 from './courses/YoutubeAds/Week1/Videos/YTW1V2.js';
// import YTW1V3 from './courses/YoutubeAds/Week1/Videos/YTW1V3.js';
// import YTW1V4 from './courses/YoutubeAds/Week1/Videos/YTW1V4.js';

// import YTW2V1 from './courses/YoutubeAds/Week1/Videos/YTW1V1.js';
// import YTW2V2 from './courses/YoutubeAds/Week1/Videos/YTW1V2.js';
// import YTW2V3 from './courses/YoutubeAds/Week1/Videos/YTW1V3.js';
// import CryptoTrailer from './courses/cryptoCourse/CryptoTrailer.js';
// import HowToUseFA from './courses/Ebooks/HowToUseFA/HowToUseFA.js';
// import HowToUseFATrailer from './courses/Ebooks/HowToUseFA/HowToUseFATrailer.js';
// import TRTrailer from './courses/tradingCourse/TradingTrailer.js';
// import DMAITrailer from './courses/DMAI/DMAITrailer.js';
// import DMAICourse from './courses/DMAI/DMAICourse.js';
// import TRWeek1 from './courses/tradingCourse/Week1/TRWeek1.js';
// import TRW1V1 from './courses/tradingCourse/Week1/Videos/TRW1V1.js';
// import TRW1V2 from './courses/tradingCourse/Week1/Videos/TRW1V2.js';
// import TRW1V3 from './courses/tradingCourse/Week1/Videos/TRW1V3.js';
// import TRW1V4 from './courses/tradingCourse/Week1/Videos/TRW1V4.js';
// import TRW1V5 from './courses/tradingCourse/Week1/Videos/TRW1V5.js';
// import TRW1V6 from './courses/tradingCourse/Week1/Videos/TRW1V6.js';
// import TRWeek2 from './courses/tradingCourse/Week2/TRWeek2.js';
// import TRW2V1 from './courses/tradingCourse/Week2/Videos/TRW2V1.js';
// import TRW2V2 from './courses/tradingCourse/Week2/Videos/TRW2V2.js';
// import TRW2V3 from './courses/tradingCourse/Week2/Videos/TRW2V3.js';
// import TRW2V4 from './courses/tradingCourse/Week2/Videos/TRW2V4.js';
// import TRW2V5 from './courses/tradingCourse/Week2/Videos/TRW2V5.js';
// import TRW2V6 from './courses/tradingCourse/Week2/Videos/TRW2V6.js';
// import TRWeek3 from './courses/tradingCourse/Week3/TRWeek3.js';
// import TRW3V1 from './courses/tradingCourse/Week3/Videos/TRW3V1.js';
// import TRW3V2 from './courses/tradingCourse/Week3/Videos/TRW3V2.js';
// import TRW3V3 from './courses/tradingCourse/Week3/Videos/TRW3V3.js';
// import TRW3V4 from './courses/tradingCourse/Week3/Videos/TRW3V4.js';
// import TRW3V5 from './courses/tradingCourse/Week3/Videos/TRW3V5.js';
// import TRWeek4 from './courses/tradingCourse/Week4/TRWeek4.js';
// import TRW4V1 from './courses/tradingCourse/Week4/Videos/TRW4V1.js';
// import TRW4V10 from './courses/tradingCourse/Week4/Videos/TRW4V10.js';
// import TRW4V9 from './courses/tradingCourse/Week4/Videos/TRW4V9.js';
// import TRW4V8 from './courses/tradingCourse/Week4/Videos/TRW4V8.js';
// import TRW4V7 from './courses/tradingCourse/Week4/Videos/TRW4V7.js';
// import TRW4V6 from './courses/tradingCourse/Week4/Videos/TRW4V6.js';
// import TRW4V5 from './courses/tradingCourse/Week4/Videos/TRW4V5.js';
// import TRW4V4 from './courses/tradingCourse/Week4/Videos/TRW4V4.js';
// import TRW4V3 from './courses/tradingCourse/Week4/Videos/TRW4V3.js';
// import TRW4V2 from './courses/tradingCourse/Week4/Videos/TRW4V2.js';
// import TRW4V11 from './courses/tradingCourse/Week4/Videos/TRW4V11.js';
// import TRW4V12 from './courses/tradingCourse/Week4/Videos/TRW4V12.js';
// import TRW4V13 from './courses/tradingCourse/Week4/Videos/TRW4V13.js';
// import TRW4V14 from './courses/tradingCourse/Week4/Videos/TRW4V14.js';
// import TRW4V15 from './courses/tradingCourse/Week4/Videos/TRW4V15.js';
// import TRW4V16 from './courses/tradingCourse/Week4/Videos/TRW4V16.js';
// import TRW4V17 from './courses/tradingCourse/Week4/Videos/TRW4V17.js';
// import TRW4V18 from './courses/tradingCourse/Week4/Videos/TRW4V18.js';
// import TRW4V19 from './courses/tradingCourse/Week4/Videos/TRW4V19.js';
// import TRW4V20 from './courses/tradingCourse/Week4/Videos/TRW4V20.js';
// import TRW4V21 from './courses/tradingCourse/Week4/Videos/TRW4V21.js';
// import TRW4V22 from './courses/tradingCourse/Week4/Videos/TRW4V22.js';
// import TRWeek5 from './courses/tradingCourse/Week5/TRWeek5.js';
// import TRW5V1 from './courses/tradingCourse/Week5/Videos/TRW5V1.js';
// import TRW5V2 from './courses/tradingCourse/Week5/Videos/TRW5V2.js';
// import TRW5V3 from './courses/tradingCourse/Week5/Videos/TRW5V3.js';
// import TRW5V4 from './courses/tradingCourse/Week5/Videos/TRW5V4.js';
// import TRW5V5 from './courses/tradingCourse/Week5/Videos/TRW5V5.js';
// import TRW5V6 from './courses/tradingCourse/Week5/Videos/TRW5V6.js';
// import TRW5V7 from './courses/tradingCourse/Week5/Videos/TRW5V7.js';
// import TRW5V8 from './courses/tradingCourse/Week5/Videos/TRW5V8.js';
// import TRW5V9 from './courses/tradingCourse/Week5/Videos/TRW5V9.js';
// import TRW5V10 from './courses/tradingCourse/Week5/Videos/TRW5V10.js';
// import TRW5V11 from './courses/tradingCourse/Week5/Videos/TRW5V11.js';
// import TRW5V12 from './courses/tradingCourse/Week5/Videos/TRW5V12.js';
// import TRW5V13 from './courses/tradingCourse/Week5/Videos/TRW5V13.js';
// import TRW5V14 from './courses/tradingCourse/Week5/Videos/TRW5V14.js';
// import TRW5V15 from './courses/tradingCourse/Week5/Videos/TRW5V15.js';
// import TRW5V16 from './courses/tradingCourse/Week5/Videos/TRW5V16.js';
// import TRW5V17 from './courses/tradingCourse/Week5/Videos/TRW5V17.js';
// import TRW5V18 from './courses/tradingCourse/Week5/Videos/TRW5V18.js';
// import TRW5V19 from './courses/tradingCourse/Week5/Videos/TRW5V19.js';
// import TRW5V20 from './courses/tradingCourse/Week5/Videos/TRW5V20.js';
// import TRW5V21 from './courses/tradingCourse/Week5/Videos/TRW5V21.js';
// import TRW5V22 from './courses/tradingCourse/Week5/Videos/TRW5V22.js';
// import TRW5V23 from './courses/tradingCourse/Week5/Videos/TRW5V23.js';
// import CRWeek1 from './courses/cryptoCourse/Week1/CRWeek1.js';
// import CRWeek2 from './courses/cryptoCourse/Week2/CRWeek2.js';
// import CRWeek3 from './courses/cryptoCourse/Week3/CRWeek3.js';
// import CRWeek4 from './courses/cryptoCourse/Week4/CRWeek4.js';
// import CRWeek5 from './courses/cryptoCourse/Week5/CRWeek5.js';
// import CRW1V1 from './courses/cryptoCourse/Week1/Videos/CRW1V1.js';
// import CRW1V2 from './courses/cryptoCourse/Week1/Videos/CRW1V2.js';
// import CRW2V1 from './courses/cryptoCourse/Week2/Videos/CRW2V1.js';
// import CRW2V2 from './courses/cryptoCourse/Week2/Videos/CRW2V2.js';
// import CRW2V3 from './courses/cryptoCourse/Week2/Videos/CRW2V3.js';
// import CRW3V1 from './courses/cryptoCourse/Week3/Videos/CRW3V1.js';
// import CRW3V2 from './courses/cryptoCourse/Week3/Videos/CRW3V2.js';
// import CRW3V3 from './courses/cryptoCourse/Week3/Videos/CRW3V3.js';
// import CRW4V1 from './courses/cryptoCourse/Week4/Videos/CRW4V1.js';
// import CRW5V1 from './courses/cryptoCourse/Week5/Videos/CRW5V1.js';
// import CRW5V2 from './courses/cryptoCourse/Week5/Videos/CRW5V2.js';
// import CRW5V3 from './courses/cryptoCourse/Week5/Videos/CRW5V3.js';
// import JapaneseCandleSticks from './courses/Ebooks/JapaneseCandleSticks/JapaneseCandleSticks.js';
// import JapaneseCandleSticksTrailer from './courses/Ebooks/JapaneseCandleSticks/JapaneseCandleSticksTrailer.js';
// import FacebookAdsGuide2024 from './SEO/FacebookGuide2024.js';
// import FacebookAdsLandingPage from './LandingPages/FacebookAdsLandingPage.js';
// import FALandingSignUp from './courses/FacebookAdsCourse/FALandingSignUp.js';
// import PurchasedCourses from './Client/PurchasedCourses/PurchasedCourses.js';
// import ClientProfile from './Client/ClientProfile/ClientProfile.js';
// import AccountSettings from './Client/AccountSettings/AccountSettings.js';
// import ClientCertifications from './Client/ClientCertifications/ClientCertifications.js';
// import TradeGptLandingPage from './LandingPages/TradeGptLandingPage.js';
// import MessageAdvisor from './Client/MessageAdvisor/MessageAdvisor.js';


// import NewMoney from './courses/NewMoney.js';
// import MainCourse from './courses/MainCourse.js';
// import CRWeek6 from './courses/cryptoCourse/Week6/CRWeek6.js';
// import CRW6V1 from './courses/cryptoCourse/Week6/Videos/CRW6V1.js';
// import CRW6V2 from './courses/cryptoCourse/Week6/Videos/CRW6V2.js';
// import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy.js';
// import TermsConditions from './TermsConditions/TermsConditions.js';
// import ReactThankYouRA from './CL-OUT-EBOOK-IN-COURSE/RA/ThankYouRA.js';
// import LandingPageRA from './CL-OUT-EBOOK-IN-COURSE/RA/LandingRA.js';
// import MainHome from './Client/ClientHome/MainHome.js';
// import MLWorld from './MLWorld/MLWorld.js';
// import AwabMLLevel1VP1 from './MLWorld/Instructors/AwabML/AwabMLLevel1/AwabMLLevel1resources/AwabMLLevel1VP1.js';
// import AwabMLLevel1 from './MLWorld/Instructors/AwabML/AwabMLLevel1/AwabMLLevel1.js';
// import AwabML from './MLWorld/Instructors/AwabML/AwabML.js';

// import InstructorSignUp from './InstructorSignUp/InstructorSignUp.js';
// import InstructorExam from './InstructorExam/InstructorExam.js';
// import InstructorDashboard from './InstructorDashboard/InstructorDashboard.js';
// import AddLesson from './UploadCourse/MyAddEditLessons/AddLesson.js';
// import SignIn from './SignUpIn&ProfileEdit/SignIn.js';
// import AddLevel from './UploadCourse/MyAddEditLevels/AddLevel.js';
// import MyWorlds from './UploadCourse/MyAddEditWorlds/MyWorlds.js';
// import MyLevels from './UploadCourse/MyAddEditLevels/MyLevels.js';
// import MyLessons from './UploadCourse/MyAddEditLessons/MyLessons.js';
// import InsideLesson from './UploadCourse/MyAddEditLessons/InsideLesson.js';
// import Worlds from './ExploreWorlds/WorldProcess/Worlds/WorldsMainHome/Worlds.js';
// import SkillerSignUp from './SignUpIn&ProfileEdit/SignUp/SignUp.js';
// import Categories from './SignUpIn&ProfileEdit/SignUp/Categories.js';
// import Certifications from './SignUpIn&ProfileEdit/SignUp/Certifications.js';
// import PreviousExperience from './SignUpIn&ProfileEdit/SignUp/PreviousExperience.js';
// import Picture from './SignUpIn&ProfileEdit/SignUp/Picture.js';
// import Letter from './SignUpIn&ProfileEdit/SignUp/Letter.js';
// import SubscribedIn from './ExploreWorlds/SubscribedIn/SubscribedIn.js';
// import SetSubscribedInButton from './ExploreWorlds/SubscribedIn/SetSubscribedInButton.js';
// import MyEarnings from './MyEarnings/MyEarnings.js';
// import Chats1 from './Chats/Chats1.js';
// import Chats2 from './Chats/Chats2.js';
// import Notifications1 from './Notifications/Notifications1.js';
// import Notifications2 from './Notifications/Notifications2.js';
// import Exam1 from './ExternalExamNB/Exam1.js';
// import Exam2 from './ExternalExamNB/Exam2.js';
// import Certifications1 from './ExternalCertificationsNB/Certifications1.js';
// import Certifications2 from './ExternalCertificationsNB/Certifications2.js';
// import SendNotification from './Notifications/SendNotification.js';
// import StartExam from './ExternalExamNB/StartExam.js';
// import Levels from './ExploreWorlds/WorldProcess/Levels/Levels.js';
// import Lessons from './ExploreWorlds/WorldProcess/Lessons/Lessons.js';
// import Resources from './ExploreWorlds/WorldProcess/Resources/Resources.js';
// import ChooseURAdvisor from './ExploreWorlds/WorldProcess/ChooseAdvisor/ChooseURAdvisor.js';
// import SetWithId from './Chats/SetWithId.js';
// import MyStudentsChats1 from './ExploreWorlds/WorldProcess/WorldChat/MyStudentsChats1.js';
// import MyAdvisorChat from './ExploreWorlds/WorldProcess/WorldChat/MyAdvisorChat.js';
// import MyStudentsChats2 from './ExploreWorlds/WorldProcess/WorldChat/MyStudentsChats2.js';
// import WorldChat from './ExploreWorlds/WorldProcess/WorldChat/WorldChat1.js';
// import WorldChat2 from './ExploreWorlds/WorldProcess/WorldChat/WorldChat2.js';
// import SetWithIdForStudentsChat from './ExploreWorlds/WorldProcess/WorldChat/SetWithIdForStudentsChat.js';
// import SetWithIdForWorldChat from './ExploreWorlds/WorldProcess/WorldChat/SetWithIdForWorldChat.js';
// import SetAdvisorManually from './ExploreWorlds/WorldProcess/ChooseAdvisor/SetAdvisorManually.js';
// import SetWorldId from './ExploreWorlds/WorldProcess/SetAndNavigate/SetWorldId.js';
// import GExams from './ExternalExamNB/GExams.js';
// import GExams4Students from './ExternalExamNB/GExams4Students.js';
// import GExams4Advisor from './ExternalExamNB/GExams4Adv.js';
// import GProfile from './Profile/GProfile/GProfile.js';

// import GetPosts from './ExploreWorlds/WorldProcess/Levels/WorldFeeds/GetPosts.js';
// import axios from 'axios';
// import MProfile from './Profile/MProfile/MProfile.js';
// import AllUploads from './ManageUploads/AllUploads.js';
// import ManageFinancial from './ManageFinancial/ManageFinancial.js';
// import ManageWorld from './ManageUploads/ManageWorld.js';
// import ManageLevel from './ManageUploads/ManageLevel.js';
// import ManageLesson from './ManageUploads/ManageLesson.js';
// import SKLANDING from './DefiningFolder/SKLANDING.js';
// import DefiningPage from './DefiningFolder/DefinigPage.js';
// import DefiningVideo from './SignUpIn&ProfileEdit/SignUp/DefiningVideo.js';
// import ProtectedRoute from './ProtectedRoute.js';
// import WUploadPost from './ExploreWorlds/WorldProcess/Levels/UploadPost/WUploadPost.js';
// import CoverPhoto from './SignUpIn&ProfileEdit/SignUp/CoverPhoto.js';
// import RealWorldResults from './SignUpIn&ProfileEdit/SignUp/RealWorldResults.js';

// import UploadWorldTrailer from './UploadCourse/MyAddEditWorlds/Trailer/UploadWorldTrailer.js';
// import AddWorld from './UploadCourse/MyAddEditWorlds/AddWorld.js';
// import WorldsDisplay from './ExploreWorlds/WorldProcess/Worlds/WorldsMainHome/TsWorlds.js';
// import Acheivement from './Profile/Acheivement/Acheivement.js';
// import UploadPostToAllLevels from './ExploreWorlds/WorldProcess/UploadPost/UploadPostToAllLevels.js';
// import EditPost from './ExploreWorlds/WorldProcess/UploadPost/EditPost.js';
// import SavePost from './ExploreWorlds/WorldProcess/UploadPost/SavePost.js';
// import GetWorldTrailer from './UploadCourse/MyAddEditWorlds/Trailer/GetWorldTrailer.js';
// import EditWorld from './UploadCourse/MyAddEditWorlds/EditWorld.js';

// import MyCertifications from './ExternalCertificationsNB/MyCertifications.js';
// import MyExams from './UploadCourse/Exam/MyExams.js';

// // import AddWorld from './UploadCourse/MyAddEditWorlds/AddWorld.js';


// function App() {




  
//     return (
    
//      <Routes>
//       <Route path="/sign-up" element={<SignUp />} />
//       <Route path="/auth" element={<SignUpLogin />} />
      
  
//       <Route path="/client" element={<MainHome/>} />


//         <Route path="/client-sign-up" element={<ClientSignUp />} />

//       <Route path="/provider" element={<PMainHome/>} />
//       <Route path="/provider-sign-up" element={<TeacherSignUp />} />
//       <Route path="/sign-up-client-provider" element={<DetermineSignUp />} />
//       <Route path="/about-us" element={<AboutUsPage/>}/>
//       <Route path="/digital-marketing" element={<BlogPost/>}/>
      
//       {
//        <Route path="*" Component={NotFoundPage} /> 
//       }

//     <Route path="/" element={<DefiningPage/>}/>

//     <Route path="/join-us" element={<LandingPage/>}/>
//     <Route path="/learn-more" element={<LearnMorePage/>}/>
//     <Route path="/testimonials" element={<TestimonialsPage/>}/>
//       <Route path="/inviting" element={<Inviting/>}/>
//       <Route path="/posting-term" element={<Term/>}/>
//       <Route path="/posting-title" element={<Title/>}/>
//       <Route path="/posting-skills" element={<Skills/>}/>
//       <Route path="/posting-scope" element={<Scope/>}/>
//       <Route path="/posting-experience" element={<Experience/>}/>
//       <Route path="/posting-pudget" element={<Pudget/>}/>
//       <Route path="/posting-description" element={<Description/>}/>
//       <Route path="/posting-overview" element={<Overview/>}/>
//       <Route path="/type-of-work" element={<TypeOfWork/>}/>
//       <Route path="/letter" element={<Letter/>}/>

//       <Route path="/previous-experience" element={<PreviousExperience/>}/>
//       <Route path="/rate-per-hour" element={<RatePerHour/>}/>

//       <Route path="/blog" element={<Blog/>}/>
//       <Route path="/provider-profile-4client" element={<ProviderProfile/>}/>
//       <Route path="/notifications" element={<ClientNotifications/>}/>
//       <Route path="/proposal" element={<ProposalAtClient/>}/>
//       <Route path="/make-proposal" element={<ProviderProposal/>}/>
//       <Route path="/accept" element={<Accept/>}/>
//       <Route path="/client-messages" element={<ClientMessages/>}/>
//       <Route path="/choose-method" element={<ChooseMethod/>}/>
//       <Route path="/Stripe" element={<Payment/>}/>
//       <Route path="/paypal" element={<PayPalButton/>}/>
//       <Route path="/cancel" element={<Cancel/>}/>
//       <Route path="/provider-messages" element={<ProviderMessages/>}/>
//       <Route path="/provider-client-messaging" element={<ProviderToClientMessage/>}/>
//       <Route path="/client-received-messages" element={<ReceivedMessages1Client/>}/>
//       <Route path="/client-access-messages" element={<ReceivedMessages2Client/>}/>
//       <Route path="/pay/success" element ={<PaymentSuccess/>}/>
//       <Route path="/pay/cancel" element={<PaymentCancel/>}/>
//       <Route path="/client-profile-picture" element={<ClientProfilePicture/>}/>
//       <Route path="/provider-profile-4provider" element={<ProviderProfile4Provider/>}/>
//       <Route path="/client-posts" element= {<ClientPosts/>}/>
//       <Route path="/provider-invitaions" element={<ProviderInvitaions/>}/>
//       <Route path="/choosen-invitation" element={<ProviderInvitation2/>}/>
//       <Route path="/report" element={<MakeReport/>}/>
//       <Route path="/client-category" element={<ClientCategory/>}/>
//       <Route path="/payment-register" element={<PaymentRegister/>}/>
//       <Route path="/my-reports" element={<ProviderReports/>}/>
//       <Route path="/certain-provider" element={<CertainProvider/>}/>
//       <Route path="/invitation-acceptance" element={<InvitationAcceptance/>}/>
//       <Route path="/contact-us" element={<ContactUs/>}/>
//       <Route path="/services" element={<ServiceConnect/>}/>
//       <Route path="/affiliate-marketing" element={<AffiliateMarketingPost/>}/>
//       <Route path="/your-beauty-guidance" element={<BeautyFashionBlog/>}/>
//       <Route path="/connect-clients-with-providers" element={<BlogPost5/>}/>
//       <Route path="/find-best-coding-tutor-online" element={<FindBestCodingTutor/>}/>
//       <Route path="/benefits-of-online-legal-consultations" element={<BenefitsOfOnlineLegalConsultations/>}/>
//       <Route path="/tips-for-learning-graphic-design-remotely" element={<TipsForLearningGraphicDesign/>}/>
//       <Route path="/find-expert" element={<FindExpertPost/>}/>
//       <Route path="/hire-graphic-designer" element={<HireGraphicDesignerPost/>}/>
//       <Route path="/seo-optimization" element={<SeoOptimizationPost/>}/>
//       <Route path="/virtual-assistance" element={<VirtualAssistantPost/>}/>
//       <Route path="/future-of-remote-work" element={<FutureOfRemoteWork/>}/>
//       <Route path="/high-demand-skills-2024" element={<HighDemandSkills2024/>}/>
//       <Route path="/job-post" element={<PerfectJobPost/>}/>
//       <Route path="/freelance-career" element={<FreelanceCareerTips/>}/>
//       <Route path="/hiring-consultant" element={<HiringConsultant/>}/>
//       <Route path="/seo-training" element={<SEOTrainingGuide/>}/>
//       <Route path="/seo-audit" element={<SeoAuditArticle/>}/>
//       <Route path="/blogs" element={<SEOArticle/>}/>
//       <Route path="/fashion-trends" element={<FallFashionTrends/>}/>
//       <Route path="/seo-tools" element={<SEOTrainingGuide/>}/>
//       <Route path="/local-seo-strategies" element={<LocalSEOGuide/>}/>
//       <Route path="/freelancing-consultant" element={<FreelanceConsultingArticle/>}/>
//       <Route path="/maximize-productivity" element={<ProductivityArticle/>}/>
//       <Route path="/sell-gift-card" element={<SellGiftCardsBlogPost/>}/>
//       <Route path="/youtube-automation" element={<YouTubeAutomationArticle/>}/>
//       <Route path="/personal-website" element={<PersonalWebsiteGuide/>}/>
//       <Route path="/firebase-fast-api" element={<FirebaseFastAPIArticle/>}/>
//       <Route path="/new-money" element={<NewMoney/>}/>
//       <Route path="/crypto" element={<CryptoCourse/>}/>
//       <Route path="/trading" element={<TradingCourse/>}/>
//       <Route path="/marketing" element={<DMAICourse/>}/>

//       <Route path="/related-providers" element={<RelatedTeachers/>}/>
//       <Route path="/course-details/crypto" element={<CryptoDetails/>}/>
//       <Route path="/course-details/trading" element={<TradingDetails/>}/>
//       <Route path="/course-details/marketing" element={<DMAITrailer/>}/>

//       <Route path="/pay-crypto-course" element={<CryptoCoursePayment/>}/>
//       <Route path="new-money-home" element={<NewMoneyLandingPage/>}/>
//       <Route path='/pay-facebook-ads-course' element={<FacebookAdsPayment/>}/>
//       <Route path="facebook-ads-course" element={<FaceBookAdsCourse/>}/>
//       <Route path="facebook-ads-course/week-1" element={<FaWeek1/>}/>
//       <Route path="facebook-ads-course/week-1/video-1" element={<FaW1V1/>}/>
//       <Route path="facebook-ads-course/week-1/video-2" element={<FaW1V2/>}/>
//       <Route path="facebook-ads-course/week-1/video-3" element={<FaW1V3/>}/>
//       <Route path="facebook-ads-course/week-1/video-4" element={<FaW1V4/>}/>
//       <Route path="facebook-ads-course/week-1/video-5" element={<FaW1V5/>}/>
//       <Route path="facebook-ads-course/week-1/video-6" element={<FaW1V6/>}/>
//       <Route path="facebook-ads-course/week-1/video-7" element={<FaW1V7/>}/>
//       <Route path="facebook-ads-course/week-1/video-8" element={<FaW1V8/>}/>
//       <Route path="facebook-ads-course/week-1/video-9" element={<FaW1V9/>}/>
//       <Route path="facebook-ads-course/week-2" element={<FaWeek2/>}/>
//       <Route path="facebook-ads-course/week-2/video-1" element={<FaW2V1/>}/>
//       <Route path="facebook-ads-course/week-2/video-2" element={<FaW2V2/>}/>
//       <Route path="facebook-ads-course/week-2/video-3" element={<FaW2V3/>}/>
//       <Route path="facebook-ads-course/week-2/video-4" element={<FaW2V4/>}/>
//       <Route path="facebook-ads-course/week-2/video-5" element={<FaW2V5/>}/>
//       <Route path="facebook-ads-course/week-2/video-6" element={<FaW2V6/>}/>
//       <Route path="facebook-ads-course/week-2/video-7" element={<FaW2V7/>}/>
//       <Route path="facebook-ads-course/week-2/video-8" element={<FaW2V8/>}/>
//       <Route path="facebook-ads-course/week-2/video-9" element={<FaW2V9/>}/>
//       <Route path="facebook-ads-course/week-2/video-10" element={<FaW2V10/>}/>
//       <Route path="business-guide-ebook" element={<LandingPage1/>}/>
//       <Route path="facebook-ads-course/week-3" element={<FaWeek3/>}/>
//       <Route path="facebook-ads-course/week-3/video-1" element={<FaW3V1/>}/>
//       <Route path="facebook-ads-course/week-3/video-2" element={<FaW3V2/>}/>
//       <Route path="facebook-ads-course/week-3/video-3" element={<FaW3V3/>}/>
//       <Route path="facebook-ads-course/week-3/video-4" element={<FaW3V4/>}/>
//       <Route path="facebook-ads-course/week-3/video-5" element={<FaW3V5/>}/>
//       <Route path="facebook-ads-course/week-3/video-6" element={<FaW3V6/>}/>
//       <Route path="facebook-ads-course/week-3/video-7" element={<FaW3V7/>}/>
//       <Route path="facebook-ads-course/week-3/video-8" element={<FaW3V8/>}/>
//       <Route path="download-trade-with-gpt" element={<ThankYouPage1/>}/>
//       <Route path="facebook-ads-course/week-4" element={<FaWeek4/>}/>
//       <Route path="facebook-ads-course/week-4/video-1" element={<FaW4V1/>}/>
//       <Route path="facebook-ads-course/week-4/video-2" element={<FaW4V2/>}/>
//       <Route path="facebook-ads-course/week-4/video-3" element={<FaW4V3/>}/>
//       <Route path="facebook-ads-course/week-4/video-4" element={<FaW4V4/>}/>
//       <Route path="/facebook-ads-trailer" element={<FacebookAdsTrailer/>}/>

//       <Route path="facebook-ads-course/week-5" element={<FaWeek5/>}/>
//       <Route path="facebook-ads-course/week-5/video-1" element={<FaW5V1/>}/>
//       <Route path="facebook-ads-course/week-5/video-2" element={<FaW5V2/>}/>
//       <Route path="facebook-ads-course/week-5/video-3" element={<FaW5V3/>}/>
//       <Route path="facebook-ads-course/week-5/video-4" element={<FaW5V4/>}/>
//       <Route path="killer-facebook-ads" element={<KillerFacebookAds/>}/>
//       <Route path="killer-facebook-ads-trailer" element={<KillerFacebookAdsTrailer/>}/>
      
//       <Route path="PS-trailer" element={<PSTrailer/>}/>
//       <Route path="PS-course" element={<PSCourse/>}/>
//       <Route path="PS-course/week-1" element={<PSWeek1/>}/>
//       <Route path="PS-course/week-2" element={<PSWeek2/>}/>
//       <Route path="PS-course/week-3" element={<PSWeek3/>}/>

//       <Route path="PS-course/week-1/video-1" element={<PSW1V1/>}/>
//       <Route path="PS-course/week-1/video-2" element={<PSW1V2/>}/>
//       <Route path="PS-course/week-1/video-3" element={<PSW1V3/>}/>
//       <Route path="PS-course/week-1/video-4" element={<PSW1V4/>}/>
//       <Route path="PS-course/week-1/video-5" element={<PSW1V5/>}/>
//       <Route path="PS-course/week-1/video-6" element={<PSW1V6/>}/>

//       <Route path="PS-course/week-2/video-1" element={<PSW2V1/>}/>
//       <Route path="PS-course/week-2/video-2" element={<PSW2V2/>}/>
//       <Route path="PS-course/week-2/video-3" element={<PSW2V3/>}/>
//       <Route path="PS-course/week-2/video-4" element={<PSW2V4/>}/>
//       <Route path="PS-course/week-2/video-5" element={<PSW2V5/>}/>
//       <Route path="PS-course/week-2/video-6" element={<PSW2V6/>}/>

//       <Route path="PS-course/week-3/video-1" element={<PSW3V1/>}/>
//       <Route path="PS-course/week-3/video-2" element={<PSW3V2/>}/>
//       <Route path="PS-course/week-3/video-3" element={<PSW3V3/>}/>
//       <Route path="PS-course/week-3/video-4" element={<PSW3V4/>}/>
//       <Route path="PS-course/week-3/video-5" element={<PSW3V5/>}/>
//       <Route path="PS-course/week-3/video-6" element={<PSW3V6/>}/>
      
//       <Route path="YT-trailer" element={<YTTrailer/>}/>
//       <Route path="YT-course" element={<YTCourse/>}/>
//       <Route path="YT-course/week-1" element={<YTWeek1/>}/>
//       <Route path="YT-course/week-2" element={<YTWeek2/>}/>
//       <Route path="YT-course/week-1/video-1" element={<YTW1V1/>}/>
//       <Route path="YT-course/week-1/video-2" element={<YTW1V2/>}/>
//       <Route path="YT-course/week-1/video-3" element={<YTW1V3/>}/>
//       <Route path="YT-course/week-1/video-4" element={<YTW1V4/>}/>
//       <Route path="YT-course/week-2/video-1" element={<YTW2V1/>}/>
//       <Route path="YT-course/week-2/video-2" element={<YTW2V2/>}/>
//       <Route path="YT-course/week-2/video-3" element={<YTW2V3/>}/>
//       <Route path="crypto-trailer" element={<CryptoTrailer/>}/>
//       <Route path="how-to-use-FA" element={<HowToUseFA/>}/>
//       <Route path="how-to-use-FA-Trailer" element={<HowToUseFATrailer/>}/>
      
//       <Route path="/trading-trailer" element={<TRTrailer/>}/>
//       <Route path="/marketing-trailer" element={<DMAITrailer/>}/>
//       <Route path="/DigitalMarketingAI-course" element={<DMAICourse/>}/>

//       <Route path="/TR-course/week-1" element={<TRWeek1/>}/>
//       <Route path="/TR-course/week-1/video-1" element={<TRW1V1/>}/>
//       <Route path="/TR-course/week-1/video-2" element={<TRW1V2/>}/>
//       <Route path="/TR-course/week-1/video-3" element={<TRW1V3/>}/>
//       <Route path="/TR-course/week-1/video-4" element={<TRW1V4/>}/>
//       <Route path="/TR-course/week-1/video-5" element={<TRW1V5/>}/>
//       <Route path="/TR-course/week-1/video-6" element={<TRW1V6/>}/>

//       <Route path="/TR-course/week-2" element={<TRWeek2/>}/>
//       <Route path="/TR-course/week-2/video-1" element={<TRW2V1/>}/>
//       <Route path="/TR-course/week-2/video-2" element={<TRW2V2/>}/>
//       <Route path="/TR-course/week-2/video-3" element={<TRW2V3/>}/>
//       <Route path="/TR-course/week-2/video-4" element={<TRW2V4/>}/>
//       <Route path="/TR-course/week-2/video-5" element={<TRW2V5/>}/>
//       <Route path="/TR-course/week-2/video-6" element={<TRW2V6/>}/>
      

//       <Route path="/TR-course/week-3" element={<TRWeek3/>}/>
//       <Route path="/TR-course/week-3/video-1" element={<TRW3V1/>}/>
//       <Route path="/TR-course/week-3/video-2" element={<TRW3V2/>}/>
//       <Route path="/TR-course/week-3/video-3" element={<TRW3V3/>}/>
//       <Route path="/TR-course/week-3/video-4" element={<TRW3V4/>}/>
//       <Route path="/TR-course/week-3/video-5" element={<TRW3V5/>}/>

//       <Route path="/TR-course/week-4" element={<TRWeek4/>}/>
//       <Route path="/TR-course/week-4/video-1" element={<TRW4V1/>}/>
//       <Route path="/TR-course/week-4/video-2" element={<TRW4V2/>}/>
//       <Route path="/TR-course/week-4/video-3" element={<TRW4V3/>}/>
//       <Route path="/TR-course/week-4/video-4" element={<TRW4V4/>}/>
//       <Route path="/TR-course/week-4/video-5" element={<TRW4V5/>}/>
//       <Route path="/TR-course/week-4/video-6" element={<TRW4V6/>}/>
//       <Route path="/TR-course/week-4/video-7" element={<TRW4V7/>}/>
//       <Route path="/TR-course/week-4/video-8" element={<TRW4V8/>}/>
//       <Route path="/TR-course/week-4/video-9" element={<TRW4V9/>}/>
//       <Route path="/TR-course/week-4/video-10" element={<TRW4V10/>}/>
//       <Route path="/TR-course/week-4/video-11" element={<TRW4V11/>}/>
//       <Route path="/TR-course/week-4/video-12" element={<TRW4V12/>}/>
//       <Route path="/TR-course/week-4/video-13" element={<TRW4V13/>}/>
//       <Route path="/TR-course/week-4/video-14" element={<TRW4V14/>}/>
//       <Route path="/TR-course/week-4/video-15" element={<TRW4V15/>}/>
//       <Route path="/TR-course/week-4/video-16" element={<TRW4V16/>}/>
//       <Route path="/TR-course/week-4/video-17" element={<TRW4V17/>}/>
//       <Route path="/TR-course/week-4/video-18" element={<TRW4V18/>}/>
//       <Route path="/TR-course/week-4/video-19" element={<TRW4V19/>}/>
//       <Route path="/TR-course/week-4/video-20" element={<TRW4V20/>}/>
//       <Route path="/TR-course/week-4/video-21" element={<TRW4V21/>}/>
//       <Route path="/TR-course/week-4/video-22" element={<TRW4V22/>}/>

//           <Route path="/TR-course/week-5" element={<TRWeek5/>}/>
//           <Route path="/TR-course/week-5/video-1" element={<TRW5V1/>}/>
//           <Route path="/TR-course/week-5/video-2" element={<TRW5V2/>}/>
//           <Route path="/TR-course/week-5/video-3" element={<TRW5V3/>}/>
//           <Route path="/TR-course/week-5/video-4" element={<TRW5V4/>}/>
//           <Route path="/TR-course/week-5/video-5" element={<TRW5V5/>}/>
//           <Route path="/TR-course/week-5/video-6" element={<TRW5V6/>}/>
//           <Route path="/TR-course/week-5/video-7" element={<TRW5V7/>}/>
//           <Route path="/TR-course/week-5/video-8" element={<TRW5V8/>}/>
//           <Route path="/TR-course/week-5/video-9" element={<TRW5V9/>}/>
//           <Route path="/TR-course/week-5/video-10" element={<TRW5V10/>}/>
//           <Route path="/TR-course/week-5/video-11" element={<TRW5V11/>}/>
//           <Route path="/TR-course/week-5/video-12" element={<TRW5V12/>}/>
//           <Route path="/TR-course/week-5/video-13" element={<TRW5V13/>}/>
//           <Route path="/TR-course/week-5/video-14" element={<TRW5V14/>}/>
//           <Route path="/TR-course/week-5/video-15" element={<TRW5V15/>}/>
//           <Route path="/TR-course/week-5/video-16" element={<TRW5V16/>}/>
//           <Route path="/TR-course/week-5/video-17" element={<TRW5V17/>}/>
//           <Route path="/TR-course/week-5/video-18" element={<TRW5V18/>}/>
//           <Route path="/TR-course/week-5/video-19" element={<TRW5V19/>}/>
//           <Route path="/TR-course/week-5/video-20" element={<TRW5V20/>}/>
//           <Route path="/TR-course/week-5/video-21" element={<TRW5V21/>}/>
//           <Route path="/TR-course/week-5/video-22" element={<TRW5V22/>}/>
//           <Route path="/TR-course/week-5/video-23" element={<TRW5V23/>}/>
//           <Route path="/crypto-course/week-1" element={<CRWeek1/>}/>
//           <Route path="/crypto-course/week-1/video-1" element={<CRW1V1/>}/>
//           <Route path="/crypto-course/week-1/video-2" element={<CRW1V2/>}/>

//           <Route path="/crypto-course/week-2" element={<CRWeek2/>}/>
//           <Route path="/crypto-course/week-2/video-1" element={<CRW2V1/>}/>
//           <Route path="/crypto-course/week-2/video-2" element={<CRW2V2/>}/>
//           <Route path="/crypto-course/week-2/video-3" element={<CRW2V3/>}/>

//           <Route path="/crypto-course/week-3" element={<CRWeek3/>}/>
//           <Route path="/crypto-course/week-3/video-1" element={<CRW3V1/>}/>
//           <Route path="/crypto-course/week-3/video-1" element={<CRW3V2/>}/>
//           <Route path="/crypto-course/week-3/video-1" element={<CRW3V3/>}/>


//           <Route path="/crypto-course/week-4" element={<CRWeek4/>}/>
//           <Route path="/crypto-course/week-4/video-1" element={<CRW4V1/>}/>

//           <Route path="/crypto-course/week-5" element={<CRWeek5/>}/>
//           <Route path="/crypto-course/week-5/video-1" element={<CRW5V1/>}/>
//           <Route path="/crypto-course/week-5/video-2" element={<CRW5V2/>}/>
//           <Route path="/crypto-course/week-5/video-3" element={<CRW5V3/>}/>
//           <Route path="/crypto-course/week-6" element={<CRWeek6/>}/>
//           <Route path="/crypto-course/week-6/video-1" element={<CRW6V1/>}/>
//           <Route path="/crypto-course/week-6/video-2" element={<CRW6V2/>}/>


//           <Route path="/Japanese-Candle-Sticks-trailer" element={<JapaneseCandleSticksTrailer/>}/>
//           <Route path="/facebook-ads-guide" element={<FacebookAdsGuide2024/>}/>
//           <Route path="/facebook-ads-academy" element={<FacebookAdsLandingPage/>}/>

//           <Route path="my-learning" element={<PurchasedCourses/>}/>
//           <Route path="/my-certificationss" element={<ClientCertifications/>}/>
//           <Route path="/trade-with-gpt" element={<TradeGptLandingPage/>}/>
//           <Route path="/ask-advisor" element={<MessageAdvisor/>}/>

//           <Route path="/courses" element={<MainCourse/>}/>
//           <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
//           <Route path="/terms-conditions" element={<TermsConditions/>}/>
//           <Route path="/react-ebook" element={<LandingPageRA/>}/>
//           <Route path="/download-react-ebook" element={<ReactThankYouRA/>}/>
//           <Route path="/ML-world" element={<MLWorld/>}/>
//           <Route path="/Awab-ML" element={<AwabML/>}/>
//           <Route path="/Awab-ML-Level1" element={<AwabMLLevel1/>}/>
//           <Route path="/Awab-ML-Level1-Lesson1" element={<AwabMLLevel1VP1/>}/>
   























//       <Route path="/sign-up" element={<InstructorSignUp/>}/>
//       <Route path="/skiller-signUp" element={<SkillerSignUp/>}/>
//       <Route path = "/categories" element={<Categories/>}/>

//       <Route path="/certifications" element={<Certifications/>}/>

//       <Route path="/experience" element={<PreviousExperience/>}/>

//       <Route path="/picture" element={<Picture/>}/>

//       <Route path="cover-picture" element={<CoverPhoto/>}/>

//       <Route path="/real-world-results" element={<RealWorldResults/>}/>

//       <Route path="/letter" element={<Letter/>}/>
      
//       <Route path="/skiller-signIn" element={<SignIn/>}/>
//       {/*GET*/}
//       <Route path="/my-worlds" element={          <ProtectedRoute >
//    <MyWorlds/>
//    </ProtectedRoute>}/>
      
      
//       {/*upload */}
//        <Route path="/add-world" element={          <ProtectedRoute >
//    <AddWorld/>
//    </ProtectedRoute>}/> 

   
//    <Route path="/edit-world" element={          <ProtectedRoute >
//    <EditWorld/>


//    </ProtectedRoute>}/> 

//       <Route path="/add-level" element={          <ProtectedRoute >
//    <AddLevel/>
//    </ProtectedRoute>}/>

//       <Route path="/add-lesson" element={          <ProtectedRoute >
//    <AddLesson/>
//    </ProtectedRoute>}/>


//       <Route path="/lesson-resources" element={          <ProtectedRoute >
//    <InsideLesson/>
//    </ProtectedRoute>}/>
   
//       <Route path = "/worlds"         element={
//           <ProtectedRoute >
//             <Worlds />
//           </ProtectedRoute>
//         }/>

// <Route path="/profile/:id" element={          
//    <GProfile/>
//    }/>

//       <Route path="/my-profile/:id" element={          <ProtectedRoute >
//    <MProfile/>
//    </ProtectedRoute>}/>



//               <Route path="/levels/:worldId"  element={
//           <ProtectedRoute >
//             <Levels />
//           </ProtectedRoute>
//         }/>
//       <Route path="/levels"  element={
//           <ProtectedRoute >
//             <Levels />
//           </ProtectedRoute>
//         }/>




//       <Route path = "/lessons" element={
//           <ProtectedRoute >
//             <Lessons />
//           </ProtectedRoute>
//         }/>


//       <Route path="/resources"  element={
//           <ProtectedRoute >
//             <Resources />
//           </ProtectedRoute>
//         }/>

        
//       <Route path="/my-chats" element={          <ProtectedRoute >
// <Chats1/>
// </ProtectedRoute>}/>   


//       <Route path="/chat" element={          <ProtectedRoute >
// <Chats2/>
// </ProtectedRoute>}/>


//       <Route path="/set-with-id" element={          <ProtectedRoute >
//    <SetWithId/>
//    </ProtectedRoute>}/>


//    <Route path="/my-exams" element={          <ProtectedRoute >
//    <MyExams/>
//    </ProtectedRoute>}/>



//       <Route path="/my-advisor-chat" element={          <ProtectedRoute >
//    <MyAdvisorChat/>
//    </ProtectedRoute>}/>


//       <Route path="/my-students-chats" element={          <ProtectedRoute >
//    <MyStudentsChats1/>
//    </ProtectedRoute>}/>


//       <Route path="/student-chat" element={          <ProtectedRoute >
//    <MyStudentsChats2/>
//    </ProtectedRoute>}/>


//       <Route path="/set-with-id-for-student-chat" element={          <ProtectedRoute >
//    <SetWithIdForStudentsChat/>
//    </ProtectedRoute>}/>

//       <Route path="/my-world-chats" element={          <ProtectedRoute >
//    <WorldChat/>
//    </ProtectedRoute>}/>


//       <Route path="/world-chat" element={          <ProtectedRoute >
//    <WorldChat2/>
//    </ProtectedRoute>}/>


//       <Route path="/set-with-id-for-world-chat" element={          <ProtectedRoute >
//    <SetWithIdForWorldChat/>
//    </ProtectedRoute>}/>



//       <Route path="/send-notification" element={          <ProtectedRoute >
//    <SendNotification/>
//    </ProtectedRoute>}/>




//       <Route path="exam" element={          <ProtectedRoute >
//    <Exam2/>
//    </ProtectedRoute>}/>
//       <Route path="/start-exam" element={          <ProtectedRoute >
//    <StartExam/>
//    </ProtectedRoute>}/>
//       <Route path="/Gexams" element={          <ProtectedRoute >
//    <GExams/>
//    </ProtectedRoute>}/>

//       <Route path="/g-exam-4-advisor" element={          <ProtectedRoute >
//    <GExams4Advisor/>
//    </ProtectedRoute>}/>
//       <Route path = "/g-exam-4-student" element={          <ProtectedRoute >
//    <GExams4Students/>
//    </ProtectedRoute>}/>



      
//       <Route path = "/set-subscirbed-in-to-true" element={          <ProtectedRoute >
//    <SetSubscribedInButton/>
//    </ProtectedRoute>}/>


//       <Route path = "/insert-advisor-manually-dispatch-worldId" element={          <ProtectedRoute >
//    <SetAdvisorManually/>
//    </ProtectedRoute>}/>


//       <Route path="/worldId-DNT" element={          <ProtectedRoute >
//    <SetWorldId/>
//    </ProtectedRoute>}/>


//       <Route path="/manage-world" element={          <ProtectedRoute >
//    <ManageWorld/>
//    </ProtectedRoute>}/>


//       <Route path="/manage-level" element={          <ProtectedRoute >
//    <ManageLevel/>
//    </ProtectedRoute>}/>


//       <Route path="/manage-lesson" element={          <ProtectedRoute >
//    <ManageLesson/>
//    </ProtectedRoute>}/>


//       <Route path="/get-world-posts" element={          <ProtectedRoute >
//    <GetPosts/>
//    </ProtectedRoute>}/>


//       <Route path="/manage-uploads" element={          <ProtectedRoute >
//    <AllUploads/>
//    </ProtectedRoute>}/>

//       <Route path="/definig-video" element={          <ProtectedRoute >
//    <DefiningVideo/>
//    </ProtectedRoute>}/>






//    <Route path="/lesson-resources" element={          <ProtectedRoute >
//    <InsideLesson/>
//    </ProtectedRoute>}/>


//    <Route path="/ts-worlds" element={          <ProtectedRoute >
//    <WorldsDisplay/>
//    </ProtectedRoute>}/>

//    <Route path="/upload-post-for-world" element={          <ProtectedRoute >
//    <WUploadPost/>
//    </ProtectedRoute>}/>


//    <Route path="/world-trailer/:worldId" element={          <ProtectedRoute >
//    <GetWorldTrailer/>
//    </ProtectedRoute>}/>




//    <Route path="/acheivment" element={<Acheivement/>}/>
//    <Route path="/edit-post-to-world" element={<EditPost/>}/>
//    <Route path="/save-post" element={<SavePost />}/>










































//    <Route path="/choose-advisor" element={          <ProtectedRoute >
//    <ChooseURAdvisor/>
//    </ProtectedRoute>}/>
//    <Route path="/upload-post-to-world" element={<UploadPostToAllLevels/>}/>
//    <Route path="/my-notifications" element={          <ProtectedRoute >
//    <Notifications1/>
//    </ProtectedRoute>}/>
//       <Route path="/notification" element={          <ProtectedRoute >
//    <Notifications2/>
//    </ProtectedRoute>}/>
//       <Route path="/certification" element={          <ProtectedRoute >
//    <Certifications2/>
//    </ProtectedRoute>}/>
//    <Route path="/my-certifications" element={          <ProtectedRoute >
//    <MyCertifications/>
//    </ProtectedRoute>}/>
//    <Route path="/manage-financial" element={          <ProtectedRoute >
//    <ManageFinancial/>
//    </ProtectedRoute>}/>
//     </Routes> 

//   );
// }

// export default App;


