import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom right, #2c3e50, #4ca1af);
  color: white;
  font-family: 'Arial', sans-serif;
  padding: 20px;
  text-align: center;
`;

const Card = styled.div`
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  color: #2c3e50;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 16px;
  margin: 0;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background: #27ae60;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #1e8449;
  }
`;

function MyCertifications() {
    const navigate = useNavigate()
  return (
    <Container>
      <Card>
        <Title>No Certifications Yet</Title>
        <Description>
          You haven’t collected any certifications yet. Start learning and earn
          certifications to showcase your achievements.
        </Description>
        <Button onClick={() => navigate ('/worlds')}>Explore Courses</Button>
      </Card>
    </Container>
  );
}

export default MyCertifications;
