import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars, FaBell, FaComments, FaSearch, FaStar } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { setExamId, setWithId } from '../../reducers/reducers';

function MyExams() {

    const [selectedExam, setSelectedExam] = useState(null);
    const [rate, setRate] = useState('')
    const [examsS, setExamsS] = useState([])
    const [examsA, setExamsA] = useState([])
    useEffect(() => {
      const fetchExamsS = async () => {
        try {
          const { data } = await axios.get('https://sell-skill.com/api/endpoints/getExams4Student');
          console.log('exams from frontend 4 student ===========--------------------->>>>>>> ',data)
          setExamsS(data);
        } catch (err) {
          console.error(err);
        }
      };

      const fetchExamsA = async () => {
        try {
          const { data } = await axios.get('https://sell-skill.com/api/endpoints/getExams4Advisor');
          console.log('exams from frontend 4 student ===========--------------------->>>>>>> ',data)
          setExamsA(data);
        } catch (err) {
          console.error(err);
        }
      };
      fetchExamsA();
      fetchExamsS();
    }, []);
  
  const handleRateAdvisor = async(studentId) => {
    await axios.post('https://sell-skill.com/api/endpoints/rateAdvisor', {studentId, worldId, levelId,  rate})
  } 
  
  const handleViewCertification = async (examId) => {
    dispatch(setExamId(examId))
    navigate ('/certification')
  }
  
  const handleYes = async(examId) => {
  //available
  await axios.patch('https://sell-skill.com/api/endpoints/setAdvisorAsAvailable')
  }
  const handleNo = async(examId) => {
  //nothing
  }



    const certificationId = uuidv4();
    const purchaseId = uuidv4();
    const [exams, setExams] = useState([]);
    const [worldId, setWorldId] = useState('')
    const [levelId, setLevelId] = useState('')
    // State for individual exam inputs
    const [examInputs, setExamInputs] = useState({});
  
    const handleInputChange = (examId, field, value) => {
      setExamInputs((prev) => ({
        ...prev,
        [examId]: {
          ...prev[examId],
          [field]: value,
        },
      }));
    };
  
    const handleInsertExam = async (examId, studentId) => {
      const inputs = examInputs[examId] || {};
      const { examTime, passingGrade, examWillBeAs } = inputs;
      try {
        await axios.post(
          "https://sell-skill.com/api/endpoints/setTimeLinkPassingGExamType",
          {
  
            examId,
            studentId,
            examTime,
            passingGrade,
            examWillBeAs,
            worldId
          }
        );
        alert("Exam details inserted successfully!");
      } catch (err) {
        console.error("Error inserting exam details:", err);
      }
    };
  
    const handleSendGrade = async (examId, studentId) => {
      const inputs = examInputs[examId] || {};
      const { grade, comment } = inputs;
      try {
        await axios.post(
          "https://sell-skill.com/api/endpoints/sendGradeComment",
          {           certificationId,examId,worldId, levelId, studentId, grade, comment, purchaseId }
        );
        alert("Grade and comment sent successfully!");
      } catch (err) {
        console.error("Error sending grade and comment:", err);
      }
    };
  
    useEffect(() => {
      const fetchExams = async () => {
        try {
          const { data } = await axios.get(
            "https://sell-skill.com/api/endpoints/getExams4Advisor"
          );
          setExams(data);
        } catch (err) {
          console.error(err);
        }
      };
      fetchExams();
    }, []);


  const [messages, setMessages] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    const getSendersInfo = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/chats1')
      setMessages(response.data)
      console.log('senders ==========>> > > > > > > > > >>>>   >>>>  >> ',response.data)
    }
    getSendersInfo();
  }, [])
  const handleNameClick = (withId) => {
    dispatch(setWithId(withId))
    navigate('/chat')

  }









  


////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
const [skillerId, setSkillerId] = useState('')
const dropdownRef = useRef(null);
const [profilePictureSrc, setProfilePictureSrc] = useState('')
const [showDropdown, setShowDropdown] = useState(false);
const [sidebarVisible, setSidebarVisible] = useState(true);
const [activeTab, setActiveTab] = useState('allChats')
const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};


useEffect(() => {
    const getSkillerId = async() => {
     const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
     setSkillerId(response.data)
     console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
    }
    getSkillerId();
  },[])
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { responseType: 'blob' }
            );
    
            console.log('Fetched blob:', response.data);
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    


    fetchProfilePicture();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
  console.log("Before clearing localStorage:", localStorage);
  localStorage.clear();
  console.log("After clearing localStorage:", localStorage);

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

  console.log("Cookies after clearing:", document.cookie);

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee



const handleToggleAStudentExams = () => {
  setActiveTab('asStudent')

}

const handleAsAdvisorChatTab = () => {
  setActiveTab('asAdvisor')

  
}



  return (


    <PageContainer>

<Container>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>

          <ContentContainer>
            <MainContent>

                              <Header>
                  <TabContainer>
                      <Tab
                          active={activeTab === 'asStudent'}
                          onClick={handleToggleAStudentExams}
                      >
                           As Student 
                      </Tab>
                      <Tab
                          active={activeTab === 'asAdvisor'}
                          onClick={ handleAsAdvisorChatTab}
                      >
                         As Advisor 
                      </Tab>

                  </TabContainer>

              </Header>
{   activeTab ==="asAdvisor" &&           <div className="container mt-4">

      {examsA.map((exam) => {
        const inputs = examInputs[exam._id] || {};
        return (
          <div key={exam._id} className="border p-3 mb-3">
            <h3>{exam.title}</h3>
            <p>Student name: {exam.studentName}</p>
            <p>World: {exam.worldName}</p>
            <p>Level: {exam.levelNumber}</p>
            <p>Time: {exam.examTime || "Not Set"}</p>
            <p>Link: {exam.examLink || "Not Set"}</p>

            <div className="mb-3">
              <input
                type="datetime-local"
                className="form-control"
                value={inputs.examTime || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "examTime", e.target.value)
                }
                placeholder="Set exam time"
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Exam will be as"
                value={inputs.examWillBeAs || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "examWillBeAs", e.target.value)
                }
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Passing grade"
                value={inputs.passingGrade || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "passingGrade", e.target.value)
                }
              />
            </div>

{    exam.examLink &&    <>  <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter grade"
                value={inputs.grade || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "grade", e.target.value)
                }
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter comment"
                value={inputs.comment || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "comment", e.target.value)
                }
              />
            </div> </>}


            <div className="d-flex gap-2">
              <Button
                onClick={() => handleInsertExam(exam._id, exam.studentId)}
                variant="primary"
              >
                Send Details
              </Button>
{     exam.examLink &&         <Button
                onClick={() => handleSendGrade(exam._id, exam.studentId)}
                variant="success"
              >
                Send Grade
              </Button>}

            </div>
          </div>
        );
      })}
    </div>}


              {   activeTab ==="asStudent" &&         <div>

      {examsS.map((exam) => (
        <div key={exam._id} style={{ border: '1px solid black', padding: '10px', margin: '10px' }}>
          <h3>{exam.title}</h3>
          <p>instructor name: {exam.instructorName}</p>
          <p>World: {exam.worldName}</p>
          <p>Level: {exam.levelNumber}</p>
         { exam.examTime && <p>Time: {exam.examTime }</p>}
          {exam.examLink &&  <a href= {exam.examLink }>Go To Exam</a>}


















      
  
          <ContentWrapper sidebarVisible={sidebarVisible}>
    {/* Other components */}
    <FeedbackSection sidebarVisible={sidebarVisible}>
                        <RatingContainer>
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    size={24}
                                    color={star <= rate ? "#f1c40f" : "#bdc3c7"}
                                    onClick={() => setRate(star)}
                                    style={{ cursor: "pointer" }}
                                />
                            ))}
                        </RatingContainer>
          <button onClick={() => handleRateAdvisor(exam.studentId)}>Submit Rating</button>
          </FeedbackSection>
          </ContentWrapper>
          {
             exam.isPassed && exam.isWorldAllowingAdvisors && 
             <> <p>want to be an advisor? </p>
             <button onClick={handleYes(exam._id)}>yes</button>
             <button onClick={handleNo(exam._id)}>no</button> </>
            
          }


















          {
            exam.grade && <p>My Grade: {exam.grade}</p>
          }
          {
            exam.isEnded && <p>{exam.isPassed}</p>
          }
          {
            exam.comment && <p>{exam.comment}</p>
          }
          {
            exam.isPassed && exam.grade && <button onClick={() => handleViewCertification(exam._id)}>view certification</button>
          }
  
        </div>
      ))}

    </div>}
      
              </MainContent>
              </ContentContainer>
              </Container>
    </PageContainer>
  )
}

export default MyExams

















const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        font-size: 20px;
        color: #2980b9;
    }
`;



const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;

;





const RatingContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
`;











const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 160px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;












const SubTab = styled.div`
    flex: 1;
    text-align: center;
    padding: 8px 0; /* Reduce padding for shorter height */
    cursor: pointer;
    font-size: 16px; /* Slightly smaller font size */
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }

`;

 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;


    const FeedbackSection = styled.div`
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth resizing */

    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '150px' : '0')}; /* Adjust for Sidebar */
    width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')}; /* Width based on Sidebar */
    box-sizing: border-box;

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #2c3e50;
    }

    textarea {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: vertical;
    }

    button {
        background-color: #27ae60;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #1e8449;
        }
    }
`;


const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const SubTabContainer = styled.div
`  position: fixed; /* Fixed to stay visible while scrolling */
  top: 135px; /* Add spacing to prevent overlap (adjusted from 120px) */
  left: 300px; /* Align with the main content considering sidebar width */
  width: calc(100% - 300px); /* Stretch full width minus sidebar */
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 1px solid #ddd; /* Subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  z-index: 950; /* Stay above content but below navbar */
  padding: 5px 0; /* Reduced padding for a shorter height */
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};


  @media (max-width: 768px) {
    width: 100%;
      top: 139px; /* Add spacing to prevent overlap (adjusted from 120px) */
    left: 0; /* Align to the screen */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

const SearchContainer = styled.div`
    position: relative;

        @media (max-width: 768px) {
        max-width: 200px;
        padding-left: 150px;
        input {
            font-size: 12px;
        }
    }
`;

const SearchBar = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;

        input {
            font-size: 12px;
        }
    }
`;





const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;