import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LP.css'; // External CSS file for enhanced styling

const LPTSWEBDEV = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [viewerData, setViewerData] = useState({ Name: '', Email: '', Number: '' });
  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity
  const navigate = useNavigate();

  // Validate form inputs
  const validateForm = () => {
    const nameRegex = /^[a-zA-Z\s]{2,}$/; // Accepts at least two alphabetic characters
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return nameRegex.test(viewerData.Name) && emailRegex.test(viewerData.Email);
  };

  // Track form validity on input change
  useEffect(() => {
    setIsFormValid(validateForm());
  }, [viewerData]);

  // Track page view for multiple Pixel IDs
  useEffect(() => {
    const pixelIds = ['1669785423607093', '620286700362285', '1580943482786881'];
  
    if (window.fbq) {
      pixelIds.forEach((pixelId) => {
        window.fbq('init', pixelId);
      });
  
      // Track the event only once after initializing all Pixels
      window.fbq('track', 'PageView', {
        content_name: 'Landing Page view',
        value: 0,
        currency: 'USD',
      });
    }
  }, []);
  

  // Handle submission
  const handleNext = async () => {
    const pixelIds = ['1669785423607093', '620286700362285', '1580943482786881'];
     pixelIds.forEach((pixelId) => {
       if (window.fbq) {
         window.fbq('init', pixelId);
         window.fbq('track', 'Lead', {
           content_name: 'Landing Page Lead',
           value: 0,
           currency: 'USD',
         });
         window.fbq('track', 'ViewContent', {
           content_name: 'Landing Page View Content',
           value: 0,
           currency: 'USD',
         });
       }
     });

    navigate('/download-react-guidance');
    await axios.post('https://sell-skill.com/api/endpoints/SKTsWebDev', viewerData);
  };

  const handleDownloadClick = () => {
    setFormVisible(true);
  };

  return (
    <div className="lp-container">
    {/* Background Animation */}
    <div className="lp-background"></div>
  
    {/* Logo Section */}
    <div className="lp-logo-section">
      <h1 className="lp-title">Master React JS With Ease</h1>
    </div>
  
    {/* Ebook Promotion */}
    <p className="lp-promo">
      Get the ultimate guide to mastering React JS, from basics to real-world projects, 
      <span className="lp-highlight">FREE</span> now!
    </p>
  
    {/* Download Button */}
    <button onClick={handleDownloadClick} className="lp-download-btn">
      DOWNLOAD NOW!
    </button>
  
    {/* PDF Promo Image */}
    <div className="lp-promo-image-container">
      <img
        src="/LandingPageMagnets/ReactJSGuidance.png"
        alt="React JS Guidance Ebook"
        className="lp-promo-image"
      />
    </div>
  
    {/* Modal for user details */}
    <CSSTransition
      in={formVisible}
      timeout={300}
      classNames="lp-modal"
      unmountOnExit
    >
      <div className="lp-modal">
        <h2 className="lp-modal-title">Claim Your Free React JS Guide</h2>
        <input
          type="text"
          placeholder="Your Name"
          className="lp-input"
          onChange={(e) =>
            setViewerData({ ...viewerData, Name: e.target.value })
          }
        />
        <input
          type="email"
          placeholder="Your Email"
          className="lp-input"
          onChange={(e) =>
            setViewerData({ ...viewerData, Email: e.target.value })
          }
        />
        <button
          className="lp-submit-btn"
          onClick={handleNext}
          disabled={!isFormValid} // Disable button if form is invalid
        >
          Submit
        </button>
      </div>
    </CSSTransition>
  
    {/* Footer */}
    <footer className="lp-footer">
      <p>sell-skill © 2025 All Rights Reserved</p>
    </footer>
  </div>
  );
};

export default LPTSWEBDEV;
