  import React from 'react';
  import ReactDOM from 'react-dom/client';
  import './index.css';
  import App from './App';
  import reportWebVitals from './reportWebVitals';
  import { BrowserRouter } from 'react-router-dom';
  import { GoogleOAuthProvider } from '@react-oauth/google';
  import { allowReducer } from './reducers/reducers';
  import { Provider } from 'react-redux';
  import { configureStore } from '@reduxjs/toolkit';


  const store = configureStore({
    reducer: {
      allow: allowReducer, // Ensure the correct key name
    },
  });

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

      <BrowserRouter>
          <Provider store={store}>
        
            <App />
      
            
          </Provider>
          </BrowserRouter>
      </GoogleOAuthProvider>
    </React.StrictMode>
  );

  reportWebVitals();
