import React, { useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

import styled, { createGlobalStyle } from "styled-components";

function SkillerSignUp() {
  const [providerSignUpData, setProviderSignUpData] = useState({
    _id: uuidv4(),
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    fcmToken: "",
  });
  const userIP = "192.168.3.17";
  const [alreadyProviderExist, setAlreadyProviderExist] = useState(false);
  const navigate = useNavigate();

  const googleSuccess = async (response) => {
    const { credential } = response;
    const payload = JSON.parse(atob(credential.split(".")[1]));
    const { email, name, family_name } = payload;

    try {
      const responseProvider = await axios.post(
        "https://sell-skill.com/api/endpoints/skillerSignUp",
        {
          _id: uuidv4(),
          name,
          surname: family_name,
          email,
          password: "",
          confirmPassword: "",
          fcmToken: providerSignUpData.fcmToken,
        }
      );

      const skillerToken = responseProvider.data;

      if (!skillerToken) {
        setAlreadyProviderExist(true);
      } else {
        localStorage.setItem("skillerToken", JSON.stringify(skillerToken));
        if (window.userIP !== userIP) {
        window.fbq('track', 'SignUp', {
          content_name: 'Sign Up',
          value: 0,
          currency: 'USD'
        });}
        navigate("/categories");

      }
    } catch (error) {
      setAlreadyProviderExist(true);
    }
  };

  const handleProviderSignUp = async () => {
    try {
      const responseProvider = await axios.post(
        "https://sell-skill.com/api/endpoints/skillerSignUp",
        providerSignUpData
      );
      const skillerToken = responseProvider.data;

      if (!skillerToken) {
        setAlreadyProviderExist(true);
      } else {

        localStorage.setItem("skillerToken", JSON.stringify(skillerToken));
        // window.fbq('init', '1669785423607093');
        // window.fbq('track', 'SignUp', {
        //   content_name: 'Sign Up',
        //   value: 0,
        //   currency: 'USD'
        // });
        navigate("/categories");

      }
    } catch (error) {
      setAlreadyProviderExist(true);
    }
  };



  return (
    <PageContainer>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>

          <Title>Sign Up as a skiller</Title>
          <StyledForm>
            <StyledFormControl
              type="text"
              placeholder="Name"
              onChange={(e) =>
                setProviderSignUpData({ ...providerSignUpData, name: e.target.value })
              }
            />
            <StyledFormControl
              type="text"
              placeholder="Surname"
              onChange={(e) =>
                setProviderSignUpData({ ...providerSignUpData, surname: e.target.value })
              }
            />
            <StyledFormControl
              type="email"
              placeholder="Email"
              onChange={(e) =>
                setProviderSignUpData({ ...providerSignUpData, email: e.target.value })
              }
            />
            <StyledFormControl
              type="password"
              placeholder="Password"
              onChange={(e) =>
                setProviderSignUpData({ ...providerSignUpData, password: e.target.value })
              }
            />
            <StyledFormControl
              type="password"
              placeholder="Confirm Password"
              onChange={(e) =>
                setProviderSignUpData({
                  ...providerSignUpData,
                  confirmPassword: e.target.value,
                })
              }
            />
            <SubmitButton onClick={handleProviderSignUp}>Next</SubmitButton>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <StyledLink href="/skiller-signIn">Already have an account? Sign in</StyledLink>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <GoogleLogin onSuccess={googleSuccess} />
            </div>
            {alreadyProviderExist && (
              <ErrorMessage>Email already exists</ErrorMessage>
            )}
          </StyledForm>
        </MainCard>
      </StyledContainer>
    </PageContainer>
  );
}

export default SkillerSignUp;

// Global Styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Light gray background */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  padding: 20px;
`;

const HeaderImage = styled.img`
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  color: #2c3e50; /* Dark navbar color */
  text-align: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormControl = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #27ae60;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #219150; /* Darker green on hover */
  }
`;

const StyledLink = styled.a`
  color: #2c3e50;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;




























// import React, { useEffect, useState } from "react";
// import { Container, Card, Form, Button } from "react-bootstrap";
// import { GoogleLogin } from "@react-oauth/google";
// import axios from "axios";
// import { v4 as uuidv4 } from "uuid";
// import { useNavigate } from "react-router-dom";
// import { requestNotificationPermission } from "../../Provider/ProviderProfile4Provider/firebase";
// import styled, { createGlobalStyle } from "styled-components";

// // CSS and Styled Components here...

// function SkillerSignUp() {
//   const [providerSignUpData, setProviderSignUpData] = useState({
//     _id: uuidv4(),
//     name: "",
//     surname: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     fcmToken: "",
//   });

//   const [alreadyProviderExist, setAlreadyProviderExist] = useState(false);
//   const navigate = useNavigate();

//   const googleSuccess = async (response) => {
//     const { credential } = response;
//     const payload = JSON.parse(atob(credential.split(".")[1]));
//     const { email, name, family_name } = payload;

//     try {
//       const responseProvider = await axios.post("https://sell-skill.com/api/endpoints/skillerSignUp", {
//         _id: uuidv4(),
//         name,
//         surname: family_name,
//         email,
//         password: "",
//         confirmPassword: "",
//         fcmToken: providerSignUpData.fcmToken,
//       });

//       const skillerToken = responseProvider.data;

//       if (!skillerToken) {
//         setAlreadyProviderExist(true);
//       } else {
//         localStorage.setItem("skillerToken", JSON.stringify(skillerToken));
//       }
//     } catch (error) {
//       setAlreadyProviderExist(true);
//     }
//   };

//   const handleProviderSignUp = async () => {
//     try {
//       const responseProvider = await axios.post(
//         "https://sell-skill.com/api/endpoints/skillerSignUp",
//         providerSignUpData
//       );
//       const skillerToken = responseProvider.data;

//       console.log("Provider sign up data ===>", providerSignUpData);

//       if (!skillerToken) {
//         setAlreadyProviderExist(true);
//       } else {
//         localStorage.setItem("skillerToken", JSON.stringify(skillerToken));
//         navigate("/categories");
//       }
//     } catch (error) {
//       setAlreadyProviderExist(true);
//     }
//   };

//   // Get FCM token and set it in state
//   useEffect(() => {
//     const fetchFCMToken = async () => {
//       const token = await requestNotificationPermission();
//       if (token) {
//         setProviderSignUpData((prevData) => ({ ...prevData, fcmToken: token }));
//       }
//     };
//     fetchFCMToken();
//   }, []);

//   return (
//     <>
//       <GlobalStyle />
//       <StyledContainer>
//         <MainCard>
//           <HeaderImage src="/images/collaboration-project-men.png" alt="Collaboration and teamwork" />
//           <Title>Sign Up as a Provider</Title>
//           <StyledForm>
//             <StyledFormControl
//               placeholder="Name"
//               onChange={(e) => setProviderSignUpData({ ...providerSignUpData, name: e.target.value })}
//             />
//             <StyledFormControl
//               placeholder="Surname"
//               onChange={(e) => setProviderSignUpData({ ...providerSignUpData, surname: e.target.value })}
//             />
//             <StyledFormControl
//               type="email"
//               placeholder="Email"
//               onChange={(e) => setProviderSignUpData({ ...providerSignUpData, email: e.target.value })}
//             />
//             <StyledFormControl
//               type="password"
//               placeholder="Password"
//               onChange={(e) => setProviderSignUpData({ ...providerSignUpData, password: e.target.value })}
//             />
//             <StyledFormControl
//               type="password"
//               placeholder="Confirm Password"
//               onChange={(e) => setProviderSignUpData({ ...providerSignUpData, confirmPassword: e.target.value })}
//             />

//             <SubmitButton onClick={handleProviderSignUp}>Next</SubmitButton>
//             <div style={{ textAlign: "center", marginTop: "10px" }}>
//               <StyledLink href="/skiller-signIn">Already have an account? Sign in</StyledLink>
//             </div>
//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <GoogleLogin onSuccess={googleSuccess} />
//             </div>
//             {alreadyProviderExist && (
//               <div style={{ color: "red", textAlign: "center", marginTop: "10px" }}>Email already exists</div>
//             )}
//           </StyledForm>
//         </MainCard>
//       </StyledContainer>
//     </>
//   );
// }

// export default SkillerSignUp;

// // Styled Components (same as before)

// const GlobalStyle = createGlobalStyle`
//   body {
//     margin: 0;
//     padding: 0;
//     background-color: #E6F0FF; /* Light Blue background */
//     color: #2F4F4F; /* Dark Gray for text */
//     font-family: Arial, Helvetica, sans-serif;
//   }
// `;

// const StyledContainer = styled(Container)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// `;

// const MainCard = styled(Card)`
//   background-color: #FAFAFA; /* Off-White for the card background */
//   border-radius: 20px;
//   width: 100%;
//   max-width: 700px;
//   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
//   padding: 30px;
// `;

// const HeaderImage = styled.img`
//   width: 100%;
//   border-radius: 20px 20px 0 0;
//   max-height: 200px; /* Limit the height */
//   object-fit: cover; /* Ensure the image covers the area */
// `;

// const Title = styled(Card.Title)`
//   text-align: center;
//   color: #4169E1; /* Royal Blue for the title */
//   margin-bottom: 30px;
//   font-size: 28px;
//   font-weight: bold;
// `;

// const StyledForm = styled(Form)`
//   margin: 20px 0;
// `;

// const StyledFormControl = styled(Form.Control)`
//   margin-bottom: 15px;
//   padding: 10px;
//   border-radius: 8px;
//   border: 1px solid #2F4F4F; /* Dark Gray for input borders */
// `;

// const SubmitButton = styled(Button)`
//   width: 100%;
//   background-color: #FFD700; /* Golden Yellow for the button */
//   border: none;
//   padding: 12px;
//   margin-top: 20px;
//   font-size: 18px;
//   font-weight: bold;
//   border-radius: 8px;

//   &:hover {
//     background-color: #4169E1; /* Royal Blue on hover */
//   }
// `;

// const StyledLink = styled.a`
//   color: #4169E1; /* Royal Blue for the link */
//   text-decoration: none;
//   margin-top: 15px;
//   display: inline-block;

//   &:hover {
//     text-decoration: underline;
//   }
// `;