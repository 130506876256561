import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';

function LandingPage1() {
  const navigate = useNavigate();
  const [viewerData, setViewerData] = useState({Name: '', Email: '', Number: ''});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleNext = async () => {
    navigate('/download-business-guide');
    await axios.post('https://sell-skill.com/api/endpoints/sendCustomerInfo', viewerData);
  };

  useEffect(() => {
    // Check if all fields are filled
    const isFormValid = viewerData.Name.trim() !== '' && 
                        viewerData.Email.trim() !== '' && 
                        viewerData.Number.trim() !== '';
    setIsButtonDisabled(!isFormValid);
  }, [viewerData]);

  useEffect(() => {
    if (window.fbq) {
      // Specify the Pixel ID for the event
      window.fbq('init', 'SPECIFIC_PIXEL_ID'); // Replace with your specific Pixel ID
      window.fbq('track', 'PageView', {
        content_name: 'Thank You Page Lead',
        value: 0,
        currency: 'USD',
      });
    }
  }, []);

  return (
    <div className="container-fluid d-flex flex-column align-items-center justify-content-center min-vh-100" style={{ background: 'linear-gradient(to right, #000, #004080)' }}>
      <div className="card shadow p-4" style={{ maxWidth: '500px', width: '100%' }}>
        <h2 className="text-center mb-4">Facebook Ads Complete Guidance Free E-book</h2>
        <div className="form-group mb-3">
          <input 
            type="text" 
            className="form-control" 
            placeholder="Name" 
            onChange={(e) => setViewerData({ ...viewerData, Name: e.target.value })}
          />
        </div>
        <div className="form-group mb-3">
          <input 
            type="email" 
            className="form-control" 
            placeholder="Email" 
            onChange={(e) => setViewerData({ ...viewerData, Email: e.target.value })}
          />
        </div>
        <div className="form-group mb-3">
          <input 
            type="tel" 
            className="form-control" 
            placeholder="Phone Number" 
            onChange={(e) => setViewerData({ ...viewerData, Number: e.target.value })}
          />
        </div>
        <div className="text-center mb-3">
          <img
            src="ThankYouPagesImages/FAGuide.jpg" 
            alt="Placeholder"
            className="img-fluid mb-3 rounded"
          />
        </div>
        <button 
          className="btn btn-primary btn-block" 
          onClick={handleNext}
          disabled={isButtonDisabled}
        >
          Get Your Guidance
        </button>
      </div>
    </div>
  );
}

export default LandingPage1;
