import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { FaBell, FaComments, FaSearch, FaUserCircle, FaStar, FaUpload, FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaStarHalfAlt, FaRegStar, FaArrowRight, FaUserGraduate, FaChalkboardTeacher, FaCheckCircle, FaShoppingCart, FaVideo, FaPlayCircle, FaTrash, FaCreditCard, FaTimes, FaBars } from 'react-icons/fa';
import { Button, Card, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import { setWithId, setWorldId } from '../reducers/reducers';

function MyStudentsAndMyAdvisors() {
    let filteredWorlds;
    const [worlds, setWorlds] = useState([]);
    const [myAdvisors, setMyAdvisors] = useState([])
    const [myDStudents, setDMyStudents] = useState([])
    const [myNDStudents, setNDMyStudents] = useState([])
    const [skillerId, setSkillerId] = useState('')
    const [activeSubTab, setActiveSubTab] = useState('exploreWorlds');
    const [matesRequests, setMatesRequests] = useState([]);
const [currentMates, setCurrentMates] = useState([]);
const [myUploads, setMyUploads] = useState([])
const [subscribedInWorlds, setSubscribedInWorlds] = useState([])          
const [isSearchVisible, setIsSearchVisible] = useState(false);

const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
};

const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
};

useEffect(() => {
    const getSkillerId = async() => {
     const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
     setSkillerId(response.data)
     console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
    }
    getSkillerId();
  })
// Safely convert large arrays into base64
const bufferToBase64 = (buffer) => {
    if (!Array.isArray(buffer)) return '';

    // Flatten the array if it contains nested arrays
    const flatArray = buffer.flat();

    // Process the flat array in manageable chunks
    const chunkSize = 10000; // Process 10,000 bytes at a time
    let base64String = '';
    for (let i = 0; i < flatArray.length; i += chunkSize) {
        const chunk = flatArray.slice(i, i + chunkSize);
        base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
};




useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);



    const [activeTab, setActiveTab] = useState('myAdvisors');
    const [posts, setPosts] = useState([])
    const [Aposts, setAPosts] = useState([])
    const [ASposts, setASPosts] = useState([])
    const [ASVposts, setASVPosts] = useState([])
    const [profilePictureSrc, setProfilePictureSrc] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const [messages, setMessages] = useState([]);
    const [expandedSection, setExpandedSection] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [myEarning, setMyEarning] = useState('');
    const [myRate, setMyRate] = useState('');
    const [myCart, setMyCart] = useState([]);
    const [selectedWorldComments, setSelectedWorldComments] = useState([]);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [mates, setMates] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);
    const [resources, setResources] = useState([])
    const [myDrafts, setMyDrafts] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

    const [relativePeople, setRelativePeople] = useState([]);
    const [filters, setFilters] = useState({
        category: '',
        priceRange: [0, 1000], // Example: [minPrice, maxPrice]
        minRate: 0,
        publishDateRange: [null, null], // [startDate, endDate]
        minStudents: 0,
        allowAdvisors: null, // true, false, or null for any
        minAdvisors: 0,
    });
    
    const [categories, setCategories] = useState([
        'Technology', 'Business', 'Art', 'Education', 'Health', 'Science'
    ]); // Example categories
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    
    const handleMyStudentsTab = () => {
        setActiveTab('myStudents')
        setActiveSubTab('myDStudents')
        
    }
    const handleMyAdviorsTab = () => {
        setActiveTab('myAdvisors')

        
    }



    const handleShowModal = (post) => {
        setSelectedPost(post);
        setResources([
          ...(post.photos || []).map((item) => ({ type: "photo", content: item.contentUrl })),
          ...(post.videos || []).map((item) => ({ type: "video", content: item.contentUrl })),
          ...(post.pdfs || []).map((item) => ({ type: "pdf", content: item.contentUrl })),
          ...(post.texts || []).map((item) => ({ type: "text", content: item })),
          ...(post.quizs || []).map((item) => ({
            type: "quiz",
            content: item.content,
            choices: item.choices,
            correctAnswer: item.correctAnswer,
          })),
        ]);
    
        console.log('resources =============================-=-=-=-=-=-=-=-=-> > > >  > > > >>  ',resources)
        setShowModal(true);
      };
      const handleCloseModal = () => setShowModal(false);



      useEffect(() => {


   
          const getmyAdvisors = async() => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getmyAdvisors')
            setMyAdvisors(response.data)
            console.log('response.data from getMyAdvisors =========================================================================-=-=-=-==-=-=-=-=-=-> > > > > >  > >>  >> > >  ',response.data)
         }
         const getMyDStudents= async() => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getMyDStudents')
            setDMyStudents(response.data)
            console.log('response.data from getMyDStudents =========================================================================-=-=-=-==-=-=-=-=-=-> > > > > >  > >>  >> > >  ',response.data)
         }
         const getMyNDStudents = async() => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getMyNDStudents')
            setNDMyStudents(response.data)
            console.log('response.data from getMyNDStudents =========================================================================-=-=-=-==-=-=-=-=-=-> > > > > >  > >>  >> > >  ',response.data)
         }

         getmyAdvisors();
         getMyDStudents();
         getMyNDStudents();

  
        
    }, []);



    const handleAddMate = async (mateId) => {
        const mateRequestId = uuidv4();
        await axios.post('https://sell-skill.com/api/endpoints/sendMateRequest', {mateId, mateRequestId})
        console.log(`Add mate with ID: ${mateId}`);

        // Add your logic here
    };
           
    const handleMessage = (mateId) => {
        console.log(`Message mate with ID: ${mateId}`);
        // Add your logic here
    };
    

    const handleAcceptRequest = async( mateId) => {
        console.log('mateId from handleAcceptRequest ===================================================> > > > > ', mateId)
        await axios.patch('https://sell-skill.com/api/endpoints/acceptMateRequest', {mateId})
    }
    const  handleDeclineRequest = async() => {

    }
    const clearAllCookies = () => {
        document.cookie.split(";").forEach((cookie) => {
            const cookieParts = cookie.split("=");
            const cookieName = cookieParts[0].trim();
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
        });
    };
    const handleUnSavePost = async(postId) => {
        await axios.delete('https://sell-skill.com/api/endpoints/unSavePost', {postId})
    }
    const handleLogout = async () => {
        // Clear localStorage
        console.log("Before clearing localStorage:", localStorage);
        localStorage.clear();
        console.log("After clearing localStorage:", localStorage);
    
        // Clear specific keys if clear() doesn't work
        localStorage.removeItem("authenticated");
        localStorage.removeItem("permission");
        localStorage.removeItem("skillerToken");
        localStorage.removeItem("lastExternalReferrer");
        localStorage.removeItem("lastExternalReferrerTime");
    
        // Clear sessionStorage
        sessionStorage.clear();
    
        // Clear all cookies
        const clearAllCookies = () => {
            document.cookie.split(";").forEach((cookie) => {
                const cookieName = cookie.split("=")[0].trim();
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });
        };
        clearAllCookies();
    
        console.log("Cookies after clearing:", document.cookie);
    
        // Delay to ensure clearing is complete
        await new Promise((resolve) => setTimeout(resolve, 100));
    
        // Redirect to login page
        navigate("/skiller-signIn");
    };
    

    //   useEffect(() => {
    //     const getPosts = async () => {
    //       try {
    //         const response = await axios.get(
    //           "https://sell-skill.com/api/endpoints/ASgetPosts"
           
    //         );
    //         console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
    //         setASPosts(response.data);
    
    //         console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
    //       } catch (error) {
    //         console.error("Error fetching posts:", error);
    //       }
    //     };
    //     getPosts();
    //   },[]);  


 
    // useEffect(() => {
    //     const getCart = async () => {
    //         try {
    //             const response = await axios.get('https://sell-skill.com/api/endpoints/getCart');
    //             setMyCart(response.data);
    //             const total = response.data.reduce((sum, world) => sum + (world.price || 0), 0);
    //             setTotalPrice(total); // Update the state
    //         } catch (error) {
    //             console.error('Error fetching cart:', error);
    //         }
    //     };
    //     getCart();
    // }, []);
    


    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);  
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };


         const handleWorldClickFromMyCart = (worldId) => {
         dispatch(setWorldId(worldId))
         navigate('/levels')
     }
     const handleNameClick = (withId) => {
         dispatch(setWithId(withId));
         navigate('/chat');
     };

    //       useEffect(() => {
    //   const getSendersInfo = async () => {
    //          const response = await axios.get('https://sell-skill.com/api/endpoints/chats1');
    //          setMessages(response.data);
    //          console.log('senders ==========>>', response.data);
    //      };
    //      getSendersInfo();
    //  }, []);
    // useEffect(() => {
    //     const myEarning = async () => {
    //         const response = await axios.get('https://sell-skill.com/api/endpoints/myEarning');
    //         setMyEarning(response.data);
    //     };
    //     myEarning();
    // }, []);


    // useEffect(() => {
    //     const myRate = async () => {
    //         const response = await axios.get('https://sell-skill.com/api/endpoints/myRate');
    //         setMyRate(response.data);
    //     };
    //     myRate();
    // }, []);


    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };
    
     filteredWorlds = worlds.filter((world) => {
        const { category, priceRange, minRate, publishDateRange, minStudents, allowAdvisors, minAdvisors } = filters;
    
        const matchesSearchQuery =
            world.worldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            world.worldDescription?.toLowerCase().includes(searchQuery.toLowerCase());
    
        const matchesCategory = !category || world.category?.toLowerCase() === category.toLowerCase();
        const matchesPriceRange =
            (priceRange[0] === 0 && priceRange[1] === 1000) ||
            (priceRange[0] <= world.price && world.price <= priceRange[1]);
        const matchesRate =
            minRate === 0 ||
            ((world.rates?.reduce((sum, r) => sum + r.rate, 0) || 0) / (world.rates?.length || 1)) >= minRate;
        const matchesPublishDate =
            (!publishDateRange[0] || new Date(world.dateOfPublish) >= publishDateRange[0]) &&
            (!publishDateRange[1] || new Date(world.dateOfPublish) <= publishDateRange[1]);
        const matchesStudents = minStudents === 0 || world.numberOfStudents >= minStudents;
        const matchesAdvisors =
            (allowAdvisors === null || world.isWorldAllowingAdvisors === allowAdvisors) &&
            (minAdvisors === 0 || (world.numberOfAdvisors || 0) >= minAdvisors);
    
        return (
            matchesSearchQuery &&
            matchesCategory &&
            matchesPriceRange &&
            matchesRate &&
            matchesPublishDate &&
            matchesStudents &&
            matchesAdvisors
        );
    });
    
    
    

    const handleGetInWorld = (worldId) => {
        dispatch(setWorldId(worldId));
        navigate(`/levels/${worldId}`);
    };

    const handleViewWorldTrailer = (worldId) => {
        dispatch(setWorldId(worldId));
        navigate(`/world-trailer/${worldId}`);
    };

    const handleAddToCart = async (worldId) => {
        await axios.post('https://sell-skill.com/api/endpoints/addToCart', { worldId });
    };

    const handleWorldComments = (comments) => {
        setSelectedWorldComments(comments);
        setShowCommentsModal(true);
    };

    const closeCommentsModal = () => {
        setShowCommentsModal(false);
    };
    const handleToggleWorldsSection = () => {
        setActiveTab('worlds')
        setActiveSubTab('exploreWorlds')
    }

    const handlePurchaseWorldFromCart = (worldId) => {
        dispatch(setWorldId(worldId))
        navigate('/levels')
    }

    const handleDeleteWorldFromCart = async(worldId) => {
        await axios.delete('https://sell-skill.com/api/endpoints/deleteWorldFromCart', {params: {worldId}})
    }

    const handleProfileClick = () => {
        setShowDropdown((prev) => !prev);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
      const handleScroll = () => {
        // Calculate opacity based on scroll position
        const maxScroll = 300; // Adjust based on when you want it to vanish completely
        const currentScroll = window.scrollY;
        const newOpacity = Math.max(0, 1 - currentScroll / maxScroll);
        setOpacity(newOpacity);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    
    return (
        <PageContainer>   
        <Container>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
      



                        <ContentContainer>









                <MainContent style={{ marginTop: '200px', padding: '50px' }}>
                <Header>
    <TabContainer>
        <Tab
            active={activeTab === 'myAdvisors'}
            onClick={handleMyAdviorsTab}
        >
             My Advisors
        </Tab>
        <Tab
            active={activeTab === 'myStudents'}
            onClick={ handleMyStudentsTab}
        >
             My Students
        </Tab>

    </TabContainer>
{  !showDropdown &&  <UploadButtonWrapper>
        <UploadButton onClick={() => navigate('/add-world')}>
            <FaUpload style={{ marginRight: '5px' }} /> Upload World
        </UploadButton>
    </UploadButtonWrapper>}
</Header>




{activeTab === 'myAdvisors' && (
    <>


        {/* Content based on the selected sub-tab */}
        {activeSubTab === 'myAdvisors' && (
            <ContentWrapper sidebarVisible={sidebarVisible}>
            <Container fluid>
                
                <Row>
                    
                    {myAdvisors.map((mate) => {
                        const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                        return (
        <Col
            key={mate._id}
            xs={12}
            sm={mates.length === 1 ? 8 : 6}
            md={mates.length === 1 ? 6 : 4}
            lg={mates.length === 1 ? 4 : 3}
            className="mb-4"
            style={mates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
        >
            <CardContainer>
            <Card
                style={{
                    minHeight: '350px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                {mate.picture && (
                      <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                    <div className="text-center mt-3">
                        <Card.Img
                            variant="top"
                            src={mate.picture || '/images/default-profile.jpg'}
                            alt={`${mate.name || 'Mate'}'s profile`}
                            className="rounded-circle mx-auto"
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'contain',
                                border: '2px solid #ccc',
                            }}
                        />
                    </div>
                    </a>
                )}
                <Card.Body style={{ flex: 1 }}>
                <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                    <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {`${mate.name} ${mate.surname}`}
                    </Card.Title>
                    </a>
                    <Card.Text className="text-center">
                        Total Cash: ${mate.totalCash || 0}
                    </Card.Text>
                    <div className="d-flex justify-content-center mb-3">
                        {Array.from({ length: 5 }, (_, i) => (
                            i < averageRate ? (
                                <FaStar key={i} color="gold" />
                            ) : (
                                <FaRegStar key={i} color="gold" />
                            )
                        ))}
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        {mate.sharedWorlds?.map((world) => (
                             <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                                {world.thumbnail && 
                                    <img
                                        key={world._id}
                                        src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                        alt={world.name || 'World'}
                                        title={world.name || 'World'}
                                        className="rounded-circle"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            objectFit: 'contain',
                                            margin: '5px',
                                        }}
                                    />
                                }
                            </a>
                        ))}
                    </div>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                    <Button variant="success" onClick={() => handleAddMate(mate._id)}>
                        Add Mate
                    </Button>
                    <Button variant="primary" onClick={() => handleMessage(mate._id)}>
                        Message
                    </Button>
                </Card.Footer>
            </Card>
            </CardContainer>
        </Col>
        
                        );
                    })}
                </Row>
            </Container>
        </ContentWrapper>



        )}

        

        

       
    </>
)}







              




         
         {activeTab === 'myStudents' && (
         <>
         
         <SubTabContainer>
                     <SubTab active={activeSubTab === 'myDStudents'} onClick={() => setActiveSubTab('myDStudents')}>

                        World System 
                     </SubTab>
                     <SubTab active={activeSubTab === 'myNDStudents'} onClick={() => setActiveSubTab('myNDstudents')}>
                         Advisor System 
                     </SubTab>

                 </SubTabContainer>
         
                 { activeSubTab === 'myDStudents' &&
                  <ContentWrapper sidebarVisible={sidebarVisible}>
                  <Container fluid>
                      
                      <Row>
                          
                          {myNDStudents.map((mate) => {
                              const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                              return (
              <Col
                  key={mate._id}
                  xs={12}
                  sm={mates.length === 1 ? 8 : 6}
                  md={mates.length === 1 ? 6 : 4}
                  lg={mates.length === 1 ? 4 : 3}
                  className="mb-4"
                  style={mates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
              >
                  <CardContainer>
                  <Card
                      style={{
                          minHeight: '350px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }}
                  >
                      {mate.picture && (
                            <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                          <div className="text-center mt-3">
                              <Card.Img
                                  variant="top"
                                  src={mate.picture || '/images/default-profile.jpg'}
                                  alt={`${mate.name || 'Mate'}'s profile`}
                                  className="rounded-circle mx-auto"
                                  style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'contain',
                                      border: '2px solid #ccc',
                                  }}
                              />
                          </div>
                          </a>
                      )}
                      <Card.Body style={{ flex: 1 }}>
                      <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                          <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {`${mate.name} ${mate.surname}`}
                          </Card.Title>
                          </a>
                          <Card.Text className="text-center">
                              Total Cash: ${mate.totalCash || 0}
                          </Card.Text>
                          <div className="d-flex justify-content-center mb-3">
                              {Array.from({ length: 5 }, (_, i) => (
                                  i < averageRate ? (
                                      <FaStar key={i} color="gold" />
                                  ) : (
                                      <FaRegStar key={i} color="gold" />
                                  )
                              ))}
                          </div>
                          <div className="d-flex justify-content-center mb-3">
                              {mate.sharedWorlds?.map((world) => (
                                   <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                                      {world.thumbnail && 
                                          <img
                                              key={world._id}
                                              src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                              alt={world.name || 'World'}
                                              title={world.name || 'World'}
                                              className="rounded-circle"
                                              style={{
                                                  width: '50px',
                                                  height: '50px',
                                                  objectFit: 'contain',
                                                  margin: '5px',
                                              }}
                                          />
                                      }
                                  </a>
                              ))}
                          </div>
                      </Card.Body>
                      <Card.Footer className="d-flex justify-content-between">

                          <Button variant="primary" onClick={() => handleMessage(mate._id)}>
                              Message
                          </Button>
                      </Card.Footer>
                  </Card>
                  </CardContainer>
              </Col>
              
                              );
                          })}
                      </Row>
                  </Container>
              </ContentWrapper>
             }
         
         
         
         
                             { activeSubTab === 'myNDStudents' &&
                <ContentWrapper sidebarVisible={sidebarVisible}>
                <Container fluid>
                    
                    <Row>
                        
                        {myDStudents.map((mate) => {
                            const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                            return (
            <Col
                key={mate._id}
                xs={12}
                sm={mates.length === 1 ? 8 : 6}
                md={mates.length === 1 ? 6 : 4}
                lg={mates.length === 1 ? 4 : 3}
                className="mb-4"
                style={mates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
            >
                <CardContainer>
                <Card
                    style={{
                        minHeight: '350px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    {mate.picture && (
                          <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                        <div className="text-center mt-3">
                            <Card.Img
                                variant="top"
                                src={mate.picture || '/images/default-profile.jpg'}
                                alt={`${mate.name || 'Mate'}'s profile`}
                                className="rounded-circle mx-auto"
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    objectFit: 'contain',
                                    border: '2px solid #ccc',
                                }}
                            />
                        </div>
                        </a>
                    )}
                    <Card.Body style={{ flex: 1 }}>
                    <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                        <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {`${mate.name} ${mate.surname}`}
                        </Card.Title>
                        </a>
                        <Card.Text className="text-center">
                            Total Cash: ${mate.totalCash || 0}
                        </Card.Text>
                        <div className="d-flex justify-content-center mb-3">
                            {Array.from({ length: 5 }, (_, i) => (
                                i < averageRate ? (
                                    <FaStar key={i} color="gold" />
                                ) : (
                                    <FaRegStar key={i} color="gold" />
                                )
                            ))}
                        </div>
                        <div className="d-flex justify-content-center mb-3">
                            {mate.sharedWorlds?.map((world) => (
                                 <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                                    {world.thumbnail && 
                                        <img
                                            key={world._id}
                                            src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                            alt={world.name || 'World'}
                                            title={world.name || 'World'}
                                            className="rounded-circle"
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                objectFit: 'contain',
                                                margin: '5px',
                                            }}
                                        />
                                    }
                                </a>
                            ))}
                        </div>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-between">

                        <Button variant="primary" onClick={() => handleMessage(mate._id)}>
                            Message
                        </Button>
                    </Card.Footer>
                </Card>
                </CardContainer>
            </Col>
            
                            );
                        })}
                    </Row>
                </Container>
            </ContentWrapper>}
         
         

                             </>
                         )}       




                </MainContent>
            </ContentContainer>


        </Container>
        </PageContainer>
    );
}

export default MyStudentsAndMyAdvisors;


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;




const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;

;
















const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 160px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;












const SubTab = styled.div`
    flex: 1;
    text-align: center;
    padding: 8px 0; /* Reduce padding for shorter height */
    cursor: pointer;
    font-size: 16px; /* Slightly smaller font size */
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }

`;

 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;





const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const SubTabContainer = styled.div
`  position: fixed; /* Fixed to stay visible while scrolling */
  top: 135px; /* Add spacing to prevent overlap (adjusted from 120px) */
  left: 300px; /* Align with the main content considering sidebar width */
  width: calc(100% - 300px); /* Stretch full width minus sidebar */
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 1px solid #ddd; /* Subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  z-index: 950; /* Stay above content but below navbar */
  padding: 5px 0; /* Reduced padding for a shorter height */
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};


  @media (max-width: 768px) {
    width: 100%;
      top: 139px; /* Add spacing to prevent overlap (adjusted from 120px) */
    left: 0; /* Align to the screen */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

const SearchContainer = styled.div`
    position: relative;

        @media (max-width: 768px) {
        max-width: 200px;
        padding-left: 150px;
        input {
            font-size: 12px;
        }
    }
`;

const SearchBar = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;

        input {
            font-size: 12px;
        }
    }
`;





const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;






const SectionHeader = styled.div`
    font-family: 'Roboto', sans-serif; /* Better font */
    font-size: 16px;
    color: #333; /* Default text color */
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transitions */

    &:hover {
        color: #27ae60; /* Change text color on hover */
        font-size: 17px; /* Slightly increase size on hover */
        background-color: #e9f5ec; /* Add subtle background color on hover */
        border-radius: 5px; /* Rounded corners for hover effect */
    }
`;