import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheckCircle, FaTrash, FaBell, FaComments, FaSearch, FaCog, FaUpload, FaBars, FaChevronLeft, FaChevronRight, FaArrowRight } from 'react-icons/fa';
import { setLevelId, setLevelNumber } from '../../reducers/reducers';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

function AddLevel() {



  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [skillerId, setSkillerId] = useState('')
  const [worldName, setWorldName] = useState('')
  const dropdownRef = useRef(null);
      const [sidebarVisible, setSidebarVisible] = useState(true);
  const [profilePictureSrc, setProfilePictureSrc] = useState('')
  const [showDropdown, setShowDropdown] = useState(false);
  useEffect(() => {
      const getSkillerId = async() => {
       const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
       setSkillerId(response.data)
       console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
      }
      getSkillerId();
    },[])
    useEffect(() => {

  
      const fetchProfilePicture = async () => {
          try {
              const response = await axios.get(
                  'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                  { responseType: 'blob' }
              );
      
              console.log('Fetched blob:', response.data);
      
              if (response.data.size > 0) {
                  // Blob has data, create an object URL
                  const url = URL.createObjectURL(response.data);
                  setProfilePictureSrc(url);
              } else {
                  // Blob is empty, use the Firebase image URL
                  setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
              }
          } catch (error) {
              console.error('Error fetching profile picture:', error);
              // Optionally set a fallback image if there's an error
              setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
          }
      };
      
  
   
      fetchProfilePicture();
  }, []);
  const handleProfileClick = () => {
    setShowDropdown((prev) => !prev);
  };
  const handleLogout = async () => {
    // Clear localStorage
    console.log("Before clearing localStorage:", localStorage);
    localStorage.clear();
    console.log("After clearing localStorage:", localStorage);

    // Clear specific keys if clear() doesn't work
    localStorage.removeItem("authenticated");
    localStorage.removeItem("permission");
    localStorage.removeItem("skillerToken");
    localStorage.removeItem("lastExternalReferrer");
    localStorage.removeItem("lastExternalReferrerTime");

    // Clear sessionStorage
    sessionStorage.clear();

    // Clear all cookies
    const clearAllCookies = () => {
        document.cookie.split(";").forEach((cookie) => {
            const cookieName = cookie.split("=")[0].trim();
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        });
    };
    clearAllCookies();

    console.log("Cookies after clearing:", document.cookie);

    // Delay to ensure clearing is complete
    await new Promise((resolve) => setTimeout(resolve, 100));

    // Redirect to login page
    navigate("/skiller-signIn");
};
const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};

useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);








  const worldId = useSelector((state) => state.allow.worldId)
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedLevel, setSelectedLevel] = useState(null);
const handleGoToLessons = (_id) => {
  dispatch(setLevelId(_id))
  navigate('/add-lesson')
}

const setLevelPublished = async(levelId) => {
  console.log('levelId from setLevelPublished ============-=-=-=-=-=->  > > ', levelId)
  await axios.patch('https://sell-skill.com/api/endpoints/setLevelPublished', {levelId, worldId})
}
const handleConfirmPublish = async () => {
  if (selectedLevelId) {
       setLevelPublished(selectedLevelId);
      setShowModal(false);

  }
};
const [showModal, setShowModal] = useState(false);
const [selectedLevelId, setSelectedLevelId] = useState(null);

    const handleShowModal = (levelId) => {
      setSelectedLevelId(levelId);
      setShowModal(true);
  };
const handleSaveLevelChanges = async (updatedLevel) => {
  try {
    console.log('updated level from handleSaveLevelChanges =================-=-=-=--=-=-=-=-=-=-= > > > > > > >  ',updatedLevel)
    await axios.patch('https://sell-skill.com/api/endpoints/editLevel', {updatedLevel, worldId});

    // Update local state
    setLevels((prevLevels) =>
      prevLevels.map((lvl) => (lvl._id === updatedLevel._id ? updatedLevel : lvl))
    );

    setIsModalOpen(false);
    alert('Level updated successfully!');
  } catch (error) {
    console.error('Error updating level:', error);
    alert('Failed to update level.');
  }
};

  const _id = uuidv4();
  const [levelNumber, setLevelNumberI] = useState('');
  const [levelTitle, setLevelTitle] = useState('');
  const [levelDescription, setLevelDescription] = useState('');
  const [toBeAnAdvisorLevel, setToBeAnAdvisorLevel] = useState(false);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  
  const [completedSteps, setCompletedSteps] = useState({
    levelNumber: false,
    levelTitle: false,
    levelDescription: false,
    advisorLevel: false,
  });



  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLevels = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getLevels', {
          params: { worldId },
        });
        console.log('response.data from fetchLevels ==============================-=-=--=-=-=-=-> > > > > > >>  ',response.data)
        setLevels(response.data);
      } catch (error) {
        console.error('Error fetching levels:', error);
      } finally {
        setLoading(false);
      }
    };

    const getWorldName = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getWorldName', {params: worldId})
      setWorldName(response.data.worldName)
      console.log('response.data from getWorldName =======================================-=-=-----------------------------------=-==-=-=-=--=---> > > >>  >>  > > > > > > >  ',response.data)
    }

    getWorldName();
    fetchLevels();
  }, [worldId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const levelData = {
      _id,
      worldId,
      levelNumber: parseInt(levelNumber),
      levelTitle,
      levelDescription,
      toBeAnAdvisorLevel,
    };

    try {
      await axios.post('https://sell-skill.com/api/endpoints/addLevel', levelData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // Update Redux store
      dispatch(setLevelNumber(levelNumber));
      dispatch(setLevelId(levelData._id));

      // Add new level to state
      setLevels((prevLevels) => [...prevLevels, levelData]);

      // Reset form
      setLevelNumberI('');
      setLevelTitle('');
      setLevelDescription('');
      setToBeAnAdvisorLevel(false);
      setCompletedSteps({
        levelNumber: false,
        levelTitle: false,
        levelDescription: false,
        advisorLevel: false,
      });

      alert('Level added successfully!');
    } catch (error) {
      console.error(error);
      alert('Error adding level.');
    }
  };

  const handleRemoveLevel = async (_id) => {
    try {
      setLevels((prevLevels) => prevLevels.filter((level) => level._id !== _id));
      await axios.delete('https://sell-skill.com/api/endpoints/removeLevel', {params: {_id, worldId}});
      // Remove level from state

    } catch (error) {
      console.error('Error removing level:', error);
    }
  };

  const LevelEditModal = ({ isOpen, level, onClose, onSave }) => {
    const [updatedLevel, setUpdatedLevel] = useState(level);
    if (!isOpen) return null;
  
    
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUpdatedLevel((prev) => ({ ...prev, [name]: value }));
    };
  
    const handleSave = () => {
      onSave(updatedLevel);
    };
  
 
    return (
      <ModalOverlay>
  <ModalContent>    
  

          <FormGroup>
            <label>Level Title:</label>
            <input
              type="text"
              name="levelTitle"
              value={updatedLevel.levelTitle}
              onChange={handleInputChange}
              style={{
                width: '100%',
                padding: '0.5rem',
                borderRadius: '5px',
                border: '1px solid #ccc',
                marginBottom: '1rem',
              }}
            />
          </FormGroup>
          <FormGroup>
            <label style={{ fontWeight: 'bold', color: '#2c3e50', marginBottom: '0.5rem' }}>Level Description:</label>
            <textarea
              name="levelDescription"
              value={updatedLevel.levelDescription}
              onChange={handleInputChange}
              style={{
                width: '100%',
                padding: '0.5rem',
                borderRadius: '5px',
                border: '1px solid #ccc',
                marginBottom: '1rem',
                minHeight: '100px',
              }}
            ></textarea>
          </FormGroup>

          <FormGroup>
            <label>
              <input
                type="checkbox"
                name="toBeAnAdvisorLevel"
                checked={updatedLevel.toBeAnAdvisorLevel}
                onChange={(e) =>
                  setUpdatedLevel((prev) => ({
                    ...prev,
                    toBeAnAdvisorLevel: e.target.checked,
                  }))
                }
              />
              Advisor Level
            </label>
          </FormGroup>
          <Button onClick={handleSave} style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '1rem' }}          onMouseOver={(e) => e.target.style.backgroundColor = '#1a2734'}
        onMouseOut={(e) => e.target.style.backgroundColor = '#2c3e50'}>Save Changes</Button>
          <Button onClick={onClose}         style={{
          backgroundColor: 'transparent',
          color: '#2c3e50',
          padding: '0.5rem 1rem',
          border: '1px solid #2c3e50',
          borderRadius: '5px',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = '#f5f5f5'}
        onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}>
            Cancel
          </Button>
        </ModalContent>
      </ModalOverlay>
    );
  };
  

  return (
    <>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
      <LevelEditModal
  isOpen={isModalOpen}
  level={selectedLevel}
  onClose={() => setIsModalOpen(false)}
  onSave={handleSaveLevelChanges}
/>

      { sidebarVisible &&  <Sidebar>
          <h3>Steps</h3>
          {[{ name: 'Level Title', key: 'levelTitle' },
            { name: 'Level Description', key: 'levelDescription' },
            { name: 'Advisor Level', key: 'advisorLevel' }].map((step) => (
            <SidebarItem key={step.key}>
              <span>{step.name}</span>
              {completedSteps[step.key] && <CompletedMark />}
            </SidebarItem>
          ))}
        </Sidebar>}

               {
                  !isMenuOpen &&  !isModalOpen && !showModal &&    <ToggleSidebarButton onClick={toggleSidebar}>
                   {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
               </ToggleSidebarButton>
          }
        <ContentWrapper>  
        <MainContent>
        {  
          worldName &&
          <h2 style={{ 
            fontSize: '1.5rem', 
            fontWeight: 'bold', 
            color: '#2c3e50', 
            textAlign: 'center', 
            marginBottom: '1rem' 
          }}>Levels of {worldName} world</h2>}
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <label>level Title:</label>
              <input
                type="text"
                value={levelTitle}
                onChange={(e) => {
                  setLevelTitle(e.target.value);
                  setCompletedSteps((prev) => ({
                    ...prev,
                    levelTitle: e.target.value.trim() !== '',
                  }));
                }}
                required  
              />
            </FormGroup>
            <FormGroup>
              <label>level Description:</label>
              <textarea
                value={levelDescription}
                onChange={(e) => {
                  setLevelDescription(e.target.value);
                  setCompletedSteps((prev) => ({
                    ...prev,
                    levelDescription: e.target.value.trim() !== '',
                  }));
                }}
                required
              ></textarea>
            </FormGroup>
            <Button
              type="button"
              onClick={() => {
                setToBeAnAdvisorLevel(!toBeAnAdvisorLevel);
                setCompletedSteps((prev) => ({
                  ...prev,
                  advisorLevel: !toBeAnAdvisorLevel,
                }));
              }}
              selected={toBeAnAdvisorLevel}
            >
              {toBeAnAdvisorLevel ? 'Advisor Level Set' : 'Set to be Advisor Level'}
            </Button>
            <SubmitButton type="submit">Add Level</SubmitButton>
          </form>

          <LevelsList>
            {loading ? (
              <p>Loading levels...</p>
            ) : levels.length ? (
              levels.map((level) => (
                <>
                <Button
  style={{
    backgroundColor: "#27ae60", // Green color
    borderColor: "#27ae60",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem", // Add space between text and icon
    padding: "0.5rem 1rem",
  }}
  onClick={() => handleGoToLessons(level._id)}
>
  Go to Lessons <FaArrowRight />
</Button>


<LevelCard key={level._id}>
                  <LevelDetails>
                  {!Boolean(level.published) && (
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button
                                style={{
                                    backgroundColor: '#27ae60',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '20px',
                                    padding: '5px 15px',
                                    fontSize: '14px',
                                }}
                                onClick={() => handleShowModal(level._id)}
                            >
                                <FaUpload style={{ marginRight: '5px' }} /> Publish
                            </Button>
                        </div>
                    )}
                    <h3>{level.levelTitle}</h3>
                    <p>{level.levelDescription}</p>
                  </LevelDetails>

                  <ActionIcons>
                  <RemoveButton onClick={() => handleRemoveLevel(level._id)}>
                    <FaTrash />
                  </RemoveButton>
                  <FaCog
  onClick={() => {
    setSelectedLevel(level);
    setIsModalOpen(true);
  }}
/>
                  </ActionIcons>


                </LevelCard>
   
                </>


              ))
            ) : (
              <p>No levels added yet.</p>
            )}
          </LevelsList>


          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
    <Modal.Header closeButton>
        <Modal.Title>Confirm Publish</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to publish this world?</Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirmPublish}>
            Yes, Publish
        </Button>
    </Modal.Footer>
</Modal>

          
        </MainContent>
        </ContentWrapper>

    </>
  );
}

export default AddLevel;

// Styled Components


const FormGroup = styled.div`
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
`;

const Button = styled.button`
  background-color: ${(props) => (props.selected ? '#27ae60' : '#7f8c8d')};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #1e8449;
  }
`;

const LevelsList = styled.div`
  margin-top: 20px;
`;































const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
`;


const ContentContainer = styled.div`
    display: flex;
    position: relative; /* Allows absolute positioning of the toggle button */
    width: 100%;
    min-height: calc(100vh - 60px); /* Adjust to ensure it spans the full viewport height minus the navbar */
`;


const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;


const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
    }
`;



const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;















const LevelCard = styled.div`
  position: relative; /* Ensure the Publish button is positioned relative to the card */
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LevelDetails = styled.div`
  margin-bottom: 20px;
`;





const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Adds spacing between the remove and settings icons */
  justify-content: flex-end; /* Aligns the icons to the right */
`;














































const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ElementContainer = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border: 1px dashed #ddd;
  border-radius: 5px;
  position: relative;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;





const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 1.1em;
  cursor: pointer; /* Add pointer effect for better UX */
  padding: 10px;
  border-radius: 5px;
  background-color: transparent; /* No initial color */
  color: inherit; /* Maintain default color */

  ${({ activeTab }) =>
    activeTab === 'trailer' &&
    `
    &:hover {
      font-size: 1.2em; /* Slightly larger size */
      background-color: #27ae60; /* Green background */
      color: white; /* White text for better contrast */
      transition: all 0.3s ease; /* Smooth transition */
    }
  `}
`;


const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  margin-top: 20px; /* Add space above the button */
`;

const SaveButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #219150;
  }
`;



const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;



const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;


const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;

const MainContent = styled.div`
  margin-left: 205px; /* Align content to start after the sidebar */
  @media (max-width: 768px) {
    margin-left: 0; /* No margin for small screens */
  }
`;


const ContentWrapper = styled.div`
padding-top: 190px;
  padding-left: 205px; /* Adjust to accommodate the sidebar width */
    @media (max-width: 768px) {
    padding-top: 90px;
        padding-left: 30px;
    }

`;






const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;




const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 60px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;



const Sidebar = styled.div`
    width: 300px;
    position: fixed;
    top: 50px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 150px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 140px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 147px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;











 const CompletedMark = styled(FaCheckCircle)`
   margin-left: 10px;
   font-size: 1.2em;
   color: #27ae60; /* Green for the check mark */
 `;
 const FileInputContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 5px;

   input {
     display: none;
   }

   label {
     background-color: #27ae60;
     color: white;
     padding: 10px 20px;
     border-radius: 5px;
     cursor: pointer;
     transition: background-color 0.3s ease;

     &:hover {
       background-color: #1e8449;
     }
   }
 `;

 const CategoryButton = styled.button`
   margin: 5px;
   padding: 10px 15px;
   background-color: ${(props) => (props.selected ? "#27ae60" : "#ecf0f1")};
   color: ${(props) => (props.selected ? "white" : "#2c3e50")};
   border: 1px solid ${(props) => (props.selected ? "#27ae60" : "#bdc3c7")};
   border-radius: 5px;
   cursor: pointer;
   transition: all 0.3s ease;

   &:hover {
     background-color: ${(props) => (props.selected ? "#1e8449" : "#d5d8dc")};
     color: ${(props) => (props.selected ? "white" : "#2c3e50")};
   }
 `;

 const FormElement = styled.div`
 margin-bottom: 20px;

 label {
   display: block;
   margin-bottom: 5px;
   font-weight: bold;
 }

 input,
 textarea,
 button {
   width: 100%;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   margin-top: 5px;

   @media (max-width: 768px) {
     width: 90%; /* Reduce the width for smaller screens */
   }

   @media (max-width: 480px) {
     width: 80%; /* Further reduce width for very small screens */
   }
 }

 button[type="button"] {
   width: auto;
   background-color: #27ae60;
   color: white;
   border: none;
   cursor: pointer;

   &:hover {
     background-color: #1e8449;
   }
 }
`;


// import React, { useState } from 'react';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { setLevelId, setLevelNumber } from '../../reducers/reducers';

// function AddLevel() {
//   const [levelNumber, setLevelNumberI] = useState('');
//   const [levelTitle, setLevelTitle] = useState('');
//   const [levelDescription, setLevelDescription] = useState('');
//   const [toBeAnAdvisorLevel, setToBeAnAdvisorLevel] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const levelId = uuidv4();
//   const worldName = useSelector((state) => state.allow.worldName);
//   const worldId = useSelector((state) => state.allow.worldId);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const levelData = {
//       levelId, // Generate a unique ID for the level
//       worldId, // World name to identify the specific world in the backend
//       levelNumber: parseInt(levelNumber), // Convert to number if needed
//       levelTitle,
//       levelDescription,
//       toBeAnAdvisorLevel,
//     };

//     try {
//       await axios.post('https://sell-skill.com/api/endpoints/addLevel', levelData, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       dispatch(setLevelNumber(levelNumber));
//       dispatch(setLevelId(levelId));
//       navigate('/add-lesson');
//     } catch (error) {
//       console.error(error);
//       alert('Error adding level.');
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Level Number:</label>
//         <input
//           type="number"
//           value={levelNumber}
//           onChange={(e) => setLevelNumberI(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Level Title:</label>
//         <input
//           type="text"
//           value={levelTitle}
//           onChange={(e) => setLevelTitle(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Level Description:</label>
//         <textarea
//           value={levelDescription}
//           onChange={(e) => setLevelDescription(e.target.value)}
//           required
//         ></textarea>
//       </div>
//       <button type="submit">Add Level</button>
//       <button
//         type="button"
//         onClick={() => setToBeAnAdvisorLevel(!toBeAnAdvisorLevel)}
//         style={{
//           backgroundColor: toBeAnAdvisorLevel ? 'green' : 'gray',
//           color: 'white',
//           padding: '10px 20px',
//           border: 'none',
//           borderRadius: '5px',
//           cursor: 'pointer',
//         }}
//       >
//         {toBeAnAdvisorLevel ? 'Advisor Level Set' : 'Set to be Advisor Level'}
//       </button>
//     </form>
//   );
// }

// export default AddLevel;


// // AddLevel.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { setLevelId, setLevelNumber } from '../../reducers/reducers';

// function AddLevel() {
//   const [levelNumber, setLevelNumberI] = useState('');
//   const [levelTitle, setLevelTitle] = useState('');
//   const [levelDescription, setLevelDescription] = useState('');
//   const [toBeAnAdvisorLevel, setToBeAnAdvisorLevel] = useState(false)
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const levelId = uuidv4();
//   const worldName = useSelector((state) => state.allow.worldName);
//   const worldId = useSelector((state) => state.allow.worldId)

  
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const levelData = {
//       levelId: levelId, // Generate a unique ID for the level
//       worldId, // World name to identify the specific world in the backend
//       levelNumber: parseInt(levelNumber), // Convert to number if needed
//       levelTitle,
//       levelDescription,
//       toBeAnAdvisorLevel
//     };


//     try {
//       await axios.post('https://sell-skill.com/api/endpoints/addLevel', levelData, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       dispatch (setLevelNumber(levelNumber))
//       dispatch(setLevelId(levelId))
//       navigate ('/add-lesson')
//     } catch (error) {
//       console.error(error);
//       alert('Error adding level.');
//     }
//   };
 

//   return (
//     <form onSubmit={handleSubmit}>

//       <div>
//         <label>Level Number:</label>
//         <input
//           type="number"
//           value={levelNumber}
//           onChange={(e) => setLevelNumberI(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>level title:</label>
//         <input
//           type="text"
//           value={levelTitle}
//           onChange={(e) => setLevelTitle(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Level Description:</label>
//         <textarea
//           value={levelDescription}
//           onChange={(e) => setLevelDescription(e.target.value)}
//           required
//         ></textarea>
//       </div>
//       <button type="submit">Add Level</button>
//       <button onClick={setToBeAnAdvisorLevel(true)}>set to be advisor level</button>
//     </form>
//   );
// }

// export default AddLevel;
