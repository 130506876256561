import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  max-width: 1500px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const MessageList = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 16px;

  & > div {
    margin: 10px 0;
    padding: 8px;
    border-radius: 6px;
    word-wrap: break-word;
  }

  .response {
    background-color: #eafaf1;
    color: #27ae60;
  }

  .user-message {
    background-color: #ffffff;
    color: #2c3e50;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  resize: none;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const FileInput = styled.input`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #219150;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;



const FileInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const CustomFileButton = styled.button`
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;

  &:hover {
    background-color: #219150;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const FileNameDisplay = styled.span`
  font-size: 14px;
  color: #2c3e50;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;


const Chats2 = () => {
  const [messageContent, setMessageContent] = useState("");
  const [file, setFile] = useState(null); // To handle file uploads
  const [messages, setMessages] = useState([]);
  const withId = useSelector((state) => state.allow.withId);

  useEffect(() => {
    const getMessages = async () => {
      const response = await axios.get(
        "https://sell-skill.com/api/endpoints/chats2",
        { params: { withId } }
      );
      setMessages(response.data);
    };

    getMessages();
    const interval = setInterval(getMessages, 5000);
    return () => clearInterval(interval);
  }, [withId]);

  const handleSendMessage = async () => {
    const messageId = uuidv4();

    try {
      let fileUrl = null;

      if (file) {
        const storage = getStorage();
        const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
        await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(fileRef);
      }

      const response = await axios.post(
        "https://sell-skill.com/api/endpoints/sendMessage",
        {
          _id: messageId,
          withId,
          content: messageContent,
          document: fileUrl,
        }
      );

      if (response.data.success) {
        setMessages([
          ...messages,
          {
            _id: messageId,
            content: messageContent,
            response: true,
            document: fileUrl,
          },
        ]);
        setMessageContent("");
        setFile(null);
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const renderFilePreview = (fileUrl) => {
    const fileExtension = fileUrl.split(".").pop().toLowerCase();

    if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <img src={fileUrl} alt="attachment" style={{ maxWidth: "100%" }} />;
    } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
      return <video src={fileUrl} controls style={{ maxWidth: "100%" }} />;
    } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
      return <audio src={fileUrl} controls />;
    } else if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={fileUrl}
          title="PDF Preview"
          style={{ width: "100%", height: "500px" }}
        />
      );
    } else {
      return (
        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          Download File
        </a>
      );
    }
  };

  return (
    <Container>
      <MessageList>
        {messages.length > 0 ? (
          messages.map((message) => (
            <div
              key={message._id}
              className={message.response ? "response" : "user-message"}
            >
              <div>{message.content}</div>
              {message.document && renderFilePreview(message.document)}
            </div>
          ))
        ) : (
          <p>No messages received.</p>
        )}
      </MessageList>
      <TextArea
        placeholder="Type your message here..."
        value={messageContent}
        onChange={(e) => setMessageContent(e.target.value)}
      />
<FileInputContainer>
  <HiddenFileInput
    type="file"
    id="fileInput"
    onChange={(e) => setFile(e.target.files[0])}
  />
  <CustomFileButton onClick={() => document.getElementById("fileInput").click()}>
    Choose File
  </CustomFileButton>
  {file && <FileNameDisplay>{file.name}</FileNameDisplay>}
</FileInputContainer>
      <Button onClick={handleSendMessage}>Send Message</Button>
    </Container>
  );
};

export default Chats2;



























// const Chats2 = () => {
  //   const [messageContent, setMessageContent] = useState("");
  //   const [file, setFile] = useState(null); // To handle file uploads
  //   const [messages, setMessages] = useState([]);
  //   const withId = useSelector((state) => state.allow.withId);
  
  //   // Fetch messages on component mount
  //   useEffect(() => {
  //     const getMessages = async () => {
  //       const response = await axios.get(
  //         "https://sell-skill.com/api/endpoints/chats2",
  //         { params: { withId } }
  //       );
  //       setMessages(response.data);
  //     };
  
  //     getMessages();
  
  //     // Optionally, use polling or WebSocket for real-time updates
  //     const interval = setInterval(getMessages, 5000); // Fetch messages every 5 seconds
  //     return () => clearInterval(interval);
  //   }, [withId]);
  
  //   const handleSendMessage = async () => {
  //     const messageId = uuidv4();
  
  //     try {
  //       let fileUrl = null;
  
  //       // Upload file to Firebase if a file is selected
  //       if (file) {
  //         const storage = getStorage();
  //         const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
  //         await uploadBytes(fileRef, file);
  //         fileUrl = await getDownloadURL(fileRef);
  //       }
  
  //       // Send the message to the backend
  //       const response = await axios.post(
  //         "https://sell-skill.com/api/endpoints/sendMessage",
  //         {
  //           _id: messageId,
  //           withId,
  //           content: messageContent,
  //           document: fileUrl, // Send the file URL if uploaded
  //         }
  //       );
  
  //       if (response.data.success) {
  //         // Optimistically update the UI
  //         setMessages([
  //           ...messages,
  //           {
  //             _id: messageId,
  //             content: messageContent,
  //             response: true,
  //             document: fileUrl,
  //           },
  //         ]);
  //         setMessageContent("");
  //         setFile(null);
  //       } else {
  //         alert("Failed to send message.");
  //       }
  //     } catch (error) {
  //       console.error("Error sending message:", error);
  //       alert("An error occurred. Please try again.");
  //     }
  //   };
  
  //   const renderFilePreview = (fileUrl) => {
  //     const fileExtension = fileUrl.split(".").pop().toLowerCase();
  
  //     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
  //       return <img src={fileUrl} alt="attachment" style={{ maxWidth: "100%" }} />;
  //     } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
  //       return <video src={fileUrl} controls style={{ maxWidth: "100%" }} />;
  //     } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
  //       return <audio src={fileUrl} controls />;
  //     } else if (["pdf"].includes(fileExtension)) {
  //       return (
  //         <iframe
  //           src={fileUrl}
  //           title="PDF Preview"
  //           style={{ width: "100%", height: "500px" }}
  //         />
  //       );
  //     } else {
  //       return (
  //         <a href={fileUrl} target="_blank" rel="noopener noreferrer">
  //           Download File
  //         </a>
  //       );
  //     }
  //   };


// import React, { useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
// import { useSelector } from "react-redux";

// const Chats2 = () => {
//   const [messageContent, setMessageContent] = useState("");
//   const [file, setFile] = useState(null); // To handle file uploads
//   const [messages, setMessages] = useState([]);
//   const withId = useSelector((state) => state.allow.withId);

//   // Fetch messages on component mount
//   useEffect(() => {
//     const getMessages = async () => {
//       const response = await axios.get(
//         "https://sell-skill.com/api/endpoints/chats2",
//         { params: { withId } }
//       );
//       setMessages(response.data);
//     };

//     getMessages();

//     // Optionally, use polling or WebSocket for real-time updates
//     const interval = setInterval(getMessages, 5000); // Fetch messages every 5 seconds
//     return () => clearInterval(interval);
//   }, [withId]);

//   const handleSendMessage = async () => {
//     const messageId = uuidv4();

//     try {
//       let fileUrl = null;

//       // Upload file to Firebase if a file is selected
//       if (file) {
//         const storage = getStorage();
//         const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
//         await uploadBytes(fileRef, file);
//         fileUrl = await getDownloadURL(fileRef);
//       }

//       // Send the message to the backend
//       const response = await axios.post(
//         "https://sell-skill.com/api/endpoints/sendMessage",
//         {
//           _id: messageId,
//           withId,
//           content: messageContent,
//           document: fileUrl, // Send the file URL if uploaded
//         }
//       );

//       if (response.data.success) {
//         // Optimistically update the UI
//         setMessages([
//           ...messages,
//           {
//             _id: messageId,
//             content: messageContent,
//             response: true,
//             document: fileUrl,
//           },
//         ]);
//         setMessageContent("");
//         setFile(null);
//       } else {
//         alert("Failed to send message.");
//       }
//     } catch (error) {
//       console.error("Error sending message:", error);
//       alert("An error occurred. Please try again.");
//     }
//   };

//   const renderFilePreview = (fileUrl) => {
//     const fileExtension = fileUrl.split(".").pop().toLowerCase();

//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <img src={fileUrl} alt="attachment" style={{ maxWidth: "100%" }} />;
//     } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
//       return <video src={fileUrl} controls style={{ maxWidth: "100%" }} />;
//     } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
//       return <audio src={fileUrl} controls />;
//     } else if (["pdf"].includes(fileExtension)) {
//       return (
//         <iframe
//           src={fileUrl}
//           title="PDF Preview"
//           style={{ width: "100%", height: "500px" }}
//         />
//       );
//     } else {
//       return (
//         <a href={fileUrl} target="_blank" rel="noopener noreferrer">
//           Download File
//         </a>
//       );
//     }
//   };

//   return (
//     <div>
 
//       {messages.length > 0 ? (
//         messages.map((message) => (
//           <div key={message._id} style={{ margin: "10px 0" }}>
//             {message.response ? (
//               <div style={{ color: "blue" }}>{message.content}</div>
//             ) : (
//               <div style={{ color: "black" }}>{message.content}</div>
//             )}
//             {message.document && renderFilePreview(message.document)}
//           </div>
//         ))
//       ) : (
//         <p>No messages received.</p>
//       )}
//       <textarea
//         placeholder="Type your message here..."
//         value={messageContent}
//         onChange={(e) => setMessageContent(e.target.value)}
//         style={{ width: "100%", marginBottom: "10px" }}
//       />
//       <input
//         type="file"
//         onChange={(e) => setFile(e.target.files[0])}
//         style={{ marginBottom: "10px" }}
//       />
//       <button onClick={handleSendMessage}>Send Message</button>
//     </div>
//   );
// };

// export default Chats2;




















// import React, { useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
// import { useSelector } from "react-redux";

// const Chats2 = () => {
//   const [messageContent, setMessageContent] = useState("");
//   const [file, setFile] = useState(null); // To handle file uploads
//   const [messages, setMessages] = useState([]);
//   const withId = useSelector((state) => state.allow.withId);

//   // Fetch messages on component mount
//   useEffect(() => {
//     const getMessages = async () => {
//       const response = await axios.get(
//         "https://sell-skill.com/api/endpoints/chats2",
//         { params: { withId } }
//       );
//       setMessages(response.data);
//     };

//     getMessages();

//     // Optionally, use polling or WebSocket for real-time updates
//     const interval = setInterval(getMessages, 5000); // Fetch messages every 5 seconds
//     return () => clearInterval(interval);
//   }, [withId]);

//   const handleSendMessage = async () => {
//     const messageId = uuidv4();

//     try {
//       let fileUrl = null;

//       // Upload file to Firebase if a file is selected
//       if (file) {
//         const storage = getStorage();
//         const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
//         await uploadBytes(fileRef, file);
//         fileUrl = await getDownloadURL(fileRef);
//       }

//       // Send the message to the backend
//       const response = await axios.post(
//         "https://sell-skill.com/api/endpoints/sendMessage",
//         {
//           _id: messageId,
//           withId,
//           content: messageContent,
//           document: fileUrl, // Send the file URL if uploaded
//         }
//       );

//       if (response.data.success) {
//         // Optimistically update the UI
//         setMessages([
//           ...messages,
//           {
//             _id: messageId,
//             content: messageContent,
//             response: true,
//             document: fileUrl,
//           },
//         ]);
//         setMessageContent("");
//         setFile(null);
//       } else {
//         alert("Failed to send message.");
//       }
//     } catch (error) {
//       console.error("Error sending message:", error);
//       alert("An error occurred. Please try again.");
//     }
//   };

//   return (
//     <div>
//       <h2>Send Message</h2>
//       {messages.length > 0 ? (
//         messages.map((message) => (
//           <div key={message._id} style={{ margin: "10px 0" }}>
//             {message.response ? (
//               <div style={{ color: "blue" }}>{message.content}</div>
//             ) : (
//               <div style={{ color: "black" }}>{message.content}</div>
//             )}
//             {message.document && (
//               <a href={message.document} target="_blank" rel="noopener noreferrer">
//                 View Attachment
//               </a>
//             )}
//           </div>
//         ))
//       ) : (
//         <p>No messages received.</p>
//       )}
//       <textarea
//         placeholder="Type your message here..."
//         value={messageContent}
//         onChange={(e) => setMessageContent(e.target.value)}
//         style={{ width: "100%", marginBottom: "10px" }}
//       />
//       <input
//         type="file"
//         onChange={(e) => setFile(e.target.files[0])}
//         style={{ marginBottom: "10px" }}
//       />
//       <button onClick={handleSendMessage}>Send Message</button>
//     </div>
//   );
// };

// export default Chats2;


























// import React, { useEffect, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { Button } from 'react-bootstrap';

// const Chats2 = () => {
//   const [receiverId, setReceiverId] = useState('');
//   const [messageContent, setMessageContent] = useState('');
//   const [messages, setMessages] = useState([])
//   const withId = useSelector((state) => state.allow.withId)
//   const handleNameClick = () => {

//   }

//   useEffect(() => {
//     const getMessages = async () => 
//     { 
//       console.log('withId from chats2 after useSelector ======================------------------------------>>>>> ', withId)
//       const response = await axios.get('https://sell-skill.com/api/endpoints/chats2', {params: {withId}})
//       setMessages(response.data)//map through messages, response true in a certain color and false in another
//       console.log('response.data from getMessages in chats2 ====================---------------------->>>>>> ',response.data)
//     }
// getMessages();
//   }, [])
//   const handleSendMessage = async () => {
//     const messageId = uuidv4(); // Generate unique ID for the message



//     try {
//       const response = await axios.post('https://sell-skill.com/api/endpoints/sendMessage', {
//         _id: messageId,
//         withId,
//         content: messageContent,
//       });

//       if (response.data.success) {
//         alert('Message sent successfully!');
//         setMessageContent('');
//       } else {
//         alert('Failed to send message');
//       }
//     } catch (error) {
//       console.error('Error sending message:', error);
//       alert('An error occurred. Please try again.');
//     }


//   };


//   const handleGoInCall = () => {

//   }
//   return (
//     <div>
//       <h2>Send Message</h2>
//       {messages.length > 0 ? (
//                 messages.map((message) => (
//                   <div key={message._id}>
//                     {message.content && (
//                       <>
//                       {     
//                       message.response ?                  
//                      ( <div style={{color: 'black'}}>
//                         {message.content}
//                       </div>) :
//                      (
//                       <>
//                       {message.content}
//                       </>
//                      )
                      
//                     }
//                       </>

//                     )}
//                   </div>
//                 ))
//               ) : (
//                 <p>No messages received.</p>
//               )}
//       <textarea
//         placeholder="Type your message here..."
//         value={messageContent}
//         onChange={(e) => setMessageContent(e.target.value)}
//       />
//       <button onClick={handleSendMessage}>Send Message</button>
//       <button onClick={handleGoInCall}>go in a call</button>
//     </div>
//   );
// };

// export default Chats2;
