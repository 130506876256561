import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

function Resources() {
  const [resources, setResources] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCompleted, setIsCompleted] = useState(false);
  const worldId = useSelector((state) => state.allow.worldId);
  const levelId = useSelector((state) => state.allow.levelId);
  const lessonId = useSelector((state) => state.allow.lessonId);

  const handleSetLessonCompleted = async () => {
    setIsCompleted(true);
    await axios.patch('https://sell-skill.com/api/endpoints/setLessonCompleted', {
      worldId,
      levelId,
      lessonId,
    });
  };

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getResources`, {
          params: { worldId, levelId, lessonId },
        });
        setResources(response.data);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };

    const getLessonCompleted = async () => {
      const response = await axios.patch('https://sell-skill.com/api/endpoints/getLessonCompleted', {
        worldId,
        levelId,
        lessonId,
      });
      setIsCompleted(response.data);
    };
    getLessonCompleted();
    fetchResources();
  }, [worldId, levelId, lessonId]);

  const renderResource = (resource, index) => {
    return (
      <div
        key={index}
        className="resource-row"
        style={{
          width: '100%',
          marginBottom: '20px',
          padding: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          backgroundColor: '#f9f9f9',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        {(() => {
          switch (resource.type) {
            case 'video':
              return (
                <>
                  <h3>Video</h3>
                  <video controls src={resource.url} style={{ width: '100%' }} />
                </>
              );
            case 'image':
              return (
                <>
                  <h3>Image</h3>
                  <img src={resource.url} alt="Resource" style={{ width: '100%' }} />
                </>
              );
            case 'pdf':
              return (
                <>
                  <h3>PDF</h3>
                  <a href={resource.url} target="_blank" rel="noopener noreferrer">
                    View PDF
                  </a>
                </>
              );
            case 'text':
              return (
                <>
                  <h3>Text</h3>
                  <p>{resource.content}</p>
                </>
              );
            case 'quiz':
              return (
                <>
                  <h3>Quiz</h3>
                  <div>
                    <p>{resource.content.question}</p>
                    <ul>
                      {resource.content.choices.map((choice, i) => (
                        <li key={i}>{choice}</li>
                      ))}
                    </ul>
                  </div>
                </>
              );
            default:
              return null;
          }
        })()}
      </div>
    );
  };

  return (
    <div style={{ width: '100%', minHeight: '100vh', padding: '20px', boxSizing: 'border-box' }}>
      <h1 style={{ textAlign: 'center', fontSize: '1.5rem', marginBottom: '20px' }}>Lesson Resources</h1>
      <div style={{ width: '100%' }}>
        {resources.map((resource, index) => renderResource(resource, index))}
      </div>
      <Button
        onClick={handleSetLessonCompleted}
        style={{
          display: 'block',
          margin: '20px auto',
          padding: '10px 20px',
          fontSize: '1rem',
          backgroundColor: isCompleted ? '#218838' : '#28a745',
          border: 'none',
          borderRadius: '5px',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: isCompleted ? 'default' : 'pointer',
          transition: 'all 0.3s ease',
          boxShadow: isCompleted ? '0 0 10px #218838' : 'none',
        }}
        disabled={isCompleted}
      >
        {isCompleted ? (
          <>
            <FaCheckCircle style={{ marginRight: '8px' }} />
            Completed
          </>
        ) : (
          'Complete'
        )}
      </Button>
    </div>
  );
}

export default Resources;





















// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
// import { FaCheckCircle } from 'react-icons/fa';

// function Resources() {
//   const [resources, setResources] = useState([]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [isCompleted, setIsCompleted] = useState(false);
//   const worldId = useSelector((state) => state.allow.worldId);
//   const levelId = useSelector((state) => state.allow.levelId);
//   const lessonId = useSelector((state) => state.allow.lessonId);

//   const handleSetLessonCompleted = async () => {
//     setIsCompleted(true)
//     await axios.patch('https://sell-skill.com/api/endpoints/setLessonCompleted', {
//       worldId,
//       levelId,
//       lessonId,
//     });
//   };



//   useEffect(() => {
//     const fetchResources = async () => {
//       try {
//         const response = await axios.get(`https://sell-skill.com/api/endpoints/getResources`, {
//           params: { worldId, levelId, lessonId },
//         });
//         setResources(response.data);
//       } catch (error) {
//         console.error('Error fetching resources:', error);
//       }
//     };

//     const getLessonCompleted = async () => {

//       const response = await axios.patch('https://sell-skill.com/api/endpoints/getLessonCompleted', {
//         worldId,
//         levelId,
//         lessonId,
//       });
//       setIsCompleted(response.data)
//     };
//     getLessonCompleted();
//     fetchResources();
//   }, [worldId, levelId, lessonId]);

//   const renderResource = (resource, index) => {
//     switch (resource.type) {
//       case 'video':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Video</h3>
//             <video controls src={resource.url} style={{ width: '100%' }} />
//           </div>
//         );
//       case 'image':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Image</h3>
//             <img src={resource.url} alt="Resource" style={{ width: '100%' }} />
//           </div>
//         );
//       case 'pdf':
//         return (
//           <div key={index} className="resource-card">
//             <h3>PDF</h3>
//             <a href={resource.url} target="_blank" rel="noopener noreferrer">
//               View PDF
//             </a>
//           </div>
//         );
//       case 'text':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Text</h3>
//             <p>{resource.content}</p>
//           </div>
//         );
//       case 'quiz':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Quiz</h3>
//             <div>
//               <p>{resource.content.question}</p>
//               <ul>
//                 {resource.content.choices.map((choice, i) => (
//                   <li key={i}>{choice}</li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div style={{ width: '100%', minHeight: '100vh', padding: '20px', boxSizing: 'border-box' }}>
//       <h1 style={{ textAlign: 'center', fontSize: '1.5rem', marginBottom: '20px' }}>Lesson Resources</h1>
//       <div
//         style={{
//           display: 'flex',
//           flexWrap: 'wrap',
//           gap: '16px',
//           justifyContent: 'center',
//         }}
//       >
//         {resources.map((resource, index) => renderResource(resource, index))}
//       </div>
//       <Button
//       onClick={handleSetLessonCompleted}
//       style={{
//         display: 'block',
//         margin: '20px auto',
//         padding: '10px 20px',
//         fontSize: '1rem',
//         backgroundColor: isCompleted ? '#218838' : '#28a745',
//         border: 'none',
//         borderRadius: '5px',
//         color: '#fff',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         cursor: isCompleted ? 'default' : 'pointer',
//         transition: 'all 0.3s ease',
//         boxShadow: isCompleted ? '0 0 10px #218838' : 'none',
//       }}
//       disabled={isCompleted}
//     >
//       {isCompleted ? (
//         <>
//           <FaCheckCircle style={{ marginRight: '8px' }} />
//           Completed
//         </>
//       ) : (
//         'Complete'
//       )}
//     </Button>
//     </div>
//   );
// }

// export default Resources;




























































// //To show resources inside a lesson




// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { Button } from 'react-bootstrap';

// function Resources() {
//   const [resources, setResources] = useState([]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const worldName = useSelector((state) => state.allow.worldName);
//   const levelNumber = useSelector((state) => state.allow.levelNumber);
//   const lessonNumber = useSelector((state) => state.allow.lessonNumber);
//   const worldId = useSelector((state) => state.allow.worldId);
//   const levelId = useSelector((state) => state.allow.levelId);
//   const lessonId = useSelector((state) => state.allow.lessonId)
//   const handleSetLessonCompleted = async() => {
//     await axios.patch('https://sell-skill.com/api/endpoints/setLessonCompleted', {
//       worldId, levelId, lessonId
//     })
//   }
//   console.log('worldId ==========>> ',worldId)
//   console.log('levelId =======>> ',levelId)
//   console.log('lessonId =======>> ',lessonId)
//   useEffect(() => {

//       const fetchResources = async () => {
//         try {
//           const response = await axios.get(`https://sell-skill.com/api/endpoints/getResources`, {
//             params: { worldId, levelId, lessonId }
//           });
//           setResources(response.data);

//           console.log('response.data from get resources =======--------->> ----------->> -------->>>> ',response.data)
//         } catch (error) {
//           console.error("Error fetching resources:", error);
//         }
//       };
  
//       fetchResources();
    
//   }, [worldId, levelId, lessonId]);
  
//   const renderResource = (resource, index) => {
//     switch (resource.type) {
//       case 'video':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Video</h3>
//             <video controls src={resource.url} width="100%" />
//           </div>
//         );
//       case 'image':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Image</h3>
//             <img src={resource.url} alt="Resource" style={{ width: '100%' }} />
//           </div>
//         );
//       case 'pdf':
//         return (
//           <div key={index} className="resource-card">
//             <h3>PDF</h3>
//             <a href={resource.url} target="_blank" rel="noopener noreferrer">
//               View PDF
//             </a>
//           </div>
//         );
//       case 'text':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Text</h3>
//             <p>{resource.content}</p>
//           </div>
//         );
//       case 'quiz':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Quiz</h3>
//             <div>
//               <p>{resource.content.question}</p>
//               <ul>
//                 {resource.content.choices.map((choice, i) => (
//                   <li key={i}>{choice}</li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>
//       <h1>Lesson Resources</h1>
//       <div className="resources-container">
//         {resources.map((resource, index) => renderResource(resource, index))}
//       </div>
//       <Button onClick={handleSetLessonCompleted}>completed</Button>
//     </div>
//   );
// }

// export default Resources;
