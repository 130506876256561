import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({  children }) => {

    const tokenString = localStorage.getItem('skillerToken');
    console.log('tokenString from app.js =================--------------------------------==>> >  > ',tokenString)
    const tokenObject = JSON.parse(tokenString);
    const token = tokenObject?.token;
    const permission = localStorage.getItem('permission')
  
  

  const location = useLocation();
  useEffect(() => {
    const fetchPermission = async () => {
      const response = await axios.get('https://sellskill-9960e1200ba0.herokuapp.com/api/endpoints/verifyClient', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      localStorage.setItem('permission', response.data.permission)
      console.log('response.data.permission from app.js =========================------------------------====-=-=-=-=-=-> > > > > > >   ',response.data.permission)
    };

    fetchPermission();
  }, [token]);



  

  if (!permission || !token) {
    return <Navigate to="/skiller-signIn" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;