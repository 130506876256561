////////////////very very very gooooooooooooooooooooooooooooooooooooood
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setExamId, setLevelId, setLevelNumber, setWithId } from '../../../reducers/reducers';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FaStar, FaLock, FaBell, FaComments, FaGraduationCap, FaCheck, FaChevronDown, FaChevronUp, FaChevronLeft, FaChevronRight, FaRegStar, FaUpload, FaBars, FaSearch, FaFacebookMessenger, FaChalkboardTeacher, FaSave, FaPaperclip, FaComment, FaThumbsUp } from 'react-icons/fa';
import { Button, Card, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';

function Levels() {
      
  const [isOn, setIsOn] = useState(false);
  const [worldName, setWorldName] = useState('')
  const [worldPricee, setWorldPricee] = useState('')
  const toggleSwitch = async() => {
    setIsOn((prev) => !prev);
    if(isOn){
      await axios.patch('https://sell-skill.com/api/endpoints/setAdvisorAsAvailable')
    }
    if(!isOn){
      await axios.patch('https://sell-skill.com/api/endpoints/setAdvisorAsUnAvailable')
    }
  };

////////////////////////header sssssssssssssssssssssssssssssssssssss
const [isMenuOpen, setIsMenuOpen] = useState(false);
const [alreadyRateWorld, setAlreadyRateWorld] = useState(false)
const [alreadyRateSkiller, setAlreadyRateSkiller] = useState(false)
const [skillerId, setSkillerId] = useState('')
const [myCurrentAdvisorId, setMyCurrentAdvisorId] = useState('')
const dropdownRef = useRef(null);
const [permissionToBeAnAdvisor, setPermissionToBeAnAdvisor] = useState(false)
const bufferToBase64 = (buffer) => {
  if (!Array.isArray(buffer)) return '';

  // Flatten the array if it contains nested arrays
  const flatArray = buffer.flat();

  // Process the flat array in manageable chunks
  const chunkSize = 10000; // Process 10,000 bytes at a time
  let base64String = '';
  for (let i = 0; i < flatArray.length; i += chunkSize) {
      const chunk = flatArray.slice(i, i + chunkSize);
      base64String += String.fromCharCode(...chunk);
  }

  return btoa(base64String);
};
useEffect(() => {
    const getSkillerId = async() => {
     const response =  await axios.get('https://sell-skill.com/api/endpoints/getSkillerId')
     setSkillerId(response.data)
     console.log('skillerId from in the front ===========================-=-=-=-=-=-=-> > > > > > >  > ',response.data)
    }

    const getMyAdvisorId = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getAMyCurrentdvisorId')
      setMyCurrentAdvisorId(response.data)
    }

    const getPermissionToBeAnAdvisor = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getToBeAdvisorPermission')
      setPermissionToBeAnAdvisor(response.data)
    }

    const getWorldName = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getWorldName', {params: worldId})
      setWorldName(response.data.worldName)
      setWorldPricee(response.data.worldPrice)
      console.log('response.data from getWorldName =======================================-=-=-----------------------------------=-==-=-=-=--=---> > > >>  >>  > > > > > > >  ',response.data)
    }

    const checkIsAlreadyRateWorld = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkIsAlreadyRateWorld')
      setAlreadyRateWorld(response.data)
    }
    checkIsAlreadyRateWorld();
    const checkIsAlreadyRateSkiller = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkIsAlreadyRateSkiller')
      setAlreadyRateSkiller(response.data)
    }

    const checkIfHaveAdvisor = async () => {
      
    }
    checkIsAlreadyRateSkiller();
    getWorldName();
    getPermissionToBeAnAdvisor();
    getMyAdvisorId();
    getSkillerId();
  },[])
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setSidebarVisible(false);
        } else {
            setSidebarVisible(true);
        }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {


    const fetchProfilePicture = async () => {
        try {
            const response = await axios.get(
                'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                { responseType: 'blob' }
            );
    
            console.log('Fetched blob:', response.data);
    
            if (response.data.size > 0) {
                // Blob has data, create an object URL
                const url = URL.createObjectURL(response.data);
                setProfilePictureSrc(url);
            } else {
                // Blob is empty, use the Firebase image URL
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
            // Optionally set a fallback image if there's an error
            setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
        }
    };
    


    fetchProfilePicture();
}, []);
const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
const handleLogout = async () => {
  // Clear localStorage
  console.log("Before clearing localStorage:", localStorage);
  localStorage.clear();
  console.log("After clearing localStorage:", localStorage);

  // Clear specific keys if clear() doesn't work
  localStorage.removeItem("authenticated");
  localStorage.removeItem("permission");
  localStorage.removeItem("skillerToken");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("lastExternalReferrerTime");

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear all cookies
  const clearAllCookies = () => {
      document.cookie.split(";").forEach((cookie) => {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      });
  };
  clearAllCookies();

  console.log("Cookies after clearing:", document.cookie);

  // Delay to ensure clearing is complete
  await new Promise((resolve) => setTimeout(resolve, 100));

  // Redirect to login page
  navigate("/skiller-signIn");
};


//////////////////////////////////header eeeeeeeeeeeeeeeeeeeeeeeeeeeeee




 


    const [levels, setLevels] = useState([]);
    const [activeTab, setActiveTab] = useState('levels');
    const [matesRequests, setMatesRequests] =useState([])
    const [worldPrice, setWorldPrice] = useState('')
    const [isPaidStatus, setIsPaidStatus] = useState(false)
    const [worlds, setWorlds] = useState([])
    const [currentMates, setCurrentMates] = useState([])     
    const [activeSubTab, setActiveSubTab] = useState('')
        const [Aposts, setAPosts] = useState([])
        const [ASposts, setASPosts] = useState([])
        const [ASVposts, setASVPosts] = useState([])
      const [showModal, setShowModal] = useState(false);
    const [mates, setMates] = useState([])
    const [rate, setRate] = useState('');
    const [rateP, setRateP] = useState('');
      const [selectedPost, setSelectedPost] = useState(null);
        const [resources, setResources] = useState([])
    const [comment, setComment] = useState('');
    const [expandedLevel, setExpandedLevel] = useState(null);
    const [messages, setMessages] =useState('')
    const [isLevelCompeleted, setIsLevelCompleted] = useState(false); // get it from the backend
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const dispatch = useDispatch();   
    const navigate = useNavigate();
    const {worldId} = useParams();
    const [loading, setLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [profilePictureSrc, setProfilePictureSrc] = useState('');
    const [posts, setPosts] = useState([])
    const examId = uuidv4();
    const [checked, setChecked] = useState(false);

    const handleChange =async (event) => {
      setChecked(event.target.checked);
      if(event.target.checked){
        await axios.patch('https://sell-skill.com/api/endpoints/setAdvisorAsAvailable')
      }
      if(!event.target.checked){
        await axios.patch('https://sell-skill.com/api/endpoints/setAdvisorAsUnAvailable')
      }
    };
    const handleChooseAdvisor = (levelId) => {navigate('/choose-advisor')

      setLevelId(levelId)
    };
    const handleMessageAdvisor = () => {
      setWithId(myCurrentAdvisorId);
      navigate('/message-advisor');


    }
    const handleAddMate = async (mateId) => {
        const mateRequestId = uuidv4();
        await axios.post('https://sell-skill.com/api/endpoints/sendMateRequest', {mateId, mateRequestId})
        console.log(`Add mate with ID: ${mateId}`);

        // Add your logic here
    };
           
    const handleMessage = (mateId) => {
        console.log(`Message mate with ID: ${mateId}`);
        // Add your logic here
    };
    
    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const handleCloseModal = () => setShowModal(false);
         useEffect(() => {
       const setLevelCompleted = async () => {
             const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', {params:  {worldId }});
             setIsLevelCompleted(response.data);
             console.log('isLevelCompleted:', response.data);
         };

         setLevelCompleted();
     }, [worldId]);

    useEffect(() => {
        const fetchLevels = async () => {
            setLoading(true);
 
            try {
              setLoading(true)
                const response = await axios.get('https://sell-skill.com/api/endpoints/getOnlyPublishedLevels', {
                    params: { worldId },
                });
                console.log('levelsForGetOnlyPublishedLevels ====================================-=----------------=-=-=-----------------=-=----------------=-=------------=-=------------=-=------------> >  >> > > > > > > >   ',response.data)
                setLevels(response.data);
            } catch(error)  {
          console.log(error.message)
            }  
            finally {
              
              setLoading(false)
  }

         
        };

        fetchLevels();
    }, [worldId]);





    useEffect(() => {

        const fetchProfilePicture = async () => {
            try {
                const response = await axios.get(
                    'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
                    { responseType: 'blob' }
                );
        
                console.log('Fetched blob:', response.data);
        
                if (response.data.size > 0) {
                    // Blob has data, create an object URL
                    const url = URL.createObjectURL(response.data);
                    setProfilePictureSrc(url);
                } else {
                    // Blob is empty, use the Firebase image URL
                    setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
                }
            } catch (error) {
                console.error('Error fetching profile picture:', error);
                // Optionally set a fallback image if there's an error
                setProfilePictureSrc('https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/images%20(46).jpg?alt=media&token=4443b115-7221-4168-830b-755016ca462a');
            }
        };


        


        fetchProfilePicture();
    }, []);



    const handleUnSavePost = async(postId) => {
        await axios.delete('https://sell-skill.com/api/endpoints/unSavePost', {postId})
    }

    const handleSavePost = async(postId) => {
      await axios.delete('https://sell-skill.com/api/endpoints/savePost', {postId})
  }
    const handleShowModal = (post) => {
        setSelectedPost(post);
        setResources([
          ...(post.photos || []).map((item) => ({ type: "photo", content: item.contentUrl })),
          ...(post.videos || []).map((item) => ({ type: "video", content: item.contentUrl })),
          ...(post.pdfs || []).map((item) => ({ type: "pdf", content: item.contentUrl })),
          ...(post.texts || []).map((item) => ({ type: "text", content: item })),
          ...(post.quizs || []).map((item) => ({
            type: "quiz",
            content: item.content,
            choices: item.choices,
            correctAnswer: item.correctAnswer,
          })),
        ]);
    
        console.log('resources =============================-=-=-=-=-=-=-=-=-> > > >  > > > >>  ',resources)
        setShowModal(true);
      };
    const handleShowLevel = (levelNumber, id) => {
        dispatch(setLevelId(id));
        dispatch(setLevelNumber(levelNumber));
        navigate('/lessons');
    };


    const handleToggleMateSection = () => {
        setActiveTab('mates')
        setActiveSubTab('exploreMates')
    }
    const handleTogglePostsSection = () => {
        setActiveTab('posts')
        setActiveSubTab('explorePosts')
    }

    const handleRateWorld = async () => {
        try {
            await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
        } catch (error) {
            console.error('Error rating world:', error);
        }
    };


        const handleRatePublisher = async () => {
        try {
       
            await axios.post('https://sell-skill.com/api/endpoints/ratePublisher', { rateId: uuidv4(), rateP, worldId });
        } catch (error) {
            console.error('Error rating world:', error);
        }
    };


    const addCommentToTheWorld = async () => {
        try {
            await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
                commentId: uuidv4(),
                comment,
                worldId,
            });
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

         const handleStartExam = async (levelId) => {
        dispatch(setExamId(examId));
        await axios.post('https://sell-skill.com/api/endpoints/startExam', {
            levelId,
             worldId,
            examId,
        });
     };





     const [expandedSection, setExpandedSection] = useState(null);

     const toggleSection = (section) => {
       setExpandedSection(expandedSection === section ? null : section);
     };
   
     const toggleLevel = (levelId) => {
       setExpandedLevel(expandedLevel === levelId ? null : levelId);
     };
     //get mates ssssssssssssssssssssssssssssssssssssssssss
     useEffect(() => {
        const getMates = async() => {

            const response = await axios.get('https://sell-skill.com/api/endpoints/getMates')
            setMates(response.data)
        }
        getMates();
    }, [])
    
    useEffect(() => {
        const getMateRequests = async() => {
           const response = await axios.get('https://sell-skill.com/api/endpoints/getMateRequests')
           setMatesRequests(response.data)
           console.log('response.data from getMateRequests ==================-=-=-=-==========-=-=-=-=-=-> > > >  > > > >  ',response.data)
        }
        getMateRequests()
    }, [])


    useEffect(() => {
        const getMateRequests = async() => {
           const response = await axios.get('https://sell-skill.com/api/endpoints/getCurrentMates')
           setCurrentMates(response.data)
           console.log('response.data from getMateRequests =========================================================================-=-=-=-==-=-=-=-=-=-> > > > > >  > >>  >> > >  ',response.data)
        }
        getMateRequests()
    }, [])

    useEffect(() => {
        const getPosts = async () => {
 
            const response = await axios.get(
              "https://sell-skill.com/api/endpoints/ASVgetPosts"
           
            );
            console.log('response.data from ASV getPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
            setASVPosts(response.data);
    
            console.log('response.data from ASVgetPosts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)

        };
        getPosts();
      },[]); 
    useEffect(() => {
        const getPosts = async () => {
          try {
            const response = await axios.get(
              "https://sell-skill.com/api/endpoints/AgetPosts"
           
            );
            console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
            setAPosts(response.data);
    
            console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
          } catch (error) {
            console.error("Error fetching posts:", error);
          }
        };
        getPosts();
      },[]);  

      useEffect(() => {
        const getPosts = async () => {
          try {
            const response = await axios.get(
              "https://sell-skill.com/api/endpoints/ASgetPosts"
           
            );
            console.log('response.data from AgetPosts ==========================-=-=-=-=-=-=-= > > > > > > > > > > >  ',response.data)
            setASPosts(response.data);
    
            console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
          } catch (error) {
            console.error("Error fetching posts:", error);
          }
        };
        getPosts();
      },[]);  
    ///get mates eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
     useEffect(() => {
        const getSendersInfo = async() => {
          const response = await axios.get('https://sell-skill.com/api/endpoints/chats1')
          setMessages(response.data)
          console.log('senders ==========>> > > > > > > > > >>>>   >>>>  >> ',response.data)
        }
        getSendersInfo();
      }, [])

      const handleNameClick = (withId) => {
        dispatch(setWithId(withId))
        navigate('/chat')
    
      }


      useEffect(() => {
        const getPosts = async() => {
           const response =  await axios.get('https://sell-skill.com/api/endpoints/WgetPosts', {params: {worldId}})
           setPosts(response.data)
        }
        getPosts()

      }, [])


      useEffect(() => {
        const checkIsPaidWorld = async() => {
        setLoading(true)
        try
{          const response = await axios.get('https://sell-skill.com/api/endpoints/checkIsPaidWorld', {params: {worldId}})
          console.log('response.data from checkIsPaidWorld =========================-=-=-=-> > > > > > >  ',response.data)
          setIsPaidStatus(response.data)}
          catch(error) {
          console.log(error.message)
          }
          finally{
                    setLoading(false)
          }

        }
        checkIsPaidWorld()
      }, [])

      
    
  //payment--/payment--/payment////--payment//start--start--start//
    

  useEffect(() => {
    const getPrice = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getWorldPrice', {params: {worldId}})
      console.log('worldPrice from the back =====================-=-=-=-=-=-=-=> >   >> > >  > > > ', response.data)
      setWorldPrice (response.data)
      if(response.data === 0){
        setIsPaidStatus(true)
      }
    }
    getPrice()
  }, [])

  useEffect(() => {
    // Load PayPal Script
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        
        // PayPal SDK script to include both PayPal and card payments
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };
  
    const renderPayPalButton = () => {
      if (!worldPrice) return; // Prevent rendering if worldPrice is not available
      window.paypal.Buttons({
        // No funding source restriction, so both PayPal and card will be available
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: worldPrice.toString(), // Use the worldPrice value dynamically
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
            try {
              await axios.patch('https://sell-skill.com/api/endpoints/paidWorld', { worldId, purchaseId: uuidv4() });
              setIsPaidStatus(true);
              window.fbq('track', 'Purchase', {
                content_name: 'Purchase page',
                value: worldPrice,
                currency: 'USD'
              });
            } catch (error) {
              console.error('Error updating payment status: ', error);
            }
            window.location.reload();
          });
        },
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);

        },
      }).render('#paypal-button-container');
    };
  
    if (worldPrice) {
      addPayPalScript();
    }
  }, [worldPrice]); // Add worldPrice as a dependency
  

  
    //payment--/payment--/payment////--payment//end--end--end--end--end//
  

    return (
    <>
    {
    loading ? (


            <div
                className="loading-circle"
                style={{
                    position: 'fixed', // Use 'fixed' to ensure it stays in the center of the viewport
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)', // Adjust for both vertical and horizontal alignment
                    zIndex: 1000, // Optional: Ensure it stays on top of other elements
                }}
            >
                <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <circle
                        cx="25"
                        cy="25"
                        r="20"
                        stroke="#27ae60"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeDasharray="90 150"
                        strokeDashoffset="0"
                    >
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="1s"
                            repeatCount="indefinite"
                        />
                    </circle>
                </svg>
            </div>

    ) : (   <>
{    isPaidStatus ? ( <> 
<PageContainer>
  <Container>

    <Header>
      <Navbar>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>





        <NavLinks isOpen={isMenuOpen}>
          <a href={`/my-profile/${skillerId}`}>My Profile</a>
          <a href="/manage-uploads">Uploads</a>
          <a href="/financial-management">Withdraw Cash</a>
          <a href="/my-chats">My chats</a>
          <a href="/my-exams">My Exams</a>

        </NavLinks>

        <NavIcons>
 
          <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
          <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
        </NavIcons>


        <div ref={dropdownRef}>
          {profilePictureSrc && (
            <ProfileWrapper onClick={handleProfileClick}>
              <ProfilePicture src={profilePictureSrc} alt="Profile" />
            </ProfileWrapper>
          )}
          {showDropdown && (
            <DropdownMenu>
              <a href={`/my-profile/${skillerId}`}>My Profile</a>
              <a href="/manage-uploads">Uploads Management</a>
              <a href="/manage-financial">Financial Management</a>
              <a href="/advisors-&-students">Advisors & Students</a>
              <a href="/skiller-signIn" onClick={handleLogout}>
                Log Out
              </a>
            </DropdownMenu>
          )}
        </div>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <FaBars />
        </Hamburger>
      </Navbar>


    </Header>
      
            <ContentContainer>
{       sidebarVisible &&      (   <Sidebar>

                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div>
                        {/* My Progress Section */}

 


                        <div>
                        <SectionHeader onClick={() => toggleSection('progress')}>
        {expandedSection === 'progress' ? <FaChevronUp /> : <FaChevronDown />}
        My Progress
    </SectionHeader>
                          {expandedSection === 'progress' && (
                            <ProgressList>
                              {levels && levels.map((level) => (
                                <ProgressItem key={level._id}>
                                  <LevelHeader onClick={() => toggleLevel(level._id)}>
                                    <LevelTitle>
                                      {level.isLevelCompleted ? <FaCheck color="green" /> : <FaLock color="red" />}
                                      {level.levelTitle}
                                    </LevelTitle>
                                    {expandedLevel === level._id ? <FaChevronUp /> : <FaChevronDown />}
                                  </LevelHeader>
                                  {expandedLevel === level._id && (
                                    <LessonList>
                                      {level.lessons.map((lesson) => (
                                        <LessonItem key={lesson._id}>
                                          {lesson.isLessonCompleted ? <FaCheck color="green" /> : <FaLock color="red" />}
                                          {lesson.lessonTitle}
                                        </LessonItem>
                                      ))}
                                    </LessonList>
                                  )}
                                </ProgressItem>
                              ))}
                            </ProgressList>
                          )}
                        </div>
                  
                        {/* My Chats Section */}
                        <div>
                        <SectionHeader onClick={() => toggleSection('chats')}>
        {expandedSection === 'chats' ? <FaChevronUp /> : <FaChevronDown />}
        My Chats
    </SectionHeader>  
                          {expandedSection === 'chats' &&                     
                          messages.length > 0 && 
                messages.map((message) => (
                  <div key={message._id}>
                    {message.senderName && (
                      <Button onClick={() => handleNameClick(message.withId)} variant="light" style={{ width: '100%' }}>
                        {message.senderName}
                      </Button>
                    )}
                  </div>
                ))
               }
                        </div>




  




                      </div>
                    )}
                </Sidebar>)
}
     {
        !isMenuOpen &&      <ToggleSidebarButton onClick={toggleSidebar}>
         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
     </ToggleSidebarButton>
}

                <MainContent>










                <Header>
    <TabContainer>
        <Tab
            active={activeTab === 'levels'}
            onClick={() => setActiveTab('levels')}
        >
            Explore Levels
        </Tab>

        <Tab
            active={activeTab === 'mates'}
            onClick={handleToggleMateSection}
        >
            Explore Mates
        </Tab>
        <Tab
            active={activeTab === 'posts'}
            onClick={handleTogglePostsSection}
        >
            Explore Posts
        </Tab>
    </TabContainer>
    {   <UploadButtonWrapper>
        <UploadButton onClick={() => navigate('/upload-post-to-world')}>
            <FaUpload style={{ marginRight: '5px' }} /> Upload post
        </UploadButton>
    </UploadButtonWrapper>}




    {
      permissionToBeAnAdvisor &&     <ToggleContainer onClick={toggleSwitch}>
      <Slider isOn={isOn} />
    </ToggleContainer>
    }
</Header>

{activeTab === 'levels' && (
    <ContentWrapper sidebarVisible={sidebarVisible}>
              {  
          worldName &&
          <h2 style={{ 
            fontSize: '1.5rem', 
            fontWeight: 'bold', 
            color: '#2c3e50', 
            textAlign: 'center', 
            marginBottom: '1rem' 
          }}>Levels of {worldName} world</h2>}
    <LevelsGrid>
        {levels?.map((level) => (
            <LevelCard key={level?._doc?._id} isOpen={level?._doc?.isOpen}>
                <h2>{level?._doc?.levelTitle}</h2>
                <p>{level?._doc?.description}</p>
                {level?._doc?.toBeAnAdvisorLevel && (
                    <FaGraduationCap size={50} color="#2c3e50" style={{ marginBottom: '10px' }} />
                )}
                <Actions>
 
                    <Button
                        onClick={() => handleShowLevel(level.doc?.levelNumber, level?.doc?._id)}
                        disabled={!level?._doc?.isOpen}
                    >
                        Get In
                    </Button>
                    <Button
                        onClick={() => navigate('/level-description')}
                        disabled={!level?._doc?.isOpen}
                    >
                        View Description
                    </Button>
                    <Button onClick={() => handleChooseAdvisor(level?._doc?._id)} disabled={!level?._doc?.isOpen}>
                        choose Advisor
                    </Button>
                    <Button onClick={() => handleStartExam(level?._doc?._id)} disabled={!level?._doc?.isLevelCompleted || level?.preventExamButton}>
                        Take Exam
                    </Button>
                </Actions>
            </LevelCard>
        ))}
    </LevelsGrid>
    </ContentWrapper>
)}


{activeTab === 'mates' && (
    <>
        {/* Sub-tabs for mates */}
        <SubTabContainer>
            <SubTab active={activeSubTab === 'exploreMates'} onClick={() => setActiveSubTab('exploreMates')}>
                Explore Mates
            </SubTab>
            <SubTab active={activeSubTab === 'matesRequests'} onClick={() => setActiveSubTab('matesRequests')}>
                Mates Requests
            </SubTab>
            <SubTab active={activeSubTab === 'currentMates'} onClick={() => setActiveSubTab('currentMates')}>
                Current Mates
            </SubTab>
        </SubTabContainer>

        {/* Content based on the selected sub-tab */}
        {activeSubTab === 'exploreMates' && (
            <ContentWrapper sidebarVisible={sidebarVisible}>
            <Container fluid>
                
                <Row>
                    
                    {mates.map((mate) => {
                        const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                        return (
        <Col
            key={mate._id}
            xs={12}
            sm={mates.length === 1 ? 8 : 6}
            md={mates.length === 1 ? 6 : 4}
            lg={mates.length === 1 ? 4 : 3}
            className="mb-4"
            style={mates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
        >
            <CardContainer>
            <Card
                style={{
                    minHeight: '350px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                {mate.picture && (
                      <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                    <div className="text-center mt-3">
                        <Card.Img
                            variant="top"
                            src={mate.picture || '/images/default-profile.jpg'}
                            alt={`${mate.name || 'Mate'}'s profile`}
                            className="rounded-circle mx-auto"
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'contain',
                                border: '2px solid #ccc',
                            }}
                        />
                    </div>
                    </a>
                )}
                <Card.Body style={{ flex: 1 }}>
                <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
                    <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {`${mate.name} ${mate.surname}`}
                    </Card.Title>
                    </a>
                    <Card.Text className="text-center">
                        Total Cash: ${mate.totalCash || 0}
                    </Card.Text>
                    <div className="d-flex justify-content-center mb-3">
                        {Array.from({ length: 5 }, (_, i) => (
                            i < averageRate ? (
                                <FaStar key={i} color="gold" />
                            ) : (
                                <FaRegStar key={i} color="gold" />
                            )
                        ))}
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        {mate.sharedWorlds?.map((world) => (
                             <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                                {world.thumbnail && 
                                    <img
                                        key={world._id}
                                        src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                        alt={world.name || 'World'}
                                        title={world.name || 'World'}
                                        className="rounded-circle"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            objectFit: 'contain',
                                            margin: '5px',
                                        }}
                                    />
                                }
                            </a>
                        ))}
                    </div>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                    <Button variant="success" onClick={() => handleAddMate(mate._id)}>
                        Add Mate
                    </Button>
                    <Button variant="primary" onClick={() => handleMessage(mate._id)}>
                        Message
                    </Button>
                </Card.Footer>
            </Card>
            </CardContainer>
        </Col>
        
                        );
                    })}
                </Row>
            </Container>
        </ContentWrapper>



        )}

        

        {activeSubTab === 'matesRequests' && (
            <ContentWrapper sidebarVisible={sidebarVisible}>
    <Container fluid>
        
        <Row>
            
            {matesRequests.map((mate) => {
                const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                return (
<Col
    key={mate._id}
    xs={12}
    sm={matesRequests.length === 1 ? 8 : 6}
    md={matesRequests.length === 1 ? 6 : 4}
    lg={matesRequests.length === 1 ? 4 : 3}
    className="mb-4"
    style={matesRequests.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
>
    <CardContainer>
    <Card
        style={{
            minHeight: '350px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}
    >
        {mate.picture && (
                                  <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
            <div className="text-center mt-3">
                <Card.Img
                    variant="top"
                    src={mate.picture || '/images/default-profile.jpg'}
                    alt={`${mate.name || 'Mate'}'s profile`}
                    className="rounded-circle mx-auto"
                    style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'contain',
                        border: '2px solid #ccc',
                    }}
                />
            </div>
            </a>
        )}
        <Card.Body style={{ flex: 1 }}>
        <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
            <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {`${mate.name} ${mate.surname}`}
            </Card.Title>
            </a>
            <Card.Text className="text-center">
                Total Cash: ${mate.totalCash || 0}
            </Card.Text>
            <div className="d-flex justify-content-center mb-3">
                {Array.from({ length: 5 }, (_, i) => (
                    i < averageRate ? (
                        <FaStar key={i} color="gold" />
                    ) : (
                        <FaRegStar key={i} color="gold" />
                    )
                ))}
            </div>
            <div className="d-flex justify-content-center mb-3">
                {mate.sharedWorlds?.map((world) => (
                                                 <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                        {world.thumbnail &&
                            <img
                                key={world._id}
                                src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                alt={world.name || 'World'}
                                title={world.name || 'World'}
                                className="rounded-circle"
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    objectFit: 'contain',
                                    margin: '5px',
                                }}
                            />
                        }
                    </a>
                ))}
            </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
            <Button variant="success" onClick={() => handleAddMate(mate._id)}>
                Add Mate
            </Button>
            <Button variant="primary" onClick={() => handleMessage(mate._id)}>
                Message
            </Button>
        </Card.Footer>
    </Card>
    </CardContainer>
</Col>

                );
            })}
        </Row>
    </Container>
</ContentWrapper>

        )}

        {activeSubTab === 'currentMates' && (

<ContentWrapper sidebarVisible={sidebarVisible}>
    <Container fluid>
        
        <Row>
            
            {currentMates.map((mate) => {
                const averageRate = Math.round(mate.rate) || 0; // Assuming rate is a number
                return (
<Col
    key={mate._id}
    xs={12}
    sm={currentMates.length === 1 ? 8 : 6}
    md={currentMates.length === 1 ? 6 : 4}
    lg={currentMates.length === 1 ? 4 : 3}
    className="mb-4"
    style={currentMates.length === 1 ? { maxWidth: '400px', minWidth: '250px' } : { minWidth: '250px' }}
>
    <CardContainer>
    <Card
        style={{
            minHeight: '350px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}
    >
        {mate.picture && (
                                  <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
            <div className="text-center mt-3">
                <Card.Img
                    variant="top"
                    src={mate.picture || '/images/default-profile.jpg'}
                    alt={`${mate.name || 'Mate'}'s profile`}
                    className="rounded-circle mx-auto"
                    style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'contain',
                        border: '2px solid #ccc',
                    }}
                />
            </div>
            </a>
        )}
        <Card.Body style={{ flex: 1 }}>
        <a href={`/profile/${mate._id}`} style={{ textDecoration: 'none' }}>
            <Card.Title className="text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {`${mate.name} ${mate.surname}`}
            </Card.Title>
            </a>
            <Card.Text className="text-center">
                Total Cash: ${mate.totalCash || 0}
            </Card.Text>
            <div className="d-flex justify-content-center mb-3">
                {Array.from({ length: 5 }, (_, i) => (
                    i < averageRate ? (
                        <FaStar key={i} color="gold" />
                    ) : (
                        <FaRegStar key={i} color="gold" />
                    )
                ))}
            </div>
            <div className="d-flex justify-content-center mb-3">
                {mate.sharedWorlds?.map((world) => (
                                                <a href={`world-trailer/${world._id}`} style={{ textDecoration: 'none' }}>
                        {world.thumbnail &&
                            <img
                                key={world._id}
                                src={world.thumbnail || '/images/default-world-thumbnail.jpg'}
                                alt={world.name || 'World'}
                                title={world.name || 'World'}
                                className="rounded-circle"
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    objectFit: 'contain',
                                    margin: '5px',
                                }}
                            />
                        }
                    </a>
                ))}
            </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
            <Button variant="success" onClick={() => handleAddMate(mate._id)}>
                Add Mate
            </Button>
            <Button variant="primary" onClick={() => handleMessage(mate._id)}>
                Message
            </Button>
        </Card.Footer>
    </Card>
    </CardContainer>
</Col>

                );
            })}
        </Row>
    </Container>
</ContentWrapper>

        )}
    </>
)}



{activeTab === 'posts' && (
<>

<SubTabContainer>
            <SubTab active={activeSubTab === 'explorePosts'} onClick={() => setActiveSubTab('explorePosts')}>
                Explore Posts
            </SubTab>
            <SubTab active={activeSubTab === 'myPosts'} onClick={() => setActiveSubTab('myPosts')}>
                My Posts
            </SubTab>
            <SubTab active={activeSubTab === 'savedPosts'} onClick={() => setActiveSubTab('savedPosts')}>
                Saved Posts
            </SubTab>
        </SubTabContainer>

        { activeSubTab === 'explorePosts' &&
            <ContentWrapper sidebarVisible={sidebarVisible}>
    <Container fluid>

   

      <h3 className="mt-5">Posts</h3>
      <Row>
        {Aposts.map((post) => (
          <Col md={4} key={post._id} className="mb-4">
            <CardContainer>
            <Card>
            <Card.Header className="d-flex align-items-center">
{        post.poster.picture &&                    <a href={`/profile/${post.poster._id}`} style={{ textDecoration: 'none' }}>
    <img
        src={ `data:${post.poster.picture.picture.contentType};base64,${bufferToBase64(post.poster.picture.picture.data.data)}`}
        alt="Publisher Picture"
        style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '10px',
        }}
    />
</a>}

                    <div>
{         post.poster.name &&               <a
                            href={`/profile/${post.poster._id}`}
                            style={{ textDecoration: 'none', color: '#2c3e50', fontWeight: 'bold' }}
                        >
                            {post.poster.name}
                        </a>}
                        <div>
                            {Array.from({ length: 5 }, (_, i) => (
                                <FaStar
                                    key={i}
                                    size={12}
                                    color={i < post.poster.rate ? '#f39c12' : '#dcdcdc'}
                                />
                            ))}
                        </div>
                    </div>
                </Card.Header>
              {post.thumbnail && (
                <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
              )}
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.description}</Card.Text>
                <Button variant="primary" >
                  <FaThumbsUp/>
                </Button>
                <Button
                  variant="danger"
                  className="ms-2"
              
                >
                  <FaComment/>
                </Button>
                <Button
                  variant="info"
                  className="ms-2"
                  onClick={() => handleShowModal(post)}
                >
                  <FaPaperclip/>
                </Button>

                <Button
                  variant="info"
                  className="ms-2"
                  onClick={() => handleSavePost(post)}
                >
                  <FaSave/>                                                              
                </Button>

                <h2>Rate Publisher</h2>
                        <RatingContainer>
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    size={24}
                                    color={star <= rate ? "#f1c40f" : "#bdc3c7"}
                                    onClick={() => setRateP(star)}
                                    style={{ cursor: "pointer" }}
                                />
                            ))}
                        </RatingContainer>
                        <button onClick={handleRatePublisher}>Submit Rating</button>
              </Card.Body>
            </Card>
            </CardContainer>
          </Col>
        ))}
      </Row>

      {/* Modal */}
      <Modal
  show={showModal}
  onHide={handleCloseModal}
  centered
  style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
>
  <Modal.Header closeButton>
    <Modal.Title style={{ fontSize: "1.5rem" }}>Resources for {selectedPost?.title}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <ListGroup variant="flush">
      {resources?.map((res, idx) => (
        <ListGroup.Item
          key={idx}
          style={{
            fontSize: "1.2rem", // Larger text
            padding: "1.5rem", // Extra padding for better appearance
          }}
        >
          {res.type}:{" "}
          {res.type === "photo" && (
            <img
              src={res.content}
              alt="Photo"
              style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
            />
          )}
          {res.type === "video" && (
            <video
              controls
              style={{
                maxWidth: "100%",
                display: "block",
                margin: "10px auto",
              }}
            >
              <source src={res.content} type="video/mp4" />
            </video>
          )}
          {res.type === "pdf" && (
            <a
              href={res.content}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
            >
              View PDF
            </a>
          )}
          {res.type === "text" && (
            <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
          )}
          {res.type === "quiz" && (
            <>
              <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
              <ul style={{ paddingLeft: "20px" }}>
                {res.choices?.map((choice, i) => (
                  <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
                    {choice}
                  </li>
                ))}
              </ul>
              <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                Correct Answer: {res.correctAnswer}
              </p>
            </>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Modal.Body>
  <Modal.Footer>
    <Button
      variant="secondary"
      onClick={handleCloseModal}
      style={{
        padding: "0.75rem 1.5rem",
        fontSize: "1.2rem", // Larger button
      }}
    >
      Close
    </Button>
  </Modal.Footer>
</Modal>

    </Container>
    </ContentWrapper>

    }




                    { activeSubTab === 'myPosts' &&
            <ContentWrapper sidebarVisible={sidebarVisible}>
            <Container fluid>
        
           
        
              <h3 className="mt-5">My Posts</h3>
              <Row>
                {ASposts.map((post) => (
                  <Col md={4} key={post._id} className="mb-4">
                    <CardContainer>
                    <Card>
                      {post.thumbnail && (
                        <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
                      )}
                      <Card.Body>
                        <Card.Title>{post.title}</Card.Title>
                        <Card.Text>{post.description}</Card.Text>
                        <Button variant="primary" >
                          Like
                        </Button>
                        <Button
                          variant="danger"
                          className="ms-2"
                      
                        >
                          Comment
                        </Button>
                        <Button
                          variant="info"
                          className="ms-2"
                          onClick={() => handleShowModal(post)}
                        >
                          Save
                        </Button>
                        <Button
                          variant="info"
                          className="ms-2"
                          onClick={() => handleShowModal(post)}
                        >
                          View attachements
                        </Button>
                      </Card.Body>
                    </Card>
                    </CardContainer>
                  </Col>
                ))}
              </Row>
        
              {/* Modal */}
              <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "1.5rem" }}>Attachements for {selectedPost?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup variant="flush">
              {resources?.map((res, idx) => (
                <ListGroup.Item
                  key={idx}
                  style={{
                    fontSize: "1.2rem", // Larger text
                    padding: "1.5rem", // Extra padding for better appearance
                  }}
                >
                  {res.type}:{" "}
                  {res.type === "photo" && (
                    <img
                      src={res.content}
                      alt="Photo"
                      style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
                    />
                  )}
                  {res.type === "video" && (
                    <video
                      controls
                      style={{
                        maxWidth: "100%",
                        display: "block",
                        margin: "10px auto",
                      }}
                    >
                      <source src={res.content} type="video/mp4" />
                    </video>
                  )}
                  {res.type === "pdf" && (
                    <a
                      href={res.content}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
                    >
                      View PDF
                    </a>
                  )}
                  {res.type === "text" && (
                    <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
                  )}
                  {res.type === "quiz" && (
                    <>
                      <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
                      <ul style={{ paddingLeft: "20px" }}>
                        {res.choices?.map((choice, i) => (
                          <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
                            {choice}
                          </li>
                        ))}
                      </ul>
                      <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                        Correct Answer: {res.correctAnswer}
                      </p>
                    </>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              style={{
                padding: "0.75rem 1.5rem",
                fontSize: "1.2rem", // Larger button
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        
            </Container>
            </ContentWrapper>}



                    { activeSubTab === 'savedPosts' &&
 <ContentWrapper sidebarVisible={sidebarVisible}>
 <Container fluid>



   <h3 className="mt-5">Posts</h3>
   <Row>
     {ASVposts.map((post) => (
       <Col md={4} key={post._id} className="mb-4">
        <CardContainer>
         <Card>
           {post.thumbnail && (
             <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
           )}
           <Card.Body>
             <Card.Title>{post.title}</Card.Title>
             <Card.Text>{post.description}</Card.Text>
             <Button variant="primary" >
               Like
             </Button>
             <Button
               variant="danger"
               className="ms-2"
           
             >
               Comment
             </Button>
             <Button
               variant="info"
               className="ms-2"
               onClick={() => handleUnSavePost(post._id)}
             >
               Unsave
             </Button>
             <Button
               variant="info"
               className="ms-2"
               onClick={() => handleShowModal(post)}
             >
               View attachements
             </Button>
           </Card.Body>
         </Card>
         </CardContainer>
       </Col>
     ))}
   </Row>

   {/* Modal */}
   <Modal
show={showModal}
onHide={handleCloseModal}
centered
style={{ maxWidth: "90%", margin: "auto" }} // Enlarges the modal
>
<Modal.Header closeButton>
 <Modal.Title style={{ fontSize: "1.5rem" }}>Attachements for {selectedPost?.title}</Modal.Title>
</Modal.Header>
<Modal.Body>
 <ListGroup variant="flush">
   {resources?.map((res, idx) => (
     <ListGroup.Item
       key={idx}
       style={{
         fontSize: "1.2rem", // Larger text
         padding: "1.5rem", // Extra padding for better appearance
       }}
     >
       {res.type}:{" "}
       {res.type === "photo" && (
         <img
           src={res.content}
           alt="Photo"
           style={{ maxWidth: "200px", display: "block", margin: "10px auto" }} // Larger photo
         />
       )}
       {res.type === "video" && (
         <video
           controls
           style={{
             maxWidth: "100%",
             display: "block",
             margin: "10px auto",
           }}
         >
           <source src={res.content} type="video/mp4" />
         </video>
       )}
       {res.type === "pdf" && (
         <a
           href={res.content}
           target="_blank"
           rel="noopener noreferrer"
           style={{ fontSize: "1.3rem", color: "#007bff" }} // Larger and styled link
         >
           View PDF
         </a>
       )}
       {res.type === "text" && (
         <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
       )}
       {res.type === "quiz" && (
         <>
           <p style={{ fontSize: "1.2rem", lineHeight: "1.5" }}>{res.content}</p>
           <ul style={{ paddingLeft: "20px" }}>
             {res.choices?.map((choice, i) => (
               <li key={i} style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
                 {choice}
               </li>
             ))}
           </ul>
           <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
             Correct Answer: {res.correctAnswer}
           </p>
         </>
       )}
     </ListGroup.Item>
   ))}
 </ListGroup>
</Modal.Body>
<Modal.Footer>
 <Button
   variant="secondary"
   onClick={handleCloseModal}
   style={{
     padding: "0.75rem 1.5rem",
     fontSize: "1.2rem", // Larger button
   }}
 >
   Close
 </Button>
</Modal.Footer>
</Modal>

 </Container>
 </ContentWrapper>}
                    </>
                )}










<ContentWrapper sidebarVisible={sidebarVisible}>
    {/* Other components */}
    <FeedbackSection sidebarVisible={sidebarVisible}>
{     !alreadyRateWorld &&        <>         <h2>Rate and Comment on the World</h2>
                        <RatingContainer>
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    size={24}
                                    color={star <= rate ? "#f1c40f" : "#bdc3c7"}
                                    onClick={() => setRate(star)}
                                    style={{ cursor: "pointer" }}
                                />
                            ))}
                        </RatingContainer>
                        <button onClick={handleRateWorld}>Submit Rating</button></>}

{    !alreadyRateSkiller  &&      <>            <h2>Rate Publisher</h2>
                        <RatingContainer>
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    size={24}
                                    color={star <= rateP ? "#f1c40f" : "#bdc3c7"}
                                    onClick={() => setRateP(star)}
                                    style={{ cursor: "pointer" }}
                                />
                            ))}
                        </RatingContainer>
                        <button onClick={handleRatePublisher}>Submit Rating</button> </>}
                        <textarea
                            placeholder="Enter your comment"
                            onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <button onClick={addCommentToTheWorld}>Add Comment</button>
                    </FeedbackSection>
                    </ContentWrapper>
                </MainContent>
            </ContentContainer>
            </Container>
        </PageContainer> </> ): (
          <div className="payment-section" style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', maxWidth: '500px', margin: 'auto' }}>
  <Card className="payment-card" style={{ border: 'none', backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
    <Card.Body>
      <h2 className="payment-title" style={{ color: '#2c3e50', fontWeight: 'bold', fontSize: '24px', textAlign: 'center', marginBottom: '16px' }}>Unlock {worldName} world</h2>
      <p className="payment-description" style={{ color: '#7f8c8d', fontSize: '16px', textAlign: 'center', marginBottom: '24px' }}>
        Get full access to the {worldName} world by making a one-time payment of <strong style={{ color: '#27ae60' }}>{worldPrice.toString()} $ </strong>.
      </p>
    
      <div className="payment-buttons" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <div id="paypal-button-container" className="paypal-button" style={{ flex: '1', maxWidth: '200px' }}></div>
        {/* Uncomment below if Stripe is enabled */}
        {/* <button id="stripe-button" className="stripe-button" style={{ backgroundColor: '#27ae60', color: '#fff', border: 'none', borderRadius: '4px', padding: '10px 16px', cursor: 'pointer', fontSize: '16px', transition: 'background-color 0.3s' }} onMouseOver={(e) => e.target.style.backgroundColor = '#1e8449'} onMouseOut={(e) => e.target.style.backgroundColor = '#27ae60'}>Pay with Stripe</button> */}
      </div>
    </Card.Body>
  </Card>       
</div>

                          )}

        </>)
    }
    </>
    
    );
}


const ProgressList = ({ children }) => <div>{children}</div>;

const LevelHeader = ({ children, onClick }) => (
  <div onClick={onClick} style={{ cursor: 'pointer' }}>
    {children}
  </div>
);
const LevelTitle = ({ children }) => <div>{children}</div>;
const LessonList = ({ children }) => <div>{children}</div>;
const LessonItem = ({ children }) => <div>{children}</div>;

const ChatList = ({ children   }) => <div>{children}</div>;
const ChatItem = ({ children }) => <div>{children}</div>;



export default Levels;









const RatingContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
`;

const LevelsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;



const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #2c3e50;
    padding: 5px 10px; /* Adds space around the icon */
`;

const ProgressItem = styled.div`
    padding: 10px 15px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        font-size: 1.2em;
        color: #2980b9;
        background-color: #f0f8ff;
    }
`;

const LevelCard = styled.div`
    background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease; /* Adds hover effect */

    &:hover {
        transform: translateY(-5px); /* Slight lift on hover */
    }
`;












const PostInfo = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;






const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;



const WorldsGrid = styled.div`

`;

const WorldCard = styled.div`

`;




const Publisher = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        color: #2c3e50;
        margin: 0;
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
`;

const Info = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

const Rating = styled.div`
    display: flex;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;



const GreenButton = styled.button`
    background-color: #27ae60 !important; /* Green color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #219150 !important; /* Darker green on hover */
    }
`;

const NavColorButton = styled.button`
    background-color: #2c3e50 !important; /* Navbar color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #1a252f !important; /* Darker navbar color on hover */
    }
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between rows */
`;


const MatesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive columns */
    gap: 20px;
    padding: 20px; /* Add padding to prevent overflow */
    box-sizing: border-box; /* Include padding in width calculation */
`;

const MateCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:hover {
        transform: translateY(-10px);
    }
`;

const WorldThumbnail = styled.img`
    width: 50px; /* Circle size */
    height: 50px;
    border-radius: 50%;
    object-fit: cover; /* Ensure proper image scaling */
    margin: 5px; /* Space between thumbnails */
`;





const MainContainer = styled(Container)`
  margin-left: 220px; /* Adjust to match Sidebar width */
  margin-top: 20px;
  padding: 20px; /* Optional: Add padding for better spacing */
`;


const FeedbackSection = styled.div`
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth resizing */

    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '150px' : '0')}; /* Adjust for Sidebar */
    width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')}; /* Width based on Sidebar */
    box-sizing: border-box;

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #2c3e50;
    }

    textarea {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: vertical;
    }

    button {
        background-color: #27ae60;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #1e8449;
        }
    }
`;



















































const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Ensure consistent box sizing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any potential margin */

  /* No media queries needed as it will always match the screen size */
`;






const ProfileWrapper = styled.div`
    position: relative;
      @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
      @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;





const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;




const ContentContainer = styled.div`
     display: flex;
     position: relative;
     width: 100%; /* Full width */
     min-height: calc(100vh - 60px); /* Adjust for navbar height */
     box-sizing: border-box;
     align-items: stretch; /* Ensure children stretch */
`;

;














const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #219150;
    }

        @media (max-width: 480px) {
        font-size: 14px; /* Reduce font size */
        padding: 8px 15px; /* Reduce padding */
    }
`;
        


const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }


`;







 const MainContent = styled.div`

     margin-top: 160px; /* Adjust for navbar + tab height */
     flex-grow: 1; /* Expand to fill available space */
     padding: 20px;
margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};

@media (max-width: 768px) {
    margin-left: 0; /* Remove sidebar margin */
    width: 100%; /* Use full width */
}
   display: flex;
     flex-direction: column;
     align-items: stretch; /* Ensure children stretch horizontally */
 `;












const SubTab = styled.div`
    flex: 1;
    text-align: center;
    padding: 8px 0; /* Reduce padding for shorter height */
    cursor: pointer;
    font-size: 16px; /* Slightly smaller font size */
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '2px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }

`;

 





const ContentWrapper = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 300px)' : '100%')};
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition */
  display: flex;
  flex-wrap: wrap; /* Ensure cards adjust dynamically */
  justify-content: space-between; /* Adjust cards in the available space */
`;


    const CardContainer = styled.div`


    `;

    const CardImage = styled.img`
    width: 100%; /* Ensure full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;

    @media (max-width: 768px) {
        height: 200px; /* Set a fixed height for better visuals */
    }
    `;





const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 5px 15px;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #34495e;
    padding: 15px 20px;

    a {
      display: block;
      margin: 10px 0;
    }
  }
`;



const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;













const TabContainer = styled.div
`  position: fixed;
  top: 60px; /* Set below the navbar */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 2px solid #ddd;
  box-shadow: 7 9px 11px rgba(0, 0, 0, 0.1);
  z-index: 950; /* Below the navbar */
  padding: 10px 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};

  
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0; /* Align to the screen */
      top: 74px; /* Add spacing to prevent overlap (adjusted from 120px) */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const SubTabContainer = styled.div
`  position: fixed; /* Fixed to stay visible while scrolling */
  top: 135px; /* Add spacing to prevent overlap (adjusted from 120px) */
  left: 300px; /* Align with the main content considering sidebar width */
  width: calc(100% - 300px); /* Stretch full width minus sidebar */
  display: flex;
  justify-content: center;
  background-color: #f8f9fa; /* Light gray for a clean look */
  border-bottom: 1px solid #ddd; /* Subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  z-index: 950; /* Stay above content but below navbar */
  padding: 5px 0; /* Reduced padding for a shorter height */
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};


  @media (max-width: 768px) {
    width: 100%;
      top: 139px; /* Add spacing to prevent overlap (adjusted from 120px) */
    left: 0; /* Align to the screen */
    padding: 0px 5px; /* Add some padding for a cleaner look */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling */
    justify-content: flex-start; /* Align items to the start */
  }
  `
;

const Sidebar = styled.div`
 width: 300px;
  position: fixed;
  top: 120px; /* Below navbar */
  left: 0;
  bottom: 0;
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  transition: width 0.3s ease;

  @media (max-width: 768px) {
    width: 250px;
    top: 120px;
  }

  @media (max-width: 480px) {
    width: 230px;
  }














    width: 300px;
    position: fixed;
    top: 120px; /* Below the navbar and tab container */
    left: 0;
    bottom: 0; /* Ensure full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Make content scrollable */
    z-index: 900; /* Below tab container and navbar */

    @media (max-width: 768px) {
        width: 250px; /* Narrower sidebar for small screens */
        top: 210px; /* Adjust for navbar */
    }

    @media (max-width: 480px) {
        width: 230; /* Sidebar takes full width on very small screens */
        position: absolute; /* Positioning to overlay */
        top: 210px; /* Adjust for navbar */
        bottom: 0; /* Ensure full height */
    }
`;

const ToggleSidebarButton = styled.div`
    position: fixed;
    top: 30%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for proper centering */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: all 0.3s ease-in-out;

    svg {
        color: #27ae60;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        left: 10px; /* Position away from the edge */
        top: 209px; /* Keep consistent with the sidebar */
        z-index: 1200; /* Ensure it stays above all elements */
        padding: 8px;
        border-radius: 50%; /* Circular button for modern look */
        background: #27ae60; /* Add contrast */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    }
`;


const NavIcons = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        font-size: 1.2rem;
        cursor: pointer;
    }
`;

const SearchContainer = styled.div`
    position: relative;

        @media (max-width: 768px) {
        max-width: 200px;
        padding-left: 150px;
        input {
            font-size: 12px;
        }
    }
`;

const SearchBar = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    top: 30px; /* Position below the icon */
    right: 0;
    max-width: 300px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
        flex: 1;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        max-width: 200px;

        input {
            font-size: 12px;
        }
    }
`;



const UploadButtonWrapper = styled.div`
  position: fixed;
  bottom: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;

const MessageChooseAdvisorButtonWrapper = styled.div`
  position: fixed;
  top: 70px; /* Place it at the bottom of the screen */
  right: 20px; /* Slightly away from the edge */
  z-index: 1000; /* Ensure it's above other elements */

  @media (max-width: 768px) {
    bottom: 65px; /* Adjust spacing for smaller screens */
    right: 15px;
  }
`;

















const SectionHeader = styled.div`
    font-family: 'Roboto', sans-serif; /* Better font */
    font-size: 16px;
    color: #333; /* Default text color */
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transitions */

    &:hover {
        color: #27ae60; /* Change text color on hover */
        font-size: 17px; /* Slightly increase size on hover */
        background-color: #e9f5ec; /* Add subtle background color on hover */
        border-radius: 5px; /* Rounded corners for hover effect */
    }
`;












const ToggleContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isOn }) => (isOn ? '#4caf50' : '#ccc')};
  transition: 0.4s;
  border-radius: 25px;

  &:before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: ${({ isOn }) => (isOn ? '26px' : '4px')};
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import styled, { keyframes } from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import { FaStar, FaLock, FaBell, FaComments, FaGraduationCap, FaCheck, FaChevronDown, FaChevronUp } from 'react-icons/fa';

// function Levels() {
//     const [levels, setLevels] = useState([]);
//     const [rate, setRate] = useState('');
//     const [comment, setComment] = useState('');
//     const [expandedLevel, setExpandedLevel] = useState(null);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const worldId = useSelector((state) => state.allow.worldId);
//     const worldName = useSelector((state) => state.allow.worldName);
//     const [isLevelCompeleted, setIsLevelCompleted] = useState(false); // get it from the backend
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [profilePictureSrc, setProfilePictureSrc] = useState('');
//     const [loading, setLoading] = useState(false);

//     const examId = uuidv4();

//     const handleChooseAdvisor = () => {
//         navigate('/choose-advisor'); // Navigate to the advisor selection page
//     };

//     const handleMessageAdvisor = () => {
//         navigate('/message-advisor');
//     };

//     useEffect(() => {
//         const setLevelCompleted = async () => {
//             const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', { worldId });
//             setIsLevelCompleted(response.data);
//             console.log('isLevelCompleted:', response.data);
//         };

//         setLevelCompleted();
//     }, [worldId]);

//     useEffect(() => {
//         const fetchLevels = async () => {
//             setLoading(true);
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getLevels', {
//                     params: { worldId },
//                 });
//                 setLevels(response.data);
//             } catch (error) {
//                 console.error('Error fetching levels:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchLevels();
//     }, [worldId]);

//     const handleShowLevel = (levelNumber, id) => {
//         dispatch(setLevelId(id));
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/lessons');
//     };

//     const handleLevelShowDescription = (levelNumber) => {
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/level-description');
//     };

//     const handleRateWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
//         } catch (error) {
//             console.error('Error rating world:', error);
//         }
//     };

//     const addCommentToTheWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
//                 commentId: uuidv4(),
//                 comment,
//                 worldId,
//             });
//         } catch (error) {
//             console.error('Error adding comment:', error);
//         }
//     };

//     const handleStartExam = async (levelId) => {
//         dispatch(setExamId(examId));
//         await axios.post('https://sell-skill.com/api/endpoints/startExam', {
//             levelId,
//             worldId,
//             examId,
//         });
//     };

//     const toggleLevel = (levelId) => {
//         setExpandedLevel(expandedLevel === levelId ? null : levelId);
//     };

//     return (
//         <PageContainer>
//             <button onClick={() => navigate('/worlds')}>Go Back</button>
//             <Navbar>
//                 <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                 <NavLinks>
//                     <a href="/my-learning">Message Advisor</a>
//                     <a href="/my-earning">My Students</a>
//                     <a href="/my-cart">Posts</a>
//                     <a href="/certifications">Certifications</a>
//                     <a href="/exams">Exams</a>
//                 </NavLinks>

//                 <NavIcons>
//                     <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
//                     <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
//                     <ProfileWrapper
//                         onMouseEnter={() => setShowDropdown(true)}
//                         onMouseLeave={() => setShowDropdown(false)}
//                     >
//                         <ProfilePicture
//                             src={profilePictureSrc || '/images/default-profile.jpg'}
//                             alt="Profile"
//                         />
//                         {showDropdown && (
//                             <DropdownMenu>
//                                 <a href="/my-profile">My Profile</a>
//                                 <a href="/my-learning">My Learning</a>
//                                 <a href="/my-earning">My Earning</a>
//                                 <a href="/my-cart">My Cart</a>
//                                 <a href="/certifications">Certifications</a>
//                                 <a href="/exams">Exams</a>
//                             </DropdownMenu>
//                         )}
//                     </ProfileWrapper>
//                 </NavIcons>
//             </Navbar>

//             <MainContent>
//                 <Sidebar>
//                     <h2>My Progress</h2>
//                     {loading ? (
//                         <p>Loading...</p>
//                     ) : (
//                         <ProgressList>
//                             {levels.map((level) => (
//                                 <ProgressItem key={level._id}>
//                                     <LevelHeader onClick={() => toggleLevel(level._id)}>
//                                         <LevelTitle>
//                                             {level.isLevelCompleted ? <FaCheck color="green" /> : <FaLock color="red" />}
//                                             {level.levelTitle}
//                                         </LevelTitle>
//                                         {expandedLevel === level._id ? <FaChevronUp /> : <FaChevronDown />}
//                                     </LevelHeader>
//                                     {expandedLevel === level._id && (
//                                         <LessonList>
//                                             {level.lessons.map((lesson) => (
//                                                 <LessonItem key={lesson._id}>
//                                                     {lesson.isLessonCompleted ? (
//                                                         <FaCheck color="green" />
//                                                     ) : (
//                                                         <FaLock color="red" />
//                                                     )}
//                                                     {lesson.lessonTitle}
//                                                 </LessonItem>
//                                             ))}
//                                         </LessonList>
//                                     )}
//                                 </ProgressItem>
//                             ))}
//                         </ProgressList>
//                     )}
//                 </Sidebar>
//                 <LevelsGrid>
//                     {levels.map((level) => (
//                         <LevelCard key={level._id} isOpen={level.isOpen}>
//                             <h2>{level.levelTitle}</h2>
//                             <p>{level.description}</p>
//                             <Actions>
//                                 <button
//                                     onClick={() => handleShowLevel(level.levelNumber, level._id)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     Get In
//                                 </button>
//                                 <button
//                                     onClick={() => handleLevelShowDescription(level.levelNumber)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     View Description
//                                 </button>
//                                 <button
//                                     onClick={() => handleStartExam(level._id)}
//                                     disabled={!level.isLevelCompleted}
//                                 >
//                                     Take Exam
//                                 </button>
//                             </Actions>
//                         </LevelCard>
//                     ))}
//                 </LevelsGrid>
//             </MainContent>
//         </PageContainer>
//     );
// }

// export default Levels;

// // Styled Components
// const PageContainer = styled.div`
//     display: flex;
//     flex-direction: column;
// `;

// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// `;

// const NavIcons = styled.div`
//     display: flex;
//     gap: 15px;
// `;

// const ProfileWrapper = styled.div`
//     position: relative;
// `;

// const ProfilePicture = styled.img`
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
// `;

// const DropdownMenu = styled.div`
//     position: absolute;
//     top: 50px;
//     right: 0;
//     background-color: white;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//     width: 200px;
// `;

// const Sidebar = styled.div`
//     width: 300px;
//     background-color: #f4f4f4;
//     padding: 20px;
//     border-right: 1px solid #ddd;
// `;

// const ProgressList = styled.ul`
//     list-style: none;
//     padding: 0;
// `;

// const ProgressItem = styled.li`
//     margin-bottom: 10px;
// `;

// const LevelHeader = styled.div`
//     display: flex;
//     justify-content: space-between;
//     cursor: pointer;
//     padding: 10px;
//     background-color: #ddd;
//     border-radius: 5px;
// `;

// const LevelTitle = styled.span`
//     display: flex;
//     align-items: center;
//     gap: 10px;
// `;

// const LessonList = styled.ul`
//     list-style: none;
//     padding-left: 20px;
// `;

// const LessonItem = styled.li`
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     padding: 5px 0;
// `;

// const LevelsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const LevelCard = styled.div`
//     background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
//     border-radius: 10px;
//     padding: 20px;
// `;

// const Actions = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 10px;

//     button {
//         background-color: #2980b9;
//         color: white;
//         border: none;
//         padding: 10px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1f618d;
//         }

//         &:disabled {
//             background-color: #bdc3c7;
//             cursor: not-allowed;
//         }
//     }
// `;

// const MainContent = styled.div`
//     flex: 1;
//     padding: 20px;
// `;

































// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import styled, { keyframes } from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import { FaStar, FaLock, FaBell, FaComments, FaGraduationCap } from 'react-icons/fa';

// function Levels() {
//     const [levels, setLevels] = useState([]);
//     const [rate, setRate] = useState('');
//     const [comment, setComment] = useState('');
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const worldId = useSelector((state) => state.allow.worldId);
//     const worldName = useSelector((state) => state.allow.worldName);
//     const [isLevelCompeleted, setIsLevelCompleted] =useState(false)//get it from the back
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [profilePictureSrc, setProfilePictureSrc] = useState('');
//     const [loading, setLoading]  = useState(false)

//     const examId = uuidv4();
//     const handleChooseAdvisor = () => {
//         navigate('/choose-advisor') // he will get all the advisors and he can select to choose
//     }
//     const handleMessageAdvisor = () => {
//         navigate('/message-advisor')
//     }

//  useEffect(() => {

//      const setLevelCompleted = async () => { 

//          const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', {
//            worldId
//          });

//            setIsLevelCompleted(response.data);
//            console.log('isLevelCompleted coming from back ============--------------=========--------->> >>  > ', response.data);
        

//      };
  
//      setLevelCompleted();
  

//    }, []);


//    useEffect(() => {
//     const fetchLevels = async () => {
//         setLoading(true); // Start loading
//         try {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/getLevels', {
//                 params: { worldId },
//             });
//             setLevels(response.data);
//         } catch (error) {
//             console.error('Error fetching levels:', error);
//         } finally {
//             setLoading(false); // Stop loading
//         }
//     };

//     fetchLevels();
// }, [worldId]);

//     const handleShowLevel = (levelNumber, id) => {
//         dispatch(setLevelId(id));
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/lessons');
//     };

//     const handleLevelShowDescription = (levelNumber) => {
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/level-description');
//     };

//     const handleRateWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
//         } catch (error) {
//             console.error('Error rating world:', error);
//         }
//     };

//     const addCommentToTheWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
//                 commentId: uuidv4(),
//                 comment,
//                 worldId,
//             });
//         } catch (error) {
//             console.error('Error adding comment:', error);
//         }
//     };


//       const handleStartExam = async(levelId) => {
//    dispatch(setExamId(examId))
//     await axios.post('https://sell-skill.com/api/endpoints/startExam', {
//          levelId,
//          worldId,//useSelector
//          examId //uuidv4
//      })
//  }
//     return (
//         <PageContainer>
//             <button onClick={() => navigate('/worlds')}> go back </button>
//             <Navbar>
//                 <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                 <NavLinks>
//                     <a>my mates</a>
//                     <a href="/my-learning">message advisor</a>
//                     <a href="/my-earning">My students</a>
//                     <a href="/my-cart">posts</a>
//                     <a href="/certifications">Certifications</a>
//                     <a href="/exams">Exams</a>
//                 </NavLinks>

//                 <NavIcons>
//                     <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
//                     <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
//                     <ProfileWrapper
//                         onMouseEnter={() => setShowDropdown(true)}
//                         onMouseLeave={() => setShowDropdown(false)}
//                     >
//                         <ProfilePicture
//                             src={profilePictureSrc || '/images/default-profile.jpg'}
//                             alt="Profile"
//                         />
//                         {showDropdown && (
//                             <DropdownMenu>
//                                 <a href="/my-profile">My Profile</a>
//                                 <a href="/my-learning">My Learning</a>
//                                 <a href="/my-earning">My Earning</a>
//                                 <a href="/my-cart">My Cart</a>
//                                 <a href="/certifications">Certifications</a>
//                                 <a href="/exams">Exams</a>
//                             </DropdownMenu>
//                         )}
//                     </ProfileWrapper>
//                 </NavIcons>
//             </Navbar>
// {loading ? ( <LoadingContainer>
//                     <LoadingSpinner />
//                 </LoadingContainer>):(            <Content>
//                 <LevelsGrid>
//                     {levels.map((level, index) => (
//                         <LevelCard key={index} isOpen={level.isOpen}>
//                             <h2>{level.levelTitle}</h2>
//                             <p>{level.description}</p>
//                             {level.toBeAnAdvisorLevel && (
//     <FaGraduationCap size={50} color="#2c3e50" style={{ marginBottom: '10px' }} />
// )}
//                             {!level.isOpen && (
//                                 <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//                                     <FaLock size={20} color="#e74c3c" />
//                                     <span>Locked</span>
//                                 </div>
//                             )}
//                             <Actions>
//                                 <button
//                                     onClick={() => handleShowLevel(level.levelNumber, level._id)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     Get In
//                                 </button>
//                                 <button
//                                     onClick={() => handleLevelShowDescription(level.levelNumber)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     View Description
//                                 </button>
//                                 {
//                                     level.myCurrentAdvisorId ?                                  
//                                     <button onClick={handleMessageAdvisor} disabled={level.isPassed || !level.isOpen}> 
//                                     message advisor
//                                    </button> :                          
//                                     <button onClick={handleChooseAdvisor} disabled={!level.isOpen}>
//                                   add advisor
//                                  </button> 
//                                 }


//                                 <button
//                                     onClick={() => handleStartExam( level._id)}
//                                     disabled={!level.isLevelCompleted }
//                                 >
//                                     Take Exam
//                                 </button>
//                             </Actions>
//                         </LevelCard>
//                     ))}
//                 </LevelsGrid>
//                 <FeedbackSection>
//                     <h2>Rate and Comment on the World</h2>
//                     <RatingContainer>
//     {[1, 2, 3, 4, 5].map((star) => (
//         <FaStar
//             key={star}
//             size={24}
//             color={star <= rate ? "#f1c40f" : "#bdc3c7"}
//             onClick={() => setRate(star)}
//             style={{ cursor: "pointer" }}
//         />
//     ))}
// </RatingContainer>
// <button onClick={handleRateWorld}>Submit Rating</button>

//                     <textarea
//                         placeholder="Enter your comment"
//                         onChange={(e) => setComment(e.target.value)}
//                     ></textarea>
//                     <button onClick={addCommentToTheWorld}>Add Comment</button>
//                 </FeedbackSection>
//             </Content>)}
//         </PageContainer>
//     );
// }

// export default Levels;

// // Styled Components
// const PageContainer = styled.div`
//     background-color: #f8f9fa;
//     min-height: 100vh;
//     padding: 20px;
// `;

// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// `;

// const Content = styled.div`
//     max-width: 1200px;
//     margin: 0 auto;
//     padding: 20px;

//     h1 {
//         font-size: 32px;
//         margin-bottom: 20px;
//         color: #2c3e50;
//     }
// `;

// const LevelsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const LevelCard = styled.div`
//     background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
//     border-radius: 10px;
//     padding: 20px;
//     box-shadow: ${(props) => (props.isOpen ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none')};
//     transition: transform 0.3s ease;
//     opacity: ${(props) => (props.isOpen ? '1' : '0.6')};

//     &:hover {
//         transform: ${(props) => (props.isOpen ? 'translateY(-10px)' : 'none')};
//     }

//     h2 {
//         font-size: 20px;
//         margin-bottom: 10px;
//         color: ${(props) => (props.isOpen ? '#2c3e50' : '#7f8c8d')};
//     }

//     p {
//         font-size: 14px;
//         color: #7f8c8d;
//         margin-bottom: 10px;
//     }
// `;

// const Actions = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 10px;

//     button {
//         background-color: #2980b9;
//         color: white;
//         border: none;
//         padding: 10px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1f618d;
//         }

//         &:disabled {
//             background-color: #bdc3c7;
//             cursor: not-allowed;
//         }
//     }
// `;

// const FeedbackSection = styled.div`
//     margin-top: 30px;

//     h2 {
//         margin-bottom: 20px;
//         font-size: 24px;
//         color: #2c3e50;
//     }

//     input,
//     textarea {
//         display: block;
//         width: 100%;
//         margin-bottom: 10px;
//         padding: 10px;
//         border: 1px solid #ccc;
//         border-radius: 5px;
//     }

//     button {
//         background-color: #27ae60;
//         color: white;
//         border: none;
//         padding: 10px 20px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1e8449;
//         }
//     }
// `;
// const RatingContainer = styled.div`
//     display: flex;
//     gap: 10px;
//     margin-bottom: 10px;
// `;



// const DropdownMenu = styled.div`
//     position: absolute;
//     top: 50px;
//     right: 0;
//     background-color: white;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//     width: 200px;
//     z-index: 10;
//     display: flex;
//     flex-direction: column;

//     a {
//         padding: 10px 15px;
//         color: black;
//         text-decoration: none;

//         &:hover {
//             background-color: #f0f0f0;
//         }
//     }
// `;




// const ProfileWrapper = styled.div`
//     position: relative;
// `;

// const ProfilePicture = styled.img`
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
// `;

// const NavIcons = styled.div`
//     display: flex;
//     gap: 15px;
// `;




// const spin = keyframes`
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
// `;


// const LoadingSpinner = styled.div`
//     border: 6px solid #f3f3f3; /* Light grey border */
//     border-top: 6px solid #3498db; /* Blue color for the top border */
//     border-radius: 50%; /* Makes it circular */
//     width: 50px; /* Width of the spinner */
//     height: 50px; /* Height of the spinner */
//     animation: ${spin} 1s linear infinite; /* Applies the spinning animation */
// `;
// const LoadingContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     min-height: 300px; /* Ensures enough space for the spinner */
// `;










// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import { FaStar, FaLock } from 'react-icons/fa';
  
// function Levels() {
//     const [levels, setLevels] = useState([]);
//     const [rate, setRate] = useState('');
//     const [comment, setComment] = useState('');
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const worldId = useSelector((state) => state.allow.worldId);
//     const worldName = useSelector((state) => state.allow.worldName);
//     const [isLevelCompeleted, setIsLevelCompleted] =useState(false)//get it from the back
//     const examId = uuidv4();
//     const handleChooseAdvisor = () => {
//         navigate('/choose-advisor') // he will get all the advisors and he can select to choose
//     }
//     const handleMessageAdvisor = () => {
//         navigate('/message-advisor')
//     }

//  useEffect(() => {

//      const setLevelCompleted = async () => { 

//          const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', {
//            worldId
//          });

//            setIsLevelCompleted(response.data);
//            console.log('isLevelCompleted coming from back ============--------------=========--------->> >>  > ', response.data);
        

//      };
  
//      setLevelCompleted();
  

//    }, []);


//     useEffect(() => {
//         const fetchLevels = async () => {
//             try {
//                 const response = await axios.get(`https://sell-skill.com/api/endpoints/getLevels`, {
//                     params: { worldId },
//                 });
//                 setLevels(response.data);
//             } catch (error) {
//                 console.error('Error fetching levels:', error);
//             }
//         };

//         fetchLevels();
//     }, [worldId]);

//     const handleShowLevel = (levelNumber, id) => {
//         dispatch(setLevelId(id));
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/lessons');
//     };

//     const handleLevelShowDescription = (levelNumber) => {
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/level-description');
//     };

//     const handleRateWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
//         } catch (error) {
//             console.error('Error rating world:', error);
//         }
//     };

//     const addCommentToTheWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
//                 commentId: uuidv4(),
//                 comment,
//                 worldId,
//             });
//         } catch (error) {
//             console.error('Error adding comment:', error);
//         }
//     };


//       const handleStartExam = async(levelId) => {
//    dispatch(setExamId(examId))
//     await axios.post('https://sell-skill.com/api/endpoints/startExam', {
//          levelId,
//          worldId,//useSelector
//          examId //uuidv4
//      })
//  }
//     return (
//         <PageContainer>
//             <Navbar>
//                 <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                 <NavLinks>
//                     <a href="/my-learning">message advisor</a>
//                     <a href="/my-earning">My students</a>
//                     <a href="/my-cart">posts</a>
//                     <a href="/certifications">Certifications</a>
//                     <a href="/exams">Exams</a>
//                 </NavLinks>
//             </Navbar>
//             <Content>
//                 <LevelsGrid>
//                     {levels.map((level, index) => (
//                         <LevelCard key={index} isOpen={level.isOpen}>
//                             <h2>{level.levelTitle}</h2>
//                             <p>{level.description}</p>
//                             <p>Advisor Level: {level.toBeAnAdvisorLevel ? 'Yes' : 'No'}</p>
//                             {!level.isOpen && (
//                                 <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//                                     <FaLock size={20} color="#e74c3c" />
//                                     <span>Locked</span>
//                                 </div>
//                             )}
//                             <Actions>
//                                 <button
//                                     onClick={() => handleShowLevel(level.levelNumber, level._id)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     Get In
//                                 </button>
//                                 <button
//                                     onClick={() => handleLevelShowDescription(level.levelNumber)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     View Description
//                                 </button>
//                                 {
//                                     level.myCurrentAdvisorId ?                                  
//                                     <button onClick={handleMessageAdvisor}> 
//                                     message advisor
//                                    </button> :                          
//                                     <button onClick={handleChooseAdvisor}>
//                                   add advisor
//                                  </button> 
//                                 }


//                                 <button
//                                     onClick={() => handleStartExam( level._id)}
//                                     disabled={!level.isLevelCompleted }
//                                 >
//                                     Take Exam
//                                 </button>
//                             </Actions>
//                         </LevelCard>
//                     ))}
//                 </LevelsGrid>
//                 <FeedbackSection>
//                     <h2>Rate and Comment on the World</h2>
//                     <input
//                         type="number"
//                         placeholder="Enter your rating (1-5)"
//                         onChange={(e) => setRate(e.target.value)}
//                     />
//                     <button onClick={handleRateWorld}>Submit Rating</button>
//                     <textarea
//                         placeholder="Enter your comment"
//                         onChange={(e) => setComment(e.target.value)}
//                     ></textarea>
//                     <button onClick={addCommentToTheWorld}>Add Comment</button>
//                 </FeedbackSection>
//             </Content>
//         </PageContainer>
//     );
// }

// export default Levels;

// // Styled Components
// const PageContainer = styled.div`
//     background-color: #f8f9fa;
//     min-height: 100vh;
//     padding: 20px;
// `;

// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// `;

// const Content = styled.div`
//     max-width: 1200px;
//     margin: 0 auto;
//     padding: 20px;

//     h1 {
//         font-size: 32px;
//         margin-bottom: 20px;
//         color: #2c3e50;
//     }
// `;

// const LevelsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const LevelCard = styled.div`
//     background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
//     border-radius: 10px;
//     padding: 20px;
//     box-shadow: ${(props) => (props.isOpen ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none')};
//     transition: transform 0.3s ease;
//     opacity: ${(props) => (props.isOpen ? '1' : '0.6')};

//     &:hover {
//         transform: ${(props) => (props.isOpen ? 'translateY(-10px)' : 'none')};
//     }

//     h2 {
//         font-size: 20px;
//         margin-bottom: 10px;
//         color: ${(props) => (props.isOpen ? '#2c3e50' : '#7f8c8d')};
//     }

//     p {
//         font-size: 14px;
//         color: #7f8c8d;
//         margin-bottom: 10px;
//     }
// `;

// const Actions = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 10px;

//     button {
//         background-color: #2980b9;
//         color: white;
//         border: none;
//         padding: 10px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1f618d;
//         }

//         &:disabled {
//             background-color: #bdc3c7;
//             cursor: not-allowed;
//         }
//     }
// `;

// const FeedbackSection = styled.div`
//     margin-top: 30px;

//     h2 {
//         margin-bottom: 20px;
//         font-size: 24px;
//         color: #2c3e50;
//     }

//     input,
//     textarea {
//         display: block;
//         width: 100%;
//         margin-bottom: 10px;
//         padding: 10px;
//         border: 1px solid #ccc;
//         border-radius: 5px;
//     }

//     button {
//         background-color: #27ae60;
//         color: white;
//         border: none;
//         padding: 10px 20px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1e8449;
//         }
//     }
// `;












// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import { FaStar } from 'react-icons/fa';

// function Levels() {
//     const [levels, setLevels] = useState([]);
//     const [rate, setRate] = useState('');
//     const [comment, setComment] = useState('');
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const worldId = useSelector((state) => state.allow.worldId);
//     const worldName = useSelector((state) => state.allow.worldName);

//     const examId = uuidv4();

//     useEffect(() => {
//         const fetchLevels = async () => {
//             try {
//                 const response = await axios.get(`https://sell-skill.com/api/endpoints/getLevels`, {
//                     params: { worldId },
//                 });
//                 setLevels(response.data);
//             } catch (error) {
//                 console.error('Error fetching levels:', error);
//             }
//         };

//         fetchLevels();
//     }, [worldId]);

//     const handleShowLevel = (levelNumber, id) => {
//         dispatch(setLevelId(id));
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/lessons');
//     };

//     const handleLevelShowDescription = (levelNumber) => {
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/level-description');
//     };

//     const handleRateWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
//         } catch (error) {
//             console.error('Error rating world:', error);
//         }
//     };

//     const addCommentToTheWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
//                 commentId: uuidv4(),
//                 comment,
//                 worldId,
//             });
//         } catch (error) {
//             console.error('Error adding comment:', error);
//         }
//     };

//     return (
//         <PageContainer>
//             <Navbar>
//                 <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                 <NavLinks>
//                     <a href="/my-learning">message advisor</a>
//                     <a href="/my-earning">My students</a>
//                     <a href="/my-cart">posts</a>
//                     <a href="/certifications">Certifications</a>
//                     <a href="/exams">Exams</a>
//                 </NavLinks>
//             </Navbar>
//             <Content>

//                 <LevelsGrid>
//                     {levels.map((level, index) => (
//                         <LevelCard key={index}>
//                             <h2>{level.levelTitle}</h2>
//                             <p>{level.description}</p>
//                             <p>Advisor Level: {level.toBeAnAdvisorLevel ? 'Yes' : 'No'}</p>
//                             <Actions>
//                                 <button onClick={() => handleShowLevel(level.levelNumber, level._id)}>Get In</button>
//                                 <button onClick={() => handleLevelShowDescription(level.levelNumber)}>View Description</button>

//                                 <button
//                                     onClick={() => dispatch(setExamId(examId)) && navigate('/exam')}
//                                     disabled={!level.isLevelCompleted}
//                                 >
//                                     Take Exam
//                                 </button>
//                             </Actions>
//                         </LevelCard>
//                     ))}
//                 </LevelsGrid>
//                 <FeedbackSection>
//                     <h2>Rate and Comment on the World</h2>
//                     <input
//                         type="number"
//                         placeholder="Enter your rating (1-5)"
//                         onChange={(e) => setRate(e.target.value)}
//                     />
//                     <button onClick={handleRateWorld}>Submit Rating</button>
//                     <textarea
//                         placeholder="Enter your comment"
//                         onChange={(e) => setComment(e.target.value)}
//                     ></textarea>
//                     <button onClick={addCommentToTheWorld}>Add Comment</button>
//                 </FeedbackSection>
//             </Content>
//         </PageContainer>
//     );
// }

// export default Levels;

// // Styled Components
// const PageContainer = styled.div`
//     background-color: #f8f9fa;
//     min-height: 100vh;
//     padding: 20px;
// `;

// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// `;

// const Content = styled.div`
//     max-width: 1200px;
//     margin: 0 auto;
//     padding: 20px;

//     h1 {
//         font-size: 32px;
//         margin-bottom: 20px;
//         color: #2c3e50;
//     }
// `;

// const LevelsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const LevelCard = styled.div`
//     background-color: white;
//     border-radius: 10px;
//     padding: 20px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     transition: transform 0.3s ease;

//     &:hover {
//         transform: translateY(-10px);
//     }

//     h2 {
//         font-size: 20px;
//         margin-bottom: 10px;
//         color: #2c3e50;
//     }

//     p {
//         font-size: 14px;
//         color: #7f8c8d;
//         margin-bottom: 10px;
//     }
// `;

// const Actions = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 10px;

//     button {
//         background-color: #2980b9;
//         color: white;
//         border: none;
//         padding: 10px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1f618d;
//         }

//         &:disabled {
//             background-color: #bdc3c7;
//             cursor: not-allowed;
//         }
//     }
// `;

// const FeedbackSection = styled.div`
//     margin-top: 30px;

//     h2 {
//         margin-bottom: 20px;
//         font-size: 24px;
//         color: #2c3e50;
//     }

//     input,
//     textarea {
//         display: block;
//         width: 100%;
//         margin-bottom: 10px;
//         padding: 10px;
//         border: 1px solid #ccc;
//         border-radius: 5px;
//     }

//     button {
//         background-color: #27ae60;
//         color: white;
//         border: none;
//         padding: 10px 20px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1e8449;
//         }
//     }
// `;































// //To show levels Thumbnails number and title


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { Card } from 'react-bootstrap';
// import { v4 as uuidv4 } from 'uuid';
  
// function Levels() { // Pass skillerId as a prop
//   const [levels, setLevels] = useState([]);
//   const [levellId, setLevellId] = useState('')
//   const [isPaidStatus, setIsPaidStatus] = useState(false)
//   const [worldPrice, setWorldPrice] = useState('')
//   const [advisorId, setAdvisorId] =useState('')//get advisor id from backend
//   const [rate, setRate] = useState('')
//   const [comment, setComment] = useState('')
//   const [isLevelCompeleted, setIsLevelCompleted] =useState(false)//get it from the back
//   const worldId = useSelector((state) => state.allow.worldId)
//   console.log('worldId from frontend ============----------------->>> ',worldId)
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const worldName = useSelector((state) => state.allow.worldName)

//   const examId = uuidv4();
//   const rateId = uuidv4();
//   const commentId = uuidv4();
//   console.log('world name from levels =====> ',worldName)

//   const handleAddLevel = () => {
//     dispatch(setWorldName(worldName))
//     navigate('/add-level')
//   }

//   const logIsLevelCompleted = () => {
//     console.log('isLevelCompleted ==========------------------------>>> > >>> ',isLevelCompeleted)
//   }
  
//   const handleStartExam = async(levelId) => {
//     dispatch(setExamId(examId))
//     await axios.post('https://sell-skill.com/api/endpoints/startExam', {
//         levelId,
//         worldId,//useSelector
//         examId //uuidv4
//     })
// }

//   // useEffect(() => {
//   //   const getWorldPrice = async () => {
//   //     const response = await axios.get('https://sell-skill.com/api/endpoints/getPrice', worldId)
//   //     setWorldPrice(response.data)
//   //   }
//   //   getWorldPrice()
//   // }, [])
//   useEffect(() => {
//     const fetchLevels = async () => {
//       try {
//         const response = await axios.get(`https://sell-skill.com/api/endpoints/getLevels`, {
//           params: { worldId } // Pass worldName as a query parameter
//         });
//         setLevels(response.data);
//       } catch (error) {
//         console.error("Error fetching worlds:", error);
//       }
//     };
  

//       fetchLevels();
    
//   }, []);
  
//   const handleShowLevel=  (levelNumber, id) => {
//     console.log('levelId ======================> >> > > > >> >> ',id)
//     dispatch(setLevelId(id))
//     setLevelId(id)
//     dispatch(setLevelNumber(levelNumber))
//     navigate ('/lessons')
//   }
//   const handleLevelShowDescription = (levelNumber) => {
//     dispatch(setLevelNumber(levelNumber))
//     // use useSelector to get this name in the edit world route => send the name to back, then send info to front
//     navigate('/level-description')
    
//   }
//   useEffect(() => {

//     const setLevelCompleted = async () => { 

//         const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', {
//           worldId
//         });

//           setIsLevelCompleted(response.data);
//           console.log('isLevelCompleted coming from back ============--------------=========--------->> >>  > ', response.data);
        

//     };
  
//     setLevelCompleted();
  

//   }, []);
  

//   const handleRateWorld = async() => {
//     await axios.post('https://sell-skill.com/api/endpoints/rateWorld', {rateId, rate, worldId})
//   }
//   const addCommentToTheWorld = async() => {
//     await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {commentId, comment, worldId})
//   }
//   // useEffect(() => {
//   //   // Load PayPal Script
//   //   const addPayPalScript = () => {
//   //     if (window.paypal) {
//   //       renderPayPalButton();
//   //     } else {
//   //       const script = document.createElement('script');
        
//   //       // PayPal SDK script to include both PayPal and card payments (no funding sources are disabled)
//   //       script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;


//   //       script.type = 'text/javascript';
//   //       script.async = true;
//   //       script.onload = renderPayPalButton;
//   //       document.body.appendChild(script);
//   //     }
//   //   };

//   //   const renderPayPalButton = () => {
//   //     window.paypal.Buttons({
//   //       // No funding source restriction, so both PayPal and card will be available
//   //       createOrder: (data, actions) => {
//   //         return actions.order.create({
//   //           purchase_units: [
//   //             {
//   //               amount: {
//   //                 value: '9.00', // Replace with your actual amount
//   //               },
//   //             },
//   //           ],
//   //         });
//   //       },
//   //       onApprove: async (data, actions) => {
//   //         return actions.order.capture().then(async (details) => {
//   //           alert(`Transaction completed by ${details.payer.name.given_name}`);
//   //           try {
//   //             await axios.patch('https://sell-skill.com/api/endpoints/paid', {
//   //               params: {worldId}
//   //             });
//   //             setIsPaidStatus(true);

//   //           } catch (error) {
//   //             console.error('Error updating payment status: ', error);
//   //           }
//   //           window.location.reload();
//   //         });
//   //       },        
//   //       onError: (err) => {
//   //         console.error('PayPal Checkout Error: ', err);
//   //         alert('An error occurred during the transaction. Please try again.');
//   //       },
//   //     }).render('#paypal-button-container');
//   //   };

//   //   addPayPalScript();
//   // }, []);

//   // useEffect(() => {
//   //   const checkIsPaid = async() => {
//   //     const response = await axios.get('https://sell-skill.com/api/endpoints/isPaid', {
//   //       params: {worldId}
//   //     });
//   //     setIsPaidStatus(response.data.isPaid);
//   //   }
//   //   checkIsPaid();

//   //   if (window.fbq) {
//   //     window.fbq('track', 'Purchase', {
//   //       content_name: 'Thank You Page Lead',
//   //       value: 0,
//   //       currency: 'USD'
//   //     });
//   //   }
//   // }, []);

//   return (
//     <div>
//       <button onClick={logIsLevelCompleted}>log is level completed</button>
//      <><h1>My Levels</h1>
//      <button onClick={() => navigate('/choose-advisor')}>chat</button>
//      <button>nav bar exams</button>
//      <button>public feeds </button>
//      <button>nav bar certifications</button>
//      <button>nav bar message advisor</button>
//      <button>navbar my earnings</button>
//      <button>nav bar my students</button>
//       <div className="worlds-container">

//         {levels.map((level, index) => (
//           <div key={index} className="world-card">
//             <>{console.log('level from inside ================ > ---------> ',level)}</>
//             <h2>{level.name}</h2>
//             <h2>{level.levelTitle}</h2>
//             <p>{level.description}</p>
//             <h2>toBeAnAdvisorLevel {level.toBeAnAdvisorLevel}</h2>
//             <button onClick={()=>handleShowLevel(level.name, level._id)}>Get in</button>
//             <button onClick={()=>handleLevelShowDescription(level.name)}>view description</button>
//             <button onClick = {() => handleStartExam(level._id) }
//              disabled={!level.isLevelCompleted}
//             >take the exam</button>
//           </div>
//         ))}
//       </div></>


//       <input 
//       placeholder='enter rate'
//       onChange={(e)=> setRate(e.target.value)}
//       />
//       <button onClick ={handleRateWorld}>rate the world</button>
//       <input 
//       placeholder = 'enter comment'
//       onChange={ (e) => setComment(e.target.value)}
//       />
//       <button onClick={addCommentToTheWorld}>add comment about the world</button>
//     </div>
//   );
// }

// export default Levels;


