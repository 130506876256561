import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #f8f9fa; /* Light gray background */
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #2c3e50; /* Dark color */
  font-size: 1.8rem;
  margin-bottom: 20px;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  color: #27ae60; /* Green color */
  font-size: 1.5rem;
  margin: 20px 0 10px;
`;

const ContentList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ContentItem = styled.li`
  margin: 10px 0;
  a {
    color: #3498db; /* Link color */
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

function Achievement() {
  const achievementId = useSelector((state) => state.allow.achievementId);
 
  const [achievementDetails, setAchievementDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAchievement = async () => {
      try {
        const response = await axios.get(
          'https://sell-skill.com/api/endpoints/getRealWorldResult',
          { params: { achievementId } }
        );
        setAchievementDetails(response.data);
      } catch (error) {
        console.error('Error fetching achievement details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAchievement();
  }, [achievementId]);

  if (loading) {
    return <Container>Loading...</Container>;
  }

  if (!achievementDetails) {
    return <Container>No achievement details found.</Container>;
  }

  const { title, thumbnail, photos, videos, pdfs } = achievementDetails;

  return (
    <Container>
      <Title>{title}</Title>
      {thumbnail && <Thumbnail src={thumbnail} alt="Achievement Thumbnail" />}
      <SectionTitle>Photos</SectionTitle>
      <ContentList>
        {photos.map((photo) => (
          <ContentItem key={photo.contentUrl}>
            <a href={photo.contentUrl} target="_blank" rel="noopener noreferrer">
              View Photo
            </a>
          </ContentItem>
        ))}
      </ContentList>
      <SectionTitle>Videos</SectionTitle>
      <ContentList>
        {videos.map((video) => (
          <ContentItem key={video.contentUrl}>
            <a href={video.contentUrl} target="_blank" rel="noopener noreferrer">
              Watch Video
            </a>
          </ContentItem>
        ))}
      </ContentList>
      <SectionTitle>PDFs</SectionTitle>
      <ContentList>
        {pdfs.map((pdf) => (
          <ContentItem key={pdf.contentUrl}>
            <a href={pdf.contentUrl} target="_blank" rel="noopener noreferrer">
              View PDF
            </a>
          </ContentItem>
        ))}
      </ContentList>
    </Container>
  );
}

export default Achievement;
