import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TP.css'; // External CSS for enhanced styling

const TPTSWEBDEV = () => {
  const navigate = useNavigate();


  useEffect(() => {
    const pixelIds = ['1669785423607093', '620286700362285', '1580943482786881'];
  
    if (window.fbq) {
      // Initialize all Pixel IDs
      pixelIds.forEach((pixelId) => {
        window.fbq('init', pixelId);
      });
  
      // Track the 'PageView' event once
      window.fbq('track', 'PageView', {
        content_name: 'Landing Page view',
        value: 0,
        currency: 'USD',
      });
    }
  }, []);
  
  return (
<div className="tp-container">
  {/* Background animation */}
  <div className="tp-background"></div>

  {/* Congratulations message */}
  <div className="tp-message-container">
    <h1 className="tp-congrats-title">🎉 Congratulations! 🎉</h1>
    <p className="tp-congrats-text">
      You're one step closer to mastering React JS! Click below to collect your free guide.
    </p>

    {/* Collect Ebook Button */}
    <button
      onClick={() => {
        window.location.href =
          'https://firebasestorage.googleapis.com/v0/b/sell-skill-79ed7.appspot.com/o/ebooks%2FBeginning.ReactJS.Foundations.Chris.Minnick.Wiley.9781119685548.EBooksWorld.ir.pdf?alt=media&token=97b71afb-cf2f-449c-9a96-62e981a20732';
      }}
      className="tp-collect-btn"
    >
      COLLECT YOUR EBOOK
    </button>
  </div>
</div>
  );
};

export default TPTSWEBDEV;
